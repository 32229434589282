import { useMemo, useState, useEffect } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { usePost, usePostFunctions } from '../../contexts/PostContext';
import { useSocialNetworks } from 'contexts/SocialNetworksContext';
import DateManager from './components/DateManager';
import FacebookLogo from '_assets/common/facebook-logo.svg';
import InstagramLogo from '_assets/common/instagram-logo-bold.svg';
import PostForm from 'components/PostForm';
import SocialPanel from 'components/SocialPanel';
import MaiaSocialPanel from 'components/MaiaSocialPanel';
import useStyles from './styles';
import DefaultButton from 'components/DefaultButton';

function Posts() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { createMode, currentPost, currentPostDates, newPostDates, postSaving, postToCreate } = usePost();
  const {
    createPost,
    deletePost,
    resetCurrentPost,
    resetPost,
    setCurrentPost,
    setCurrentPostDates,
    setNewPostDates,
    setPostToCreate,
    updatePost,
  } = usePostFunctions();

  const { changeStatus, status } = useSocialNetworks();
  const [maiaPlatform, setMaiaPlatform] = useState('travel');

  const [openDateManager, setOpenDateManager] = useState(false);
  const [removingPost, setRemovingPost] = useState(false);

  const [fileChanged, setFileChanged] = useState(false);

  const post = useMemo(() => {
    const locationPost = location?.state?.post;
    if (createMode) {
      if (locationPost) return { ...postToCreate, comment: locationPost.comment, file: locationPost.file };
      return postToCreate;
    }

    const loc = currentPost.posts_location;
    const postLocation = loc
      ? {
        geometry: {
          coordinates: [loc.longitude, loc.latitude],
        },
        properties: {
          context: loc.context,
          label: loc.label,
          postcode: loc.postcode,
        },
      }
      : null;

    return { ...currentPost, location: currentPost.location !== '' ? currentPost.location : postLocation };
  }, [createMode, currentPost, location?.state?.post, postToCreate]);
  const postDates = useMemo(
    () => (createMode ? newPostDates : currentPostDates),
    [createMode, currentPostDates, newPostDates],
  );
  const disableSubmit = post?.posts_socials && post.posts_socials.some((p) => p.published_date !== null);

  const handleCreatePost = async (data) => {
    const formData = new FormData();
    formData.append('attachment', data.file);
    formData.append('attachmentType', data.file.type.split('/')[0]);
    formData.append('isDraft', data.isDraft);

    if (data.description) formData.append('description', data.description);
    if (data.hashtags) formData.append('hashtags', data.hashtags);
    if (data.localisation) formData.append('localisation', data.localisation ? JSON.stringify(data.localisation) : null);

    formData.append('facebookIsEnabled', status.facebook);
    formData.append('facebookPlanningDate', newPostDates.facebook.value.format());
    formData.append('instagramIsEnabled', status.instagram);
    formData.append('instagramPlanningDate', newPostDates.instagram.value.format());

    formData.append('platformPlanningDate', newPostDates.maia.value.format());
    const { created } = await createPost(maiaPlatform, formData);
    if (created) resetPost();
    return { success: created };
  };

  const handleUpdatePost = async (data) => {
    const formData = new FormData();

    if (fileChanged) {
      formData.append('attachment', data.file.data);
      formData.append('attachmentType', data.file.type);
      if (data.file.thumbnail) formData.append('thumbnail', data.file.thumbnail);
    }

    if (data.comment) formData.append('comment', data.comment);
    if (data.hashtag) formData.append('hashtag', data.hashtag);
    if (data.location) formData.append('location', data.location ? JSON.stringify(data.location) : null);

    formData.append('facebookIsEnabled', status.facebook);
    formData.append('facebookPlanningDate', currentPostDates.facebook.value.format());
    formData.append('instagramIsEnabled', status.instagram);
    formData.append('instagramPlanningDate', currentPostDates.instagram.value.format());
    formData.append('isDraft', data.isDraft);

    const { updated } = await updatePost(currentPost, formData);
    if (updated) {
      setFileChanged(false);
      resetPost();
    }
    return { success: updated };
  };

  const handleInstagramToggle = async ({ target: { checked } }) => {
    await changeStatus('instagram', checked);
  };

  const handleFacebookToggle = async ({ target: { checked } }) => {
    await changeStatus('facebook', checked);
  };

  const handleMaiaToggle = async ({ target: { checked } }) => {
    setMaiaPlatform(checked ? 'partners' : 'travel');
  }

  const handleOpenModal = () => {
    setOpenDateManager(true);
  };

  const handleCloseModal = () => {
    setOpenDateManager(false);
  };

  const handleFieldChange = ({ target: { name, value } }) => {
    if (createMode) setPostToCreate((prev) => ({ ...prev, [name]: value }));
    else setCurrentPost((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (fileData) => {
    if (createMode) {
      setPostToCreate((prev) => ({ ...prev, file: fileData }));
    } else {
      setCurrentPost((prev) => ({ ...prev, file: fileData }));
      setFileChanged(true);
    }
  };

  const handleDeletePost = async () => {
    setRemovingPost(true);
    await deletePost(currentPost);
    setRemovingPost(false);
  };

  const handleCancel = () => {
    if (location?.state?.post) history.push('/activities');

    if (createMode) resetPost();
    else resetCurrentPost();
  };

  const handleSubmit = async (data) => {
    let result;
    if (createMode) {
      result = await handleCreatePost(data);
      if (location?.state?.post) history.push('/activities');
    } else {
      result = await handleUpdatePost(data);
    }

    return result;
  };

  const handleConfirm = (dates) => {
    if (createMode) setNewPostDates(dates);
    else setCurrentPostDates(dates);
    setOpenDateManager(false);
  };

  return (
    <>
      <Grid className={classes.socialContainer} container item xs={12} spacing={4}>
        <Grid item xs={3}>
          <MaiaSocialPanel
            active={status.maia === true} // True : Partners - False : Travel
            date={postDates.maia}
            onToggle={handleMaiaToggle}
            leftText="Travel"
            rightText="Partners"
             />
        </Grid>

        <Grid item xs={3}>
          <SocialPanel
            active={status.maia ? status.instagramPartners : status.instagramTravel}
            altText="Instagram"
            date={postDates.instagram}
            icon={InstagramLogo}
            onToggle={handleInstagramToggle}
            textOn="On"
            textOff="Off"
          />
        </Grid>

        <Grid item xs={3}>
          <SocialPanel
            active={status.maia ? status.facebookPartners : status.facebookTravel}
            altText="Facebook"
            date={postDates.facebook}
            icon={FacebookLogo}
            onToggle={handleFacebookToggle}
            textOn="On"
            textOff="Off"
          />
        </Grid>

        <Grid item xs={3}>
          <DefaultButton className={classes.button} disabled={postSaving} standard onClick={handleOpenModal}>
            Programmer
          </DefaultButton>
          {!createMode && (
            <DefaultButton className={classes.button} disabled={postSaving} standard onClick={handleDeletePost}>
              {removingPost ? <CircularProgress size={20} /> : 'Supprimer'}
            </DefaultButton>
          )}
        </Grid>
      </Grid>

      <Grid className={classes.formContainer} container item xs={12}>
        <PostForm
          disabled={postSaving}
          disableSubmit={disableSubmit}
          onCancel={handleCancel}
          onFieldChange={handleFieldChange}
          onFileChange={handleFileChange}
          onSubmit={handleSubmit}
          post={post}
        />
      </Grid>

      <DateManager dates={newPostDates} onClose={handleCloseModal} onConfirm={handleConfirm} open={openDateManager} platform={maiaPlatform} />
    </>
  );
}

export default Posts;
