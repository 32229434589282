import { useState } from 'react';
import { Box, InputAdornment, Menu, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import DefaultIconButton from 'components/DefaultIconButton';
import DefaultMenuItem from 'components/DefaultMenuItem';
import hoveredUploadIcon from '_assets/common/hoveredUploadArrow.svg';
import uploadIcon from '_assets/common/uploadArrow.svg';

const useStyles = makeStyles(() => ({
  burgerMenu: {
    '& .MuiMenu-paper': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    },
  },
}));

function DataTableExportToolbar({ classes, selectedData, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const internClasses = useStyles();

  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
    props.onSearchChange(event);
  };

  return (
    <Box className={classes.toolbarRoot}>
      {<DefaultIconButton
        aria-controls="actions-menu"
        aria-haspopup="true"
        className={anchorEl ? classes.activeMassActionButton : classes.massActionButton}
        icon={uploadIcon}
        hoveredIcon={hoveredUploadIcon}
        onClick={handleActionsClick}
        iconAlt={"Bouton d'export"}
        iconText="Exports"
      />}
      <TextField
        margin="dense"
        variant="outlined"
        onChange={handleSearchValueChange}
        placeholder="Recherche"
        value={searchValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleActionsClose}
        className={internClasses.burgerMenu}
      >
        <DefaultMenuItem
          className={classes.menuItem}
          onClick={() => {
            props.onExport(null, 'csv', false);
            setAnchorEl(null);
          }}
        >
          en CSV
        </DefaultMenuItem>
        <DefaultMenuItem
          className={classes.menuItem}
          onClick={() => {
            props.onExport(null, 'pdf', false);
            setAnchorEl(null);
          }}
        >
          en PDF
        </DefaultMenuItem>
        {selectedData.length > 0 && (
          <DefaultMenuItem
            className={classes.menuItem}
            onClick={() => {
              props.onExport(selectedData, 'csv', true);
              setAnchorEl(null);
            }}
          >
            Exporter les factures sélectionnées en CSV
          </DefaultMenuItem>
        )}
        {selectedData.length > 0 && (
          <DefaultMenuItem
            className={classes.menuItem}
            onClick={() => {
              props.onExport(selectedData, 'pdf', true);
              setAnchorEl(null);
            }}
          >
            Exporter les factures sélectionnées en PDF
          </DefaultMenuItem>
        )}
      </Menu>
    </Box>
  );
}

export default DataTableExportToolbar;
