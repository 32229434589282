import colors from './colors';

const typography = {
  fontFamily: 'Roboto',
  body1: {
    fontWeight: 400,
  },
  subtitle1: {
    color: colors.main.MAIA_BLACK,
  },
  h1: {
    color: colors.main.MAIA_BLUE,
    fontFamily: 'CenturyGothic',
    fontSize: 48,
    fontWeight: 400,
  },
  h2: {
    color: colors.main.MAIA_BLACK,
    fontFamily: 'CenturyGothic',
    fontSize: 30,
    fontWeight: 400,
  },
  h3: {
    color: colors.main.MAIA_BLUE,
    fontFamily: 'CenturyGothic',
    fontSize: 26,
    fontWeight: 400,
  },
  h4: {
    color: colors.main.MAIA_BLUE,
    fontWeight: 400,
    fontSize: 24,
  },
  h5: {
    color: colors.main.MAIA_BLUE,
    fontWeight: 500,
    fontSize: 18,
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "-0.05px",
  },
  buttons: {
    textTransform: "none",
  },
};

export default typography;
