import axios from 'axios';

async function getDestinations() {
    const response = await axios.get(`/admin/destinations`);
    return response.data;
}

async function updateDestination(id, data) {
    const response = await axios.put(`/admin/destinations/` + id, data);
    return response.data;
}

async function createDestination(data) {
    const response = await axios.post(`/admin/destinations/`, data);
    return response.data;
}

async function getDestinationCallBack(value, callback) {
    await axios.get(`/admin/destinations?label=${value}`).then((res) => callback(res.data.data));
}

const destinationsService = {
    getDestinations,
    updateDestination,
    createDestination,
    getDestinationCallBack
}

export default destinationsService;
