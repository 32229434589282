import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  table: {
    minWidth: ({ minWidth }) => minWidth ? `${minWidth}px` : '750px',
    width: '100%',
  },
  titles: {
    fontWeight: 500,
  },
  rowCell: {
    textAlign: 'center',
  },
}));
