import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
  },
  field: {
    color: theme.palette.primary.main,
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  tableCell: {
    '& *': {
      maxHeight: 120,
      minHeight: 25,
    },
  },
  evenRow: {
    backgroundColor: theme.palette.common.white,
  },
  oddRow: {
    backgroundColor: theme.palette.blue.lighter,
  },
  loadingCircle: {
    margin: '2px',
  },
  loadingRow: {
    border: 'none',
    textAlign: 'center',
  },
  massActionButton: {
    height: '40px',
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  activeMassActionButton: {
    height: '40px',
    textTransform: 'none',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  menuItem: {
    color: theme.palette.primary.main,
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '21.09px',
    '&:hover': {
      backgroundColor: theme.palette.blue.lightest,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.blue.lightest,
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
  moreButton: {
    margin: theme.spacing(1),
  },
  titles: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '18.75px',
  },
  header: {
    backgroundColor: theme.palette.common.white,
  },
  searchToolbarOnly: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(3),
  },
  toolbarRoot: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
