import DataTable from 'components/DataTable';
import { IconButton } from "@material-ui/core";
import { useState, useMemo } from 'react';
import EditIcon from '@material-ui/icons/Edit';

const headCells = [
    { id: 'mainImage', label: '', sortable: false },
    { id: 'id', label: 'ID évènement', sortable: true },
    { id: 'name', label: 'Nom', sortable: true },
    { id: 'label', label: 'Label', sortable: true },
    { id: 'description', label: 'Description', sortable: true },
    { id: 'startDate', label: 'Début', sortable: true },
    { id: 'endDate', label: 'Fin', sortable: true },
    { id: 'summary', label: 'Résumé', sortable: true },
    { id: 'access', label: 'Accès', sortable: true },
    { id: 'tips', label: 'Conseils', sortable: true },
    { id: 'latitude', label: 'Latitude', sortable: true },
    { id: 'longitude', label: 'Longitude', sortable: true },
    { id: 'popularScore', label: 'Notation', sortable: true },
    { id: 'creationDate', label: 'Date de création', sortable: true },
    { id: 'destination', label: 'Destination', sortable: true },
    { id: 'edit', label: 'Editer', sortable: false }
];

function EventsTable({ data, loading, onEditEvent }) {

    const [searchValue, setSearchValue] = useState('');

    const onDocumentClick = (event) => {
        event.target.value = '';
    };

    const normalizedData = useMemo(() => Array.from(data).map((eventObject, index) => ({
        ...eventObject,
        edit: {
            component: (
                <>
                    <IconButton size="small" onClick={(event) => onEditEvent(event, eventObject)}>
                        <EditIcon color="primary" />
                    </IconButton>
                </>
            )
        },
        destination: eventObject.destination?.name ?? '',
        mainImage: {
            component: eventObject.pictureURL ? <img src={eventObject.pictureURL} alt={"image de l'évènement " + eventObject.name} /> : <span />,
        },
    })).filter(v => Object.values(v).map(v => String(v)).find(f => searchValue ? f.toLowerCase().includes(searchValue.toLowerCase()) : true)), [data, searchValue]);

    return <DataTable
        headCells={headCells}
        data={normalizedData}
        loading={loading}
    />;
}

export default EventsTable;
