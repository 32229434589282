import { useState } from 'react';
import { useSocialNetworks } from 'contexts/SocialNetworksContext';
import DefaultModal from 'components/DefaultModal';
import InstagramLogo from '_assets/common/instagram-logo-bold.svg';
import FacebookLogo from '_assets/common/facebook-logo.svg';
import MaiaLogo from '_assets/common/maia-no-text-logo.svg';
import SocialProgram from '../SocialProgram';

function DateManager({ dates, onClose = () => { }, onConfirm = () => { }, open, platform }) {
  const { status } = useSocialNetworks();

  const [dateState, setDateState] = useState(dates);

  const handleDateChange = (data) => {
    setDateState({ ...dateState, ...data });
  }

  return (
    <DefaultModal
      onClose={onClose}
      onConfirm={() => onConfirm(dateState)}
      open={open}
      title={ 'Programmation de post Maïa ' + platform }
    >
      <SocialProgram
        date={dateState.instagram.value}
        disabled={!status.instagram }
        icon={InstagramLogo}
        name='instagram'
        onChange={handleDateChange}
      />
      <SocialProgram
        date={dateState.facebook.value}
        disabled={!status.facebook}
        icon={FacebookLogo}
        name='facebook'
        onChange={handleDateChange}
      />
      <SocialProgram
        date={dateState.maia.value}
        disabled={false}
        icon={MaiaLogo}
        name='maia'
        onChange={handleDateChange}
      />
    </DefaultModal>
  )
}

export default DateManager;
