import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    marginTop: ({ noMargin }) => !!noMargin ? 0 : theme.spacing(2),
    position: 'relative',
    textAlign: 'center',
  },
  goBack: {
    left: theme.spacing(2),
    position: 'absolute',
    top: ({ noMargin }) => !!noMargin ? 8 : -8,
  },
  image: {
    left: '16%',
    position: 'absolute',
    top: -16,
  },
}));
