import { Checkbox, withStyles } from '@material-ui/core';
import colors from '../../themes/colors';

const StandardCheckbox = withStyles({
  root: {
    '&$checked': {
      color: colors.main.MAIA_BLUE,
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

function DefaultCheckbox(props) {
  const { color, ...other } = props;

  if (!color || color === 'default') return <StandardCheckbox {...other} />
  return <Checkbox color={color} {...other} />
}

export default DefaultCheckbox;
