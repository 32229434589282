import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react'
import {
  formHelperTextClasses,
  IconButton,
  InputAdornment,
  inputBaseClasses,
  outlinedInputClasses,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AlertCircleIcon from 'components/icons/AlertCircleIcon'
import CheckCircleIcon from 'components/icons/CheckCircleIcon'
import CrossedOutEyeIcon from 'components/icons/CrossedOutEyeIcon'
import EyeIcon from 'components/icons/EyeIcon'

const DefaultTextField = ({
  children,
  color,
  error,
  success,
  CustomAdornment,
  multiline,
  password,
  sx,
  value: initialValue,
  onChange,
  ...rest
}) => {
  const theme = useTheme()
  const inputAdornment = useMemo(() => {
    if (error) return <AlertCircleIcon color="error" />
    if (success) return <CheckCircleIcon color="success" />
    if (CustomAdornment) return CustomAdornment
    return null
  }, [CustomAdornment, error, success])

  const usedColor = useMemo(() => {
    if (error) return 'error'
    if (success) return 'success'
    return color
  }, [color, error, success])

  const [value, setValue] = useState(initialValue)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleChange = (event) => {
    setValue(event.target.value)
    onChange?.(event)
  }

  return (
    <TextField
      color={usedColor}
      error={error}
      multiline={multiline}
      type={!!password && !showPassword ? 'password' : 'text'}
      InputProps={{
        endAdornment: !password ? (
          !!inputAdornment && <InputAdornment position="end">{inputAdornment}</InputAdornment>
        ) : (
          <InputAdornment position={'end'}>
            <IconButton
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            >
              {showPassword ? <EyeIcon color={usedColor} /> : <CrossedOutEyeIcon color={usedColor} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        [`& .${inputBaseClasses.root}`]: {
          height: !multiline && { xs: 37, sm: 50 },
        },
        [`& .${inputBaseClasses.input}`]: {
          [theme.breakpoints.down('sm')]: {
            padding: '8px',
          },
          padding: !multiline && '14px 14px',
        },
        [`& .${outlinedInputClasses.root}`]: {
          color: error && `${usedColor}.main`,
          '& fieldset': {
            borderColor: usedColor && `${usedColor}.light`,
          },
          '&:hover fieldset': {
            borderColor: usedColor && `${usedColor}.main`,
          },
          '&.Mui-focused fieldset': {
            borderColor: usedColor && `${usedColor}.main`,
          },
        },
        [`& .${formHelperTextClasses.root}`]: {
          color: usedColor && `${usedColor}.main`,
          fontWeight: 500,
          ml: 0,
        },
        ...sx,
      }}
      value={value}
      onChange={handleChange}
      {...rest}
    >
      {children}
    </TextField>
  )
}

export default DefaultTextField
