import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Typography, withStyles } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    root: {
        textAlign:'flex-start',
        paddingLeft:theme.spacing(2),
        padding:0,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        '& .MuiTableSortLabel-active': {
            color: theme.palette.primary.main,
        },
        '& .MuiTableSortLabel-root:hover':{
            color: theme.palette.primary.main,
        },
        '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon':{
            color: theme.palette.primary.main,
            opacity: 1,
        },
    },
}))(TableCell);

function DataTableHead(props) {
    const { classes, headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, checkbox, isCollapsable } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }

    return (
        <TableHead>
            <TableRow>
                {!!checkbox && <StyledTableCell padding='checkbox'>
                    <Checkbox
                        checked={rowCount > 0 && numSelected === rowCount}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        onChange={onSelectAllClick}
                        className={classes.checkbox}
                        inputProps={{ 'aria-label': 'select all bills' }}
                    />
                </StyledTableCell>}

                {isCollapsable && <StyledTableCell className={classes.titles} key='collapse'></StyledTableCell>}

                {headCells.map((headCell) => {
                    if (!headCell.sortable) return (
                        <StyledTableCell className={classes.titles} key={headCell.id}>
                            {headCell.label}
                        </StyledTableCell>
                    )

                    return (
                        <StyledTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                <Typography noWrap className={classes.titles}>{headCell.label}</Typography>
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    )
                })}

            </TableRow>
        </TableHead>
    );
}

export default DataTableHead;
