import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import useIconDimension from 'hooks/useIconDimension'

const CrossedOutEyeIcon = ({ width, height, sx, ...rest }) => {
  const dimension = useIconDimension()

  return (
    <SvgIcon sx={{ width: width ?? dimension.width, height: height ?? dimension.height, ...sx }} {...rest}>
      <path
        d="M8.23319 8.2334C7.98757 8.46227 7.79056 8.73827 7.65392 9.04494C7.51729 9.3516 7.44381 9.68265 7.43789 10.0183C7.43197 10.354 7.49372 10.6874 7.61945 10.9987C7.74519 11.31 7.93234 11.5928 8.16973 11.8302C8.40713 12.0676 8.68991 12.2547 9.0012 12.3805C9.3125 12.5062 9.64593 12.568 9.9816 12.562C10.3173 12.5561 10.6483 12.4826 10.955 12.346C11.2617 12.2094 11.5377 12.0124 11.7665 11.7667"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.94141 4.23366C9.2925 4.18992 9.64593 4.16765 9.99974 4.16699C15.8331 4.16699 18.3331 10.0003 18.3331 10.0003C17.9605 10.7979 17.4933 11.5478 16.9414 12.2337"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50866 5.50879C3.85137 6.63764 2.52522 8.18817 1.66699 10.0005C1.66699 10.0005 4.16699 15.8338 10.0003 15.8338C11.5969 15.8381 13.1593 15.3714 14.492 14.4921"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 1.66699L18.3337 18.3337"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default CrossedOutEyeIcon;
