import axios from 'axios';

async function getEvents() {
    const response = await axios.get(`/admin/events`);
    return response.data;
}

async function updateEvent(id, data) {
    const response = await axios.put(`/admin/events/` + id, data);
    return response.data;
}

async function createEvent(data) {
    const response = await axios.post(`/admin/events`, data);
    return response.data;
}

const eventsService = {
    getEvents,
    updateEvent,
    createEvent
}

export default eventsService;
