import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  titleContainer: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    position: 'relative',
    textAlign: 'center',
  },
  goBack: {
    left: theme.spacing(2),
    position: 'absolute',
    top: -8,
  },
  columnSeparator: {
    backgroundColor: theme.palette.divider,
    width: '2px',
  },
}));
