import { toastMessages } from '../config/constants';
import moment from 'moment';

export const getTextWidth = (text, font) => {
    const context = document.createElement('canvas').getContext('2d');
    context.font = font;
    return context.measureText(text).width;
};

export const trimHashtag = (hashtag) => {
    let newHash = hashtag
    while (newHash.charAt(0) === '#') {
        newHash = newHash.slice(1, newHash.length)
    }
    return newHash
}

export function computeAge(dateTime) {
    if (dateTime === null) {
        return null;
    }
    let birthDate = moment(moment(dateTime).format('YYYY-MM-DD'));
    let birthYear = new Date(dateTime).getFullYear();
    let age = new Date().getFullYear() - birthYear;
    return age;
};

export const getImageObjectFromDataURL = (dataURL, filename) => {
    let arr = dataURL.split(','),
        mimeType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], `${filename}.${mimeType.split('/')[1]}`, { type: mimeType });
};

export const getThumbnailFromFile = (file) => {
    const fileSize = file.size / 1024 / 1024;
    const fileType = file.type.split('/')[0];

    let fileReader = new FileReader();

    return new Promise((resolve) => {
        if (fileType.match('image')) {
            if (fileSize > 8) resolve({ error: toastMessages.error.POST_FILE_SIZE });

            fileReader.onload = () => {
                resolve({ data: file, type: 'image', url: fileReader.result });
            };
            fileReader.readAsDataURL(file);
        } else if (fileType.match('video')) {
            if (fileSize > 100) resolve({ error: toastMessages.error.POST_FILE_SIZE });

            fileReader.onload = () => {
                const blob = new Blob([fileReader.result], { type: file.type });
                const url = URL.createObjectURL(blob);
                let video = document.createElement('video');

                video.addEventListener('loadeddata', () => {
                    if (video.duration < 3 || video.duration > 60) resolve({ error: toastMessages.error.VIDEO_DURATION });

                    const snap = snapImage();
                    if (snap.success) {
                        video.pause();
                        resolve({ data: file, thumbnail: snap.file, type: snap.type, url: snap.url });
                    }
                });

                const snapImage = () => {
                    let canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                    const dataURL = canvas.toDataURL();
                    const success = dataURL.length > 100000;
                    if (success) {
                        URL.revokeObjectURL(url);

                        const image = getImageObjectFromDataURL(dataURL, `${file.name.split('.')[0]}`);
                        return { success, file: image, type: 'video', url: dataURL };
                    }
                    return { success };
                };

                video.preload = 'metadata';
                video.src = url;
                video.muted = true;
                video.playsInline = true;
                video.play();
            };

            fileReader.readAsArrayBuffer(file);
        } else {
            resolve({ error: toastMessages.error.POST_FILE_FORMAT });
        }
    });
};

export const convertBytesToKiloBytes = (bytes, decimals = 3) => {
    if (!+bytes) return 0;

    const k = 1024;
    return parseFloat((bytes / k).toFixed(decimals));
};