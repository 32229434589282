import axios from 'axios';

async function getHelpRequests() {
    const response = await axios.get(`/admin/helprequests`);
    return response.data;
}

async function updateHelpRequest(id, data) {
    const response = await axios.put(`/admin/helprequests/` + id, data);
    return response.data;
}

const helpRequestsService = {
    getHelpRequests,
    updateHelpRequest
}

export default helpRequestsService;
