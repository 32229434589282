import DataTable from 'components/DataTable';
import { Button, Card, List, ListItem, ListItemText, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { tripStatus } from 'config/constants';
import { useState, useMemo } from 'react';
import moment from 'moment';
import DataTableExportToolbar from "components/DataTableExportToolbar";
import { TripsApi } from 'services';
import { toastMessages } from 'config/constants';
import toast from 'react-hot-toast';

const headCells = [
    { id: 'id', label: 'ID', sortable: true },
    { id: 'startDate', label: 'Date début', sortable: true },
    { id: 'endDate', label: 'Date fin', sortable: true },
    { id: 'status', label: 'Statut', sortable: true },
    { id: 'totalPrice', label: 'Prix total', sortable: true },
    { id: 'creationDate', label: 'Date de création', sortable: true },
    { id: 'destinations', label: 'Destinations', sortable: true },
    { id: 'activities', label: 'Activités', sortable: true },
    { id: 'events', label: 'Evénements', sortable: true },
    { id: 'accommodations', label: 'Hébergements', sortable: true },
    { id: 'confirmation', label: 'Confirmation', sortable: false },
];

const nestedHeadCells = [
    { id: 'id', label: 'ID', sortable: true },
    { id: 'userName', label: 'Voyageur', sortable: true },
    { id: 'documents', label: 'Documents', sortable: false },
    { id: 'uploadDocument', label: 'Upload document', sortable: false },
];

function TripsTable({ data, loading, onConfirmationClick, onDeleteTripDocument, onUploadDocument }) {

    const [searchValue, setSearchValue] = useState('');
    const [exporting, setExporting] = useState(false);

    const onDocumentClick = (event) => {
        event.target.value = '';
    };

    const normalizedData = useMemo(() => Array.from(data).map((trip, index) => ({
        ...trip,
        collapsableComponent: (
            <DataTable
                headCells={nestedHeadCells}
                data={trip.users.map((user) => {
                    return {
                        ...user,
                        userName: user.firstname + ' ' + user.lastname + ' (' + user.nickname + ')',
                        documents: {
                            component: (
                                <>
                                    <List>
                                        {user.documents.map((value) => {
                                            return (
                                                <ListItem style={{ padding: 0 }}>
                                                    <ListItemText /*className={classes.field}*/>
                                                        <a href={value.url}>{value.document}</a>
                                                        <IconButton size="small" onClick={(event) => onDeleteTripDocument(event, trip.id, user.id, value)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemText>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </>
                            )
                        },
                        uploadDocument: {
                            component: (
                                <input
                                    accept=".jpg,.jpeg,.png,.pdf"
                                    onChange={(event) => onUploadDocument(event, trip.id, user.id)}
                                    onClick={onDocumentClick}
                                    id="postDocumentInput"
                                    name="postDocument"
                                    type="file"
                                    multiple
                                />
                            )
                        }
                    }
                })}
                loading={loading}
                isCollapsable={false}
            />
        ),
        // < Card >
        // <input
        //     accept=".jpg,.jpeg,.png,.pdf"
        //     onChange={(event) => onUploadDocument(event, trip.id)}
        //     onClick={onDocumentClick}
        //     id="postDocumentInput"
        //     name="postDocument"
        //     type="file"
        //     multiple
        // />
        //             </Card >
        //         </>
        //     ),
        startDate: moment(trip.startDate).format('DD/MM/YYYY'),
        endDate: moment(trip.endDate).format('DD/MM/YYYY'),
        creationDate: moment(trip.creationDate).format('DD/MM/YYYY HH:mm'),
        destinations: trip.destinations.map((destination) => { return destination.name }).join(', '),
        users: trip.users.map((user) => { return user.firstname + ' ' + user.lastname }).join(', '),
        status: {
            component: (
                <>
                    {trip.status === tripStatus.processing && <div
                        style={{
                            backgroundColor: "rgba(255,157,92, 1.0)",
                            fillOpacity: "0.5",
                            borderRadius: "15px",
                            flex: "diplay",
                            textAlign: "center",
                        }}
                    >
                        En cours de traitement
                    </div>}
                    {trip.status === tripStatus.processed && <div
                        style={{
                            backgroundColor: "rgba(0,190,0, 0.6)",
                            fillOpacity: "0.5",
                            borderRadius: "15px",
                            flex: "diplay",
                            textAlign: "center",
                        }}
                    >
                        Traitée
                    </div>
                    }
                    {trip.status === tripStatus.error && <div
                        style={{
                            backgroundColor: "rgba(255,10,0, 0.6)",
                            fillOpacity: "0.5",
                            borderRadius: "15px",
                            flex: "diplay",
                            textAlign: "center",
                        }}
                    >
                        En erreur
                    </div>
                    }
                </>
            )
        },
        accommodations: trip.accommodations.length,
        activities: trip.activities.length,
        events: trip.events.length,
        confirmation: {
            component: (
                <>
                    {trip.status === tripStatus.processing && <Button
                        color="primary"
                        variant="contained"
                        style={{ margin: "1em" }}
                        onClick={(event) => {
                            onConfirmationClick(event, trip);
                        }}
                    >
                        Valider
                    </Button>
                    }
                </>
            )
        }
    })).filter(v => Object.values(v).map(v => String(v)).find(f => searchValue ? f.toLowerCase().includes(searchValue.toLowerCase()) : true)), [data, searchValue]);

    const handleExport = async (selectedData, format, useSelected) => {
        if (exporting) return;

        setExporting(true);

        let tripsIds;
        if (useSelected) {
            tripsIds = selectedData.sort((a, b) => a - b);
        } else {
            tripsIds = data.map(b => b.id);
        }

        setExporting(false);

        await TripsApi.exportTripsBookings(format, { ids: tripsIds })
            .then((result) => {
                let blob;
                if (format === 'csv')
                    blob = new Blob(['\ufeff' + result], { type: 'text/csv;' });
                else
                    blob = new Blob([result], { type: 'application/pdf' });
                const filename = `Réservations voyages Maia Partners.${format}`;
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, filename);
                } else {
                    let link = document.createElement('a');
                    if (link.download !== undefined) {
                        link.setAttribute('href', URL.createObjectURL(blob));
                        link.setAttribute('download', filename);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }

                setExporting(false);
            })
            .catch(() => {
                toast.error(toastMessages.error.EXPORT_TRIPS_BOOKINGS);
                setExporting(false);
            });

        // BillApi.exportBills(currentCompany?.id, format, { ids: billIds })
        //     .then((result) => {
        //         let blob;
        //         if (format === 'csv') blob = new Blob(['\ufeff' + result], { type: 'text/csv;' });
        //         else blob = new Blob([result], { type: 'application/pdf' });

        //         const filename = `Prestataires Maia Partners.${format}`;

        //         if (navigator.msSaveBlob) { // IE 10+
        //             navigator.msSaveBlob(blob, filename);
        //         } else {
        //             let link = document.createElement('a');
        //             if (link.download !== undefined) {
        //                 link.setAttribute('href', URL.createObjectURL(blob));
        //                 link.setAttribute('download', filename);
        //                 link.style.visibility = 'hidden';
        //                 document.body.appendChild(link);
        //                 link.click();
        //                 document.body.removeChild(link);
        //             }
        //         }

        //         setExporting(false);
        //     })
        //     .catch(() => {
        //         toast.error(toastMessages.error.EXPORT_BILL);
        //         setExporting(false);
        //     });
    }

    return <DataTable
        headCells={headCells}
        data={normalizedData}
        loading={loading}
        ToolbarComponent={DataTableExportToolbar}
        isCollapsable={true}
        toolbarProps={{
            onSearchChange: (event) => setSearchValue(event.target.value),
            onExport: (data, format, useSelected) => handleExport(data, format, useSelected),
            nbSelectedItems: 0,
        }}
    />;
}

export default TripsTable;
