import { Select, CircularProgress, makeStyles, TextField, Box, IconButton, Typography } from "@material-ui/core";
import DefaultMenuItem from 'components/DefaultMenuItem';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DefaultButton from 'components/DefaultButton';
import React, { useState, useEffect } from "react";
import { convertBytesToKiloBytes } from 'utils';
import { ActivitiesApi, DestinationsApi, PartnersApi } from 'services';
import { toastMessages } from 'config/constants';
import toast from 'react-hot-toast';
import useStyles from './styles';


function CreateActivityForm({ onSubmit }) {
    const [activityName, setActivityName] = useState("");
    const [activityLabel, setActivityLabel] = useState("");
    const [activityDescription, setActivityDescription] = useState("");
    const [activityLatitude, setActivityLatitude] = useState("");
    const [activityLongitude, setActivityLongitude] = useState("");
    const [activityPopularScore, setActivityPopularScore] = useState("");
    const [activityDestination, setActivityDestination] = useState(null);
    const [activityPartner, setActivityPartner] = useState(null);
    const [destinations, setDestinations] = useState([]);
    const [partners, setPartners] = useState([]);
    const [picture, setPicture] = useState({ file: null, url: null });

    const [isSubmitting, setSubmitting] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        fetchDestinations();
        fetchPartners();
    }, []);

    async function fetchDestinations() {
        await DestinationsApi.getDestinations().then((destinations) => {
            var data = [];
            if (destinations && destinations.data) {
                for (var i = 0; i < destinations.data.length; i++) {
                    data.push({
                        id: destinations.data[i].id,
                        name: destinations.data[i].name,
                        subtitle: destinations.data[i].subtitle
                    });
                }
                setDestinations(data);
            }
        });
    }

    async function fetchPartners() {
        await PartnersApi.getPartners().then((partners) => {
            var data = [];
            if (partners && partners.data) {
                for (var i = 0; i < partners.data.length; i++) {
                    data.push({
                        id: partners.data[i].id,
                        name: partners.data[i].name,
                        label: partners.data[i].label
                    });
                }
                setPartners(data);
            }
        });
    }

    async function handleChange(event) {
        switch (event.target.name) {
            case 'name':
                setActivityName(event.target.value);
                break;
            case 'label':
                setActivityLabel(event.target.value);
                break;
            case 'description':
                setActivityDescription(event.target.value);
                break;
            case 'latitude':
                setActivityLatitude(event.target.value);
                break;
            case 'longitude':
                setActivityLongitude(event.target.value);
                break;
            case 'popularScore':
                setActivityPopularScore(event.target.value);
                break;
        }
    }

    async function handleSubmit(event) {
        setSubmitting(true);
        event.preventDefault();
        let formData = new FormData();
        formData.append('name', activityName);
        formData.append('label', activityLabel);
        formData.append('description', activityDescription);
        formData.append('latitude', activityLatitude);
        formData.append('longitude', activityLongitude);
        formData.append('popular_score', activityPopularScore);
        formData.append('destination_id', activityDestination.id);
        formData.append('partner_id', activityPartner.id);
        if (picture && picture?.file)
            formData.append('file', picture?.file);
        else {
            setSubmitting(false);
            toast.error(toastMessages.error.CREATE_ACTIVITY_MISSING_PICTURE);
            return;
        }

        await ActivitiesApi.createActivity(formData).then((_) => {
            setSubmitting(false);
            onSubmit();
        }).catch((error) => {
            setSubmitting(false);
            toast.error(toastMessages.error.CREATE_ACTIVITY);
        });
    }

    const handlePictureChange = async (event) => {
        if (event.target.files != null && event.target.files.length > 0) {
            const file = new Blob([event.currentTarget.files[0]], { type: 'image/*' });
            const url = URL.createObjectURL(file);
            setPicture({ file: event.currentTarget.files[0], fileBlob: file, url: url });
            document.getElementById('activityImage').src = url;
        }
    };

    const handlePictureClick = (event) => {
        event.target.value = '';
    };

    return (
        <>
            <div id="headers">
                <h1 style={{ fontWeight: "500" }}>Editer une activité</h1>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit} style={{ height: '100%', width: '100%' }}>

                <Box className={classes.imageContainer}>
                    <img
                        id="activityImage"
                        hidden={picture.url == null}
                        // src={activity?.pictureURL /*?? placeholder*/}
                        alt="photo de l'activité"
                        width="122px"
                        height="122px"
                        style={{ borderRadius: '5px' }}
                    />

                    <Box>
                        <input
                            accept="image/*"
                            className={classes.input}
                            //   disabled={(!autoSavePicture && disabled) || isUpdatingPicture}
                            id={'activityPictureInput'}
                            name="activityPictureInput"
                            type="file"
                            onChange={handlePictureChange}
                            onClick={handlePictureClick}
                        />
                        <label htmlFor={'activityPictureInput'}>
                            <IconButton
                                aria-label="upload"
                                color="secondary"
                                component="span"
                            // disabled={(!autoSavePicture && disabled) || isUpdatingPicture}
                            >
                                <ControlPointIcon style={{ height: '25px', width: '25px' }} fontSize="medium" />
                            </IconButton>
                            Ajouter une image
                        </label>
                        <Typography style={{ width: '80%', marginLeft: '10px' }}>
                            Vérifiez que l’image soit bien au format PNG ou JPG et qu’elle ne dépasse pas 5 Mo
                        </Typography>
                    </Box>
                </Box>

                <TextField
                    fullWidth
                    label="Nom"
                    margin="dense"
                    name="name"
                    variant="outlined"
                    value={activityName}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Label"
                    margin="dense"
                    name="label"
                    variant="outlined"
                    value={activityLabel}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Description"
                    margin="dense"
                    name="description"
                    variant="outlined"
                    value={activityDescription}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    type="number"
                    label="Latitude"
                    margin="dense"
                    name="latitude"
                    variant="outlined"
                    value={activityLatitude}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    type="number"
                    label="Longitude"
                    margin="dense"
                    name="longitude"
                    variant="outlined"
                    value={activityLongitude}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    type="number"
                    label="Note"
                    margin="dense"
                    name="popularScore"
                    variant="outlined"
                    value={activityPopularScore}
                    onChange={handleChange}
                >
                </TextField>

                <label htmlFor={'destinationSelect'}>
                    Destination associée
                </label>
                <Select
                    id='destinationSelect'
                    displayEmpty
                    fullWidth
                    margin="dense"
                    value={activityDestination ?? ''}
                    onChange={(event) => {
                        const index = destinations.findIndex((destination) => destination.name == event.target.value);
                        setActivityDestination({ id: destinations[index].id, name: event.target.value });
                    }}
                    renderValue={(activityDestination) => {
                        if (!activityDestination) return 'Choisir une destination associée';
                        return activityDestination.name;
                    }}
                >
                    {destinations.length === 0 && (
                        <DefaultMenuItem value="" disabled>
                            Aucune destination disponible
                        </DefaultMenuItem>
                    )}
                    {destinations.map((destination, index) => (
                        <DefaultMenuItem key={index} value={destination.name}>
                            {destination.name}
                        </DefaultMenuItem>
                    ))}
                </Select>

                <label htmlFor={'partnerSelect'}>
                    Partenaire associé
                </label>
                <Select
                    id='partnerSelect'
                    displayEmpty
                    fullWidth
                    margin="dense"
                    value={activityPartner ?? ''}
                    onChange={(event) => {
                        const index = partners.findIndex((partner) => partner.name == event.target.value);
                        setActivityPartner({ id: partners[index].id, name: event.target.value });
                    }}
                    renderValue={(activityPartner) => {
                        if (!activityPartner) return 'Choisir un partenaire associé';
                        return activityPartner.name;
                    }}
                >
                    {partners.length === 0 && (
                        <DefaultMenuItem value="" disabled>
                            Aucun partenaire disponible
                        </DefaultMenuItem>
                    )}
                    {partners.map((partner, index) => (
                        <DefaultMenuItem key={index} value={partner.name}>
                            {partner.name}
                        </DefaultMenuItem>
                    ))}
                </Select>

                <DefaultButton
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                >
                    {isSubmitting ? <CircularProgress size={20} /> : 'Valider'}
                </DefaultButton>

            </form>
        </>
    );
}

export default CreateActivityForm;