import React, { useEffect, useState } from "react";
import { Button, Dialog, CircularProgress } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { popupMessages } from "config/constants";
import { TripsApi } from "services";
import { tripStatus, toastMessages } from 'config/constants';
import { convertBytesToKiloBytes } from 'utils';
import toast from 'react-hot-toast';
import TripsTable from './components/TripsTable';

function TripsBookings() {
    const [trips, setTrips] = useState([]);
    const [openValidate, setOpenValidate] = useState(false);
    const [deleteDocumentDialogVisible, setDeleteDocumentDialogVisible] = useState(false);
    const [currentTripDocumentId, setCurrentTripDocumentId] = useState(null);
    const [selectedTripId, setSelectedTripId] = useState(0);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [isRefreshingDocuments, setIsRefreshingDocuments] = useState(false);

    useEffect(() => {
        fetchTrips();
    }, []);

    async function fetchTrips() {
        setIsTableLoading(true);
        TripsApi.getTrips()
            .then(
                (trips) => {
                    setIsTableLoading(false);
                    var data = [];
                    if (trips && trips.data) {
                        for (var i = 0; i < trips.data.length; i++) {
                            data.push({
                                id: trips.data[i].id,
                                startDate: trips.data[i].start_date,
                                endDate: trips.data[i].end_date,
                                creationDate: trips.data[i].creation_date,
                                totalPrice: trips.data[i].total_price + "€",
                                status: trips.data[i].status,
                                users: trips.data[i].users,
                                destinations: trips.data[i].destinations,
                                activities: trips.data[i].activity_bookings,
                                events: trips.data[i].event_bookings,
                                accommodations: trips.data[i].accommodations,
                                documents: trips.data[i].documents,
                            });
                        }
                    }
                    setTrips(data);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsTableLoading(false);
                }
            );
    }

    const onTripConfirmationClick = (event, selectedTrip) => {
        setSelectedTripId(selectedTrip.id);
        setTitle(popupMessages.title.tripConfirmation);
        setMessage(popupMessages.message.tripConfirmation);
        setOpenValidate(true);
    };

    const onTripDocumentDelete = async (event, tripId, userId, tripDocument) => {
        setDeleteDocumentDialogVisible(true);
        setSelectedTripId(tripId);
        setSelectedUserId(userId);
        setCurrentTripDocumentId(tripDocument.id)
    };

    const onTripDocumentUpload = async (event, tripId, userId) => {
        setIsRefreshingDocuments(true);
        if (event.target.files !== null) {
            let errors = 0;
            let success = 0;
            for (const file of event.target.files) {
                if (convertBytesToKiloBytes(file.size) > 10000) { // TODO : recheck value
                    toast.error(toastMessages.error.TRIP_DOCUMENT_FILE_SIZE);
                    setIsRefreshingDocuments(false);
                    return;
                }
            }
            for (const file of event.target.files) {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('user_id', userId);
                await TripsApi.uploadTripDocument(tripId, formData).then((response) => {
                    if (response.status !== 'OK')
                        errors += 1;
                    else
                        success += 1;
                });
            }
            setIsRefreshingDocuments(false);
            if (errors > 0)
                toast.error(toastMessages.error.TRIP_DOCUMENT_UPLOAD);
            else
                toast.success(toastMessages.success.TRIP_DOCUMENT_FILE_UPLOADED);
            if (success > 0)
                await fetchTrips(); // TODO : try to improve and reload only current trip documents
        }
    };

    const handleCloseDeleteDocumentDialog = () => {
        setDeleteDocumentDialogVisible(false);
        setCurrentTripDocumentId(null);
    }

    const deleteTripDocument = async () => {
        setIsRefreshingDocuments(true);
        setDeleteDocumentDialogVisible(false);
        await TripsApi.deleteTripDocument(currentTripDocumentId).then(_ => {
            for (var i = 0; i < trips.length; i++) {
                if (trips[i].id === selectedTripId) {
                    for (var k = 0; k < trips[i].users.length; k++) {
                        if (trips[i].users[k].id === selectedUserId) {
                            for (var j = 0; j < trips[i].users[k].documents.length; j++) {
                                if (trips[i].users[k].documents[j].id === currentTripDocumentId) {
                                    let tripsCopy = [...trips];
                                    let usersCopy = [...trips[i].users];
                                    let documentCopy = [...trips[i].users[k].documents];
                                    documentCopy.splice(j, 1);
                                    usersCopy[k].documents = documentCopy;
                                    tripsCopy[i].users = usersCopy;
                                    setTrips(tripsCopy);
                                    setCurrentTripDocumentId(null);
                                    setSelectedTripId(null);
                                    setSelectedUserId(null);
                                }
                            }
                        }
                    }
                }
            }
        }).finally(_ => {
            setIsRefreshingDocuments(false);
        });
    }

    const handleClose = () => {
        setOpenValidate(false);
    };

    async function confirmTrip() {
        let data = new FormData();
        data.append('status', 'processed');
        await TripsApi.updateTrip(selectedTripId, data).then((result) => {
            for (var i = 0; i < trips.length; i++) {
                if (trips[i].id === selectedTripId) {
                    let tripsCopy = [...trips];
                    let trip = { ...trips[i] };
                    trip.status = tripStatus.processed;
                    tripsCopy[i] = trip;
                    setTrips(tripsCopy);
                }
            }
            handleClose();
        });
    }

    return (
        <>
            <Dialog
                open={openValidate}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    style={{ textAlign: "center", color: "red" }}
                    id="alert-dialog-title"
                >
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{ textAlign: "center", color: "#4459C6" }}
                        id="alert-dialog-description"
                    >
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button
                        onClick={() => {
                            confirmTrip();
                        }}
                        color="primary"
                    >
                        <div style={{ color: "#4459C6" }}>Oui</div>
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        <div style={{ color: "#4459C6" }}>Non</div>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDocumentDialogVisible}
                onClose={handleCloseDeleteDocumentDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    style={{ textAlign: "center", color: "red" }}
                    id="alert-dialog-title"
                >
                    Suppression de document de voyage
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{ textAlign: "center", color: "#4459C6" }}
                        id="alert-dialog-description"
                    >
                        Voulez-vous supprimer ce document de voyage ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button
                        onClick={() => {
                            deleteTripDocument();
                        }}
                        color="primary"
                    >
                        <div style={{ color: "#4459C6" }}>Oui</div>
                    </Button>
                    <Button onClick={handleCloseDeleteDocumentDialog} color="primary">
                        <div style={{ color: "#4459C6" }}>Non</div>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isRefreshingDocuments}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            <TripsTable
                data={trips}
                loading={isTableLoading}
                onConfirmationClick={onTripConfirmationClick}
                onDeleteTripDocument={onTripDocumentDelete}
                onUploadDocument={onTripDocumentUpload}
            //                onValidateClick={onProviderValidateClick}
            //                onDeletionClick={onProviderDeleteClick}
            //                onBanClick={onProviderBanClick}
            //                openFile={openFile}
            />
        </>
    );
}

export default TripsBookings;