import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import useIconDimension from 'hooks/useIconDimension'

const AlertCircleIcon = ({ width, height, sx, ...rest }) => {
  const dimension = useIconDimension()

  return (
    <SvgIcon sx={{ width: width ?? dimension.width, height: height ?? dimension.height, ...sx }} {...rest}>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 8V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 16H12.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  )
}

export default AlertCircleIcon;
