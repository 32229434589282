import { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Calendar from '_assets/common/calendar.svg';
import DefaultButton from 'components/DefaultButton';
import moment from 'moment';
import Time from '_assets/common/time.svg';
import useStyles from './styles';

function SocialProgram({ date = null, disabled, icon = null, name = '', onChange = () => {} }) {
  const classes = useStyles();
  const [dateState, setDateState] = useState(date || moment());

  const dayDate = dateState.format('YYYY-MM-DD');
  const timeDay = dateState.format('HH:mm');

  const handleDayChange = ({ target: { value } }) => {
    if (!value) return;

    const [year, month, day] = value.split('-');
    let newDate = moment(dateState);
    newDate.set('year', year);
    newDate.set('month', month -1);
    newDate.set('date', day);
    newDate.set('hour', dateState.get('hour'));
    newDate.set('minute', dateState.get('minute'));

    setDateState(newDate);
    onChange({ [name]: { format: newDate.format('ddd DD MMM HH:mm'), value: newDate } });
  }

  const handleTimeChange = (event) => {
    const [hour, minute] = event.target.value.split(':');
    let newDate = moment(dateState);
    newDate.set('hour', hour);
    newDate.set('minute', minute);

    setDateState(newDate);
    onChange({ [name]: { format: newDate.format('ddd DD MMM HH:mm'), value: newDate } });
  }

  const onNowClick = () => {
    const d = moment();
    setDateState(d);
    onChange({ [name]: { format: d.format('ddd DD MMM HH:mm'), value: d } });
  }

  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item xs={1}>
        <img className={classes.logo} src={icon} alt='social network' width={24} height={24} style={{ marginTop: '8px' }} />
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled={disabled}
          fullWidth
          name='date'
          margin='dense'
          variant='outlined'
          type='date'
          onChange={handleDayChange}
          value={dayDate}
          InputProps={{ className: classes.picker }}
          inputProps={{ min: moment().format('YYYY-MM-DD') }}
        />
      </Grid>
      <Grid item xs={1}>
        <img className={classes.logo} src={Calendar} alt='Calendrier' width={24} height={24} />
      </Grid>
      <Grid item xs={2}>
        <TextField
          disabled={disabled}
          fullWidth
          name='date'
          margin='dense'
          variant='outlined'
          type='time'
          onChange={handleTimeChange}
          value={timeDay}
          InputProps={{ className: classes.picker }}
        />
      </Grid>
      <Grid item xs={1}>
        <img className={classes.logo} src={Time} alt='Horloge' width={24} height={24} />
      </Grid>
      <Grid item xs={3}>
        <DefaultButton disabled={disabled} standard onClick={onNowClick}>
          Maintenant
        </DefaultButton>
      </Grid>
    </Grid>
  )
}

export default SocialProgram;
