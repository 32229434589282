import { Dialog, DialogContent, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { convertBytesToKiloBytes } from 'utils';
import { EventsBookingsApi } from "services";
import { toastMessages, bookingStatus } from 'config/constants';
import toast from 'react-hot-toast';
import EventsTable from './components/EventsBookingsTable';

function EventsBookings() {
    const [eventsBookings, setEventsBookings] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [isRefreshingDocument, setIsRefreshingDocument] = useState(false);

    useEffect(() => {
        fetchEventsBookings();
    }, []);

    const onConfirmationClick = async (event, eventBooking) => {
        setIsRefreshingDocument(true);
        let formData = new FormData();
        formData.append('status', bookingStatus.paid);
        await EventsBookingsApi.updateEventBooking(eventBooking.id, formData).then((response) => {
            if (response.status === 'OK') {
                let eventsBookingsCopy = [...eventsBookings];
                for (var i = 0; i < eventsBookings.length; i++) {
                    for (var j = 0; j < eventsBookings[i].participants.length; j++) {
                        if (eventsBookings[i].participants[j].id === eventBooking.id) {
                            eventsBookingsCopy[i].participants[j].status = bookingStatus.paid;
                            setEventsBookings(eventsBookingsCopy);
                        }
                    }
                }
            }
        }).catch((_) => {
            toast.error(toastMessages.error.EVENT_BOOKING_CONFIRM_ERROR);
        }).finally(_ => {
            setIsRefreshingDocument(false);
        });
    };

    const onDocumentUpload = async (event, eventBookingId) => {
        setIsRefreshingDocument(true);
        if (event.target.files !== null) {
            let errors = 0;
            for (const file of event.target.files) {
                if (convertBytesToKiloBytes(file.size) > 9000) { // TODO : recheck value
                    toast.error(toastMessages.error.EVENT_BOOKING_DOCUMENT_FILE_SIZE);
                    setIsRefreshingDocument(false);
                    return;
                }
            }
            let responseData = null;
            for (const file of event.target.files) {
                let formData = new FormData();
                formData.append('file', file);
                await EventsBookingsApi.uploadDocument(eventBookingId, formData).then((response) => {
                    if (response.status === 'OK') {
                        if (response.data)
                            responseData = response.data;
                    }
                    else
                        errors += 1;
                }).catch((_) => {
                    errors += 1;
                    setIsRefreshingDocument(false);
                }).finally(_ => {
                    event.target.value = '';
                });
            }
            setIsRefreshingDocument(false);
            if (errors > 0)
                toast.error(toastMessages.error.EVENT_BOOKING_DOCUMENT_UPLOAD);
            else {
                toast.success(toastMessages.success.EVENT_BOOKING_DOCUMENT_FILE_UPLOADED);
                let eventsBookingsCopy = [...eventsBookings];
                for (var i = 0; i < eventsBookings.length; i++) {
                    for (var j = 0; j < eventsBookings[i].participants.length; j++) {
                        if (eventsBookings[i].participants[j].id === eventBookingId) {
                            eventsBookingsCopy[i].participants[j].url = responseData.documentUrl;
                            setEventsBookings(eventsBookingsCopy);
                        }
                    }
                }
            }
        }
    };

    async function fetchEventsBookings() {
        setIsTableLoading(true);
        EventsBookingsApi.getEventsBookings()
            .then(
                (events_bookings) => {
                    setIsTableLoading(false);
                    var data = [];
                    if (events_bookings && events_bookings.data) {
                        for (var i = 0; i < events_bookings.data.length; i++) {
                            if (events_bookings.data[i].participants_bookings) {
                                let participantBookings = events_bookings.data[i].participants_bookings;
                                let participants = [];
                                for (var j = 0; j < participantBookings.length; j++) {
                                    participants.push({
                                        id: participantBookings[j].id,
                                        eventName: participantBookings[j].event_ticket.event.name,
                                        clientName: participantBookings[j].user.firstname + " " + participantBookings[j].user.lastname + " (" + participantBookings[j].user.nickname + ")",
                                        clientEmail: participantBookings[j].user.email,
                                        invitedName: participantBookings[j].invited_members.firstname + " " + participantBookings[j].invited_members.lastname + "(" + participantBookings[j].invited_members.nickname + ")",
                                        price: participantBookings[j].event_ticket.price + "€",
                                        status: participantBookings[j].status,
                                        url: participantBookings[j].url,
                                        creationDate: participantBookings[j].creation_date,
                                    })
                                }
                                data.push({
                                    id: participants.length > 0 ? participants[0].id : "",
                                    participants: participants,
                                    bookerClientName: participants.length > 0 ? participants[0].clientName : "",
                                    bookerClientEmail: participants.length > 0 ? participants[0].clientEmail : "",
                                    userId: events_bookings.data[i].user_id,
                                    tripId: events_bookings.data[i].trip_id
                                });
                            }
                        }
                    }
                    setEventsBookings(data);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsTableLoading(false);
                }
            );
    }

    return (
        <>
            <Dialog open={isRefreshingDocument}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            <EventsTable
                data={eventsBookings}
                loading={isTableLoading}
                onUploadDocument={onDocumentUpload}
                onConfirmationClick={onConfirmationClick}
            />
        </>
    );
}

export default EventsBookings;