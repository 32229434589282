import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    paddingLeft: '1px',
    width: '100%',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  statusAndDiscord: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  statusEllipse: {
    width: '10px',
    height: '10px',
    borderRadius: '10px / 10px',
  },
  statusText: {
    fontSize: '18px',
    fontWeight: 400,
    marginLeft: '10px',
  },
  confirmedStatus: {
    background: '#51D7B9',
  },
  pendingStatus: {
    background: '#FFB966',
  },
  welcomeText: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  listContainer: {
    marginTop: theme.spacing(2),
  },
  listItem: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
  },
  logoutContainer: {
    textAlign: 'center',
  },
  logoutButton: {
    color: theme.palette.common.black,
    fontSize: '18px',
    fontWeight: '400',
    marginBottom: theme.spacing(1),
    textDecoration: `underline ${theme.palette.common.black}`,
    '&:hover': {
      textDecoration: `underline ${theme.palette.common.black}`,
    },
  },
}));
