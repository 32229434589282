import React, { createContext, useEffect, useState } from "react";
import { auth } from "../firebaseConfig";

export const UserContext = createContext({ userDataPresent: false, user: null });

const UserProvider = (props) => {
  const [state, setState] = useState({
    userDataPresent: false,
    user: null,
    listener: null
  })

  useEffect(() => {
    if (state.listener === null) {
      setState({
        ...state, listener: auth.onAuthStateChanged((user) => {
          if (user) {
            setState(old => ({ ...old, userDataPresent: true, user: user }));
            localStorage.setItem('authUser', JSON.stringify(user));
          }
          else {
            setState(old => ({ ...old, userDataPresent: false, user: null }));
            localStorage.removeItem('authUser');
          }
        })
      })
    }

    return () => {
      if (state.listener) state.listener();
    }
  }, [])

  return (
    <UserContext.Provider value={state}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider;