import { Select, CircularProgress, makeStyles, TextField, Box, IconButton, Typography } from "@material-ui/core";
import DefaultMenuItem from 'components/DefaultMenuItem';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DefaultButton from 'components/DefaultButton';
import React, { useState, useEffect } from "react";
import { convertBytesToKiloBytes } from 'utils';
import { EventsApi, DestinationsApi } from 'services';
import { toastMessages } from 'config/constants';
import toast from 'react-hot-toast';
import useStyles from './styles';
import moment from 'moment';

function EditEventForm({ eventObject, onSubmit }) {
    const [eventName, setEventName] = useState(eventObject?.name);
    const [eventLabel, setEventLabel] = useState(eventObject?.label);
    const [eventDescription, setEventDescription] = useState(eventObject?.description);
    const [eventPopularScore, setEventPopularScore] = useState(eventObject?.popularScore);
    const [eventDestination, setEventDestination] = useState({
        id: eventObject?.destination?.id,
        name: eventObject?.destination?.name
    });
    const [eventStartDate, setEventStartDate] = useState(eventObject?.startDate);
    const [eventEndDate, setEventEndDate] = useState(eventObject?.endDate);
    const [eventSummary, setEventSummary] = useState(eventObject?.summary);
    const [eventAccess, setEventAccess] = useState(eventObject?.access);
    const [eventTips, setEventTips] = useState(eventObject?.tips);

    const [destinations, setDestinations] = useState([]);

    const [picture, setPicture] = useState({ file: null, url: eventObject?.pictureURL ?? null });

    const [isSubmitting, setSubmitting] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        fetchDestinations();
    }, []);

    async function fetchDestinations() {
        await DestinationsApi.getDestinations().then((destinations) => {
            var data = [];
            if (destinations && destinations.data) {
                for (var i = 0; i < destinations.data.length; i++) {
                    data.push({
                        id: destinations.data[i].id,
                        name: destinations.data[i].name,
                        subtitle: destinations.data[i].subtitle
                    });
                }
                setDestinations(data);
            }
        });
    }

    async function handleChange(event) {
        switch (event.target.name) {
            case 'name':
                setEventName(event.target.value);
                break;
            case 'label':
                setEventLabel(event.target.value);
                break;
            case 'description':
                setEventDescription(event.target.value);
                break;
            case 'popularScore':
                setEventPopularScore(event.target.value);
                break;
            case 'startDate':
                setEventStartDate(event.target.value);
                break;
            case 'endDate':
                setEventEndDate(event.target.value);
                break;
            case 'summary':
                setEventSummary(event.target.value);
                break;
            case 'access':
                setEventAccess(event.target.value);
                break;
            case 'tips':
                setEventTips(event.target.value);
                break;
        }
    }

    async function handleSubmit(event) {
        setSubmitting(true);
        event.preventDefault();
        let formData = new FormData();
        formData.append('name', eventName);
        formData.append('label', eventLabel);
        formData.append('description', eventDescription);
        formData.append('popular_score', eventPopularScore);
        formData.append('start_date', moment(eventStartDate).format('DD/MM/YYYY'));
        formData.append('end_date', moment(eventEndDate).format('DD/MM/YYYY'));
        formData.append('summary', eventSummary);
        formData.append('access', eventAccess);
        formData.append('tips', eventTips);
        if (eventDestination && eventDestination.id)
            formData.append('destination_id', eventDestination.id);
        if (picture && picture?.file)
            formData.append('file', picture?.file);

        await EventsApi.updateEvent(eventObject?.id, formData).then((_) => {
            setSubmitting(false);
            onSubmit();
        }).catch((error) => {
            setSubmitting(false);
            toast.error(toastMessages.error.EDIT_EVENT);
        });
    }

    const handlePictureChange = async (event) => {
        if (event.target.files != null && event.target.files.length > 0) {
            const file = new Blob([event.currentTarget.files[0]], { type: 'image/*' });
            const url = URL.createObjectURL(file);
            setPicture({ file: event.currentTarget.files[0], fileBlob: file, url: url });
            document.getElementById('eventImage').src = url;
        }
    };

    const handlePictureClick = (event) => {
        event.target.value = '';
    };

    return (
        <>
            <div id="headers">
                <h1 style={{ fontWeight: "500" }}>Editer un évènement</h1>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit} style={{ height: '100%', width: '100%' }}>

                <Box className={classes.imageContainer}>
                    <img
                        id="eventImage"
                        src={eventObject?.pictureURL}
                        alt="photo de l'évènement"
                        width="122px"
                        height="122px"
                        style={{ borderRadius: '5px' }}
                    />

                    <Box>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id={'eventPictureInput'}
                            name="eventPictureInput"
                            type="file"
                            onChange={handlePictureChange}
                            onClick={handlePictureClick}
                        />
                        <label htmlFor={'eventPictureInput'}>
                            <IconButton
                                aria-label="upload"
                                color="secondary"
                                component="span"
                            >
                                <ControlPointIcon style={{ height: '25px', width: '25px' }} fontSize="medium" />
                            </IconButton>
                        </label>
                        <Typography style={{ width: '80%', marginLeft: '10px' }}>
                            Vérifiez que l’image soit bien au format PNG ou JPG et qu’elle ne dépasse pas 5 Mo
                        </Typography>
                    </Box>
                </Box>

                <TextField
                    disabled
                    fullWidth
                    label="ID"
                    margin="dense"
                    name="id"
                    variant="outlined"
                    value={eventObject?.id}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Nom"
                    margin="dense"
                    name="name"
                    variant="outlined"
                    value={eventName}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Label"
                    margin="dense"
                    name="label"
                    variant="outlined"
                    value={eventLabel}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Date début"
                    margin="dense"
                    name="startDate"
                    variant="outlined"
                    type="date"
                    value={eventStartDate}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    fullWidth
                    label="Date fin"
                    margin="dense"
                    name="endDate"
                    variant="outlined"
                    type="date"
                    value={eventEndDate}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    fullWidth
                    label="Description"
                    margin="dense"
                    name="description"
                    variant="outlined"
                    value={eventDescription}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    type="number"
                    label="Note"
                    margin="dense"
                    name="popularScore"
                    variant="outlined"
                    value={eventPopularScore}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    disabled
                    label="Latitude"
                    margin="dense"
                    name="latitude"
                    variant="outlined"
                    value={eventObject?.latitude}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    disabled
                    label="Longitude"
                    margin="dense"
                    name="longitude"
                    variant="outlined"
                    value={eventObject?.longitude}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Résumé"
                    margin="dense"
                    name="summary"
                    variant="outlined"
                    value={eventSummary}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Accès"
                    margin="dense"
                    name="access"
                    variant="outlined"
                    value={eventAccess}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Conseils"
                    margin="dense"
                    name="tips"
                    variant="outlined"
                    value={eventTips}
                    onChange={handleChange}
                >
                </TextField>

                <label htmlFor={'destinationSelect'}>
                    Destination associée
                </label>
                <Select
                    id='destinationSelect'
                    displayEmpty
                    fullWidth
                    margin="dense"
                    value={eventDestination ?? ''}
                    onChange={(event) => {
                        const index = destinations.findIndex((destination) => destination.name == event.target.value);
                        setEventDestination({ id: destinations[index].id, name: event.target.value });
                    }}
                    renderValue={(eventDestination) => {
                        if (!eventDestination) return 'Choisir une destination associée';
                        return eventDestination.name;
                    }}
                >
                    {destinations.length === 0 && (
                        <DefaultMenuItem value="" disabled>
                            Aucune destination disponible
                        </DefaultMenuItem>
                    )}
                    {destinations.map((destination, index) => (
                        <DefaultMenuItem key={index} value={destination.name}>
                            {destination.name}
                        </DefaultMenuItem>
                    ))}
                </Select>

                <DefaultButton
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                >
                    {isSubmitting ? <CircularProgress size={20} /> : 'Valider'}
                </DefaultButton>

            </form>
        </>
    );
}

export default EditEventForm;