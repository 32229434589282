import { useEffect, useState } from 'react';
import axios from 'axios';

function useLocationSearch(query = '') {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (!query) return undefined;

    let cancel;
    axios({
      method: 'GET',
      url: 'https://api-adresse.data.gouv.fr/search',
      params: { q: query, type: 'municipality', limit: 10 },
      cancelToken: new axios.CancelToken(c => cancel = c),
    }).then(({ data: { features } }) => {
      setLocations(features);
    }).catch(error => {
      if (axios.isCancel(error)) return undefined;
    });

    return () => cancel();
  }, [query]);

  return { locations };
}

export default useLocationSearch;
