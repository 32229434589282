import axios from 'axios';

async function getTrips() {
    const response = await axios.get(`/admin/trips`);
    return response.data;
}

async function updateTrip(id, data) {
    const response = await axios.put(`/admin/trips/` + id.toString(), data);
    return response.data;
}

async function uploadTripDocument(tripId, data) {
    const response = await axios.post(`/admin/trips/`+ tripId + `/documents`, data);
    return response.data;
}

async function deleteTripDocument(documentId) {
    const response = await axios.delete(`/admin/trips/documents/` + documentId);
    return response.data;
}

async function exportTripsBookings(format, data) {
    let options = null;
    if (format === 'pdf')
        options = { responseType: 'blob' };
    const response = await axios.post(`/admin/trips/export?format=` + format, data, options);
    return response.data;
}

const tripService = {
    getTrips,
    updateTrip,
    uploadTripDocument,
    deleteTripDocument,
    exportTripsBookings
}

export default tripService;
