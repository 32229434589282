import { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import useStyles from './styles';
import DefaultSwitch from '../DefaultSwitch';

function MaiaSocialPanel({ active, date, onToggle, leftText, rightText }) {
    const classes = useStyles();
    const theme = useTheme();

    const [enabled, setEnabled] = useState(active);
    const displayedDate = moment().diff(date.value) > 0 ? 'Maintenant' : date.format;

    useEffect(() => {
        setEnabled(active);
    }, [active]);

    const onToggleSwitch = (event) => {
        const { target: { checked } } = event;
        setEnabled(checked);
        onToggle(event);
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <Paper className={classes.paper}>
                    <Box className={classes.rightPart}>
                        <Typography className={classes.textOn}>{leftText}</Typography>
                        <DefaultSwitch
                            checked={enabled}
                            checkedIcon={CheckIcon}
                            color={theme.palette.primary.main}
                            onChange={onToggleSwitch}
                        />
                        <Typography className={classes.textOff}>{rightText}</Typography>
                    </Box>
                </Paper>
                <Typography className={classes.date} variant='body2'>{displayedDate}</Typography>
            </Box>
        </Box>
    )
}

export default MaiaSocialPanel;
