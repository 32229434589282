import axios from 'axios';

async function getActivities() {
    const response = await axios.get(`/admin/activities`);
    return response.data;
}

async function updateActivity(id, data) {
    const response = await axios.put(`/admin/activities/` + id, data);
    return response.data;
}

async function createActivity(data) {
    const response = await axios.post(`/admin/activities`, data);
    return response.data;
}

const activitiesService = {
    getActivities,
    updateActivity,
    createActivity
}

export default activitiesService;
