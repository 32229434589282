import DataTable from 'components/DataTable';
import { Card, Button } from "@material-ui/core";
import { bookingStatus } from 'config/constants';
import { useState, useMemo } from 'react';
import DataTableExportToolbar from "components/DataTableExportToolbar";
import { ActivitiesBookingsApi } from 'services';
import toast from 'react-hot-toast';
import { toastMessages } from 'config/constants';
import moment from 'moment';

const headCells = [
    { id: 'tripId', label: 'ID voyage', sortable: true },
    { id: 'userId', label: 'ID du client passant la commande', sortable: true },
    { id: 'bookerClientName', label: 'Pseudo', sortable: true },
    { id: 'bookerClientEmail', label: 'Email', sortable: true },
    { id: 'activityTicketAvailabilityId', label: 'ID activité', sortable: true }
];

const nestedHeadCells = [
    { id: 'id', label: 'ID réservation', sortable: true },
    { id: 'activityName', label: 'Nom activité', sortable: true },
    { id: 'invitedName', label: 'Bénéficiaire', sortable: true },
    { id: 'price', label: 'Prix basse/haute saison', sortable: false },
    { id: 'status', label: 'Statut paiement', sortable: true },
    { id: 'document', label: 'Document de réservation', sortable: false },
    { id: 'confirmation', label: 'Confirmation', sortable: false },
    { id: 'creationDate', label: 'Date de réservation', sortable: true },
]

function ActivitiesTable({ data, loading, onConfirmationClick, onDeleteActivityDocument, onUploadDocument }) {

    const [searchValue, setSearchValue] = useState('');
    const [exporting, setExporting] = useState(false);

    const onDocumentClick = (event) => {
        event.target.value = '';
    };

    const normalizedData = useMemo(() => Array.from(data).map((activityBooking, index) => ({
        ...activityBooking,
        collapsableComponent: (
            <>
                <DataTable
                    headCells={nestedHeadCells}
                    data={activityBooking.participants.map((participant) => {
                        return {
                            ...participant,
                            creationDate: moment(participant.creationDate).format('DD/MM/YYYY HH:mm'),
                            confirmation: {
                                component: (
                                    <>
                                        <Button
                                            disabled={participant.status === bookingStatus.paid}
                                            variant="contained"
                                            onClick={(event) =>
                                                onConfirmationClick(event, participant)
                                            }
                                        >
                                            Confirmer
                                        </Button>
                                    </>
                                )
                            },
                            document: {
                                component: (
                                    <>
                                        {participant.url && <a href={participant.url}>Document de l'activité</a>}
                                        {!participant.url && 'Ajoutez un document pour la réservation d\'activité'}
                                        <Card>
                                            <input
                                                accept=".jpg,.jpeg,.png,.pdf"
                                                onChange={(event) => onUploadDocument(event, participant.id)}
                                                onClick={onDocumentClick}
                                                id="postDocumentInput"
                                                name="postDocument"
                                                type="file"
                                            />
                                        </Card>
                                    </>
                                )
                            },
                            status: {
                                component: (
                                    <>
                                        {participant.status === bookingStatus.onHold && <div
                                            style={{
                                                backgroundColor: "rgba(255,10,0, 0.6)",
                                                fillOpacity: "0.5",
                                                borderRadius: "15px",
                                                flex: "diplay",
                                                textAlign: "center",
                                            }}
                                        >
                                            En attente
                                        </div>}
                                        {participant.status === bookingStatus.paid && <div
                                            style={{
                                                backgroundColor: "rgba(0,190,0, 0.6)",
                                                fillOpacity: "0.5",
                                                borderRadius: "15px",
                                                flex: "diplay",
                                                textAlign: "center",
                                            }}
                                        >
                                            Payée
                                        </div>
                                        }
                                    </>
                                )
                            },
                        }
                    })}
                    loading={loading}
                    isCollapsable={false}
                />
            </>
        ),
        id: activityBooking.id,
        activityName: activityBooking.activityName,
    })).filter(v => Object.values(v).map(v => String(v)).find(f => searchValue ? f.toLowerCase().includes(searchValue.toLowerCase()) : true)), [data, searchValue]);

    const handleExport = async (selectedData, format, useSelected) => {
        if (exporting) return;

        setExporting(true);

        let activitiesBookingsIds;
        if (useSelected) {
            activitiesBookingsIds = selectedData.sort((a, b) => a - b);
        } else {
            activitiesBookingsIds = data.map(b => b.id);
        }

        setExporting(false);

        await ActivitiesBookingsApi.exportActivitiesBookings(format, { ids: activitiesBookingsIds })
            .then((result) => {
                let blob;
                if (format === 'csv')
                    blob = new Blob(['\ufeff' + result], { type: 'text/csv;' });
                else
                   blob = new Blob([result], { type: 'application/pdf' });
                const filename = `Réservations activités Maia Partners.${format}`;
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, filename);
                } else {
                    let link = document.createElement('a');
                    if (link.download !== undefined) {
                        link.setAttribute('href', URL.createObjectURL(blob));
                        link.setAttribute('download', filename);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }

                setExporting(false);
            })
            .catch(() => {
                toast.error(toastMessages.error.EXPORT_ACTIVITIES_BOOKINGS);
                setExporting(false);
            });
    }

    return <DataTable
        headCells={headCells}
        data={normalizedData}
        loading={loading}
        ToolbarComponent={DataTableExportToolbar}
        toolbarProps={{
            onSearchChange: (event) => setSearchValue(event.target.value),
            onExport: (data, format, useSelected) => handleExport(data, format, useSelected),
            nbSelectedItems: 0,
        }}
        isCollapsable={true}
    />;
}

export default ActivitiesTable;
