import { Grid, Typography } from '@material-ui/core';
import GoBack from 'components/GoBack';
import useStyles from './styles';

function HeaderParameters({ image, isSubmitting = false, noMargin, onClick, text, ...props }) {
  const classes = useStyles({ noMargin });

  return (
    <Grid className={classes.root} item xs={12} {...props}>
      <GoBack className={classes.goBack} isSubmitting={isSubmitting} onClick={onClick} />
      {!!image && <img className={classes.image} alt='Header' src={image} width={100} height={100} />}
      <Typography variant='h1'>{text}</Typography>
    </Grid>
  )
}

export default HeaderParameters;
