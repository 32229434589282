import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  button: {
    height: '50px',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  socialContainer: {
    paddingLeft: theme.spacing(4),
  },
  formContainer: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
}));
