import { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import SwitchStatus from '../SwitchStatus';
import useStyles from './styles';
import DefaultSwitch from '../DefaultSwitch';

function SocialPanel({ active, altText, date, disabled, icon, onToggle, textOn, textOff }) {
  const classes = useStyles();
  const theme = useTheme();

  const [enabled, setEnabled] = useState(active);
  const displayedDate = moment().diff(date.value) > 0 ? 'Maintenant' : date.format;

  useEffect(() => {
    setEnabled(active);
  }, [active]);

  const onToggleSwitch = (event) => {
    const { target: { checked }} = event;
    setEnabled(checked);
    onToggle(event);
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Paper className={classes.paper}>
          <img src={icon} alt={altText} width='30' height='30' />
          <Box className={classes.rightPart}>
            <DefaultSwitch
              checked={enabled}
              checkedIcon={CheckIcon}
              color={theme.palette.primary.main}
              disabled={!!disabled}
              onChange={onToggleSwitch}
            />
            <SwitchStatus status={enabled} textOn={textOn} textOff={textOff} />
          </Box>
        </Paper>
        <Typography className={classes.date} variant='body2'>{displayedDate}</Typography>
      </Box>
    </Box>
  )
}

export default SocialPanel;
