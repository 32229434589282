import axios from 'axios';

async function getPartners() {
    const response = await axios.get(`/admin/partners`);

    return response.data;
}

const partnersService = {
    getPartners,
}

export default partnersService;
