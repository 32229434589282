import { Button, withStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';

const CancelButton = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    marginRight: '24px',
    textDecorationLine: 'underline',
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },
}))(Button);

const CustomButton = withStyles((theme) => ({
  root: {
    border: (props) => `2px solid ${theme.palette[props.customColor].main}`,
    color: (props) => theme.palette[props.customColor].main,
  },
}))(Button);

const StandardButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.blue.lighter,

    '&:hover': {
      backgroundColor: darken(theme.palette.blue.lighter, 0.1),
    },
  },
}))(Button);

function DefaultButton(props) {
  const { cancel, standard, children, customColor, variant, ...other } = props;

  if (!!cancel)
    return (
      <CancelButton variant={variant} {...other}>
        {children || ''}
      </CancelButton>
    );

  if (!!standard)
    return (
      <StandardButton variant={variant} {...other}>
        {children || ''}
      </StandardButton>
    );

  if (variant === 'outlined-custom')
    return (
      <CustomButton customColor={customColor} {...other}>
        {children || ''}
      </CustomButton>
    );

  return (
    <Button variant={variant} {...other}>
      {children || ''}
    </Button>
  );
}

export default DefaultButton;
