import { CircularProgress, Grid, Typography } from '@material-ui/core';
import DefaultButton from 'components/DefaultButton';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { TgxApi } from 'services';

function TGX() {

    const [isSubmitting, setSubmitting] = useState(false);

    // TODO : list in this page all suppliers / all suppliers hotels received from TGX and stored in database

    const onButtonClick = async () => {
        try {
            setSubmitting(true);
            const result = await TgxApi.createTgxSuppliers();
            console.log('result : ' + JSON.stringify(result));
            if (result && result.status && result.status === 'OK')
                toast.success('L\'importation des hotels et des fournisseurs a été réalisée avec succès !');
        } catch (error) {
            console.log('error : ' + JSON.stringify(error));
            toast.error(error.toString());
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Grid container spacing={3} alignItems="center" justifyContent="center" direction="column">
            <DefaultButton
                color="secondary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                onClick={onButtonClick}
            >
                {isSubmitting ? <CircularProgress size={20} /> : 'Forcer la récupération des hotels et fournisseurs'}
            </DefaultButton>
            {isSubmitting && <Typography>Le traitement peut prendre plusieurs minutes</Typography>}
        </Grid>
    );
};

export default TGX;
