import {Box, Button, Typography, withStyles} from "@material-ui/core";
import {useState} from "react";

const StdIconButton = withStyles( (theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.blue.lighter,
    minWidth:0,
    width:50,
    height:40,
    marginBottom:15,
    '&:hover':{
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  }
}))(Button);

function DefaultIconButton(props) {
  const { icon, hoveredIcon, iconAlt, iconText, ...other } = props;
  const [actualIcon, setActualIcon] = useState(icon);

  let mouseOverFunction;
  let mouseOutFunction;
  let buttonChildren;
  if(typeof icon === 'string'){
    mouseOverFunction = _ => (setActualIcon(hoveredIcon));
    mouseOutFunction = _ => (setActualIcon(icon));
    buttonChildren = <img src={actualIcon} alt={iconAlt} width={30} height={30}/>;
  } else {
    mouseOutFunction = _ => {};
    mouseOverFunction = _ => {};
    buttonChildren = icon;
  }

  return (
    <Box style={{display:'flex', flexDirection:'column', alignItems:'center', margin:15}}>
      <StdIconButton
        {...other}
        onMouseOver={mouseOverFunction}
        onMouseOut ={mouseOutFunction}
      >
        {buttonChildren}
      </StdIconButton>
      <Typography color='primary' style={{fontSize:18, lineHeight:'21.09px'}}> {iconText} </Typography>
    </Box>
  )
}

export default DefaultIconButton;
