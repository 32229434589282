import { createContext, useCallback, useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { AdminPostApi } from 'services';
import { toastMessages } from 'config/constants';
// import { useCompany } from 'contexts/CompanyContext';
import { useAdmin } from 'contexts/AdminContext';
import moment from 'moment';
import momentFR from 'moment/min/locales';
import toast from 'react-hot-toast';
import useGenericContext from 'hooks/useGenericContext';

moment.locale('fr', momentFR);

const PostContext = createContext(undefined);
const PostFunctionsContext = createContext(undefined);

const initialPostState = {
  comment: '',
  file: null,
  hashtag: '',
  location: null,
}

const initialPostDates = () => ({
  instagram: {
    format: moment().format('ddd DD MMM HH:mm'),
    value: moment(),
  },
  facebook: {
    format: moment().format('ddd DD MMM HH:mm'),
    value: moment(),
  },
  maia: {
    format: moment().format('ddd DD MMM HH:mm'),
    value: moment(),
  },
})

function PostProvider({ children }) {
  const [createMode, setCreateMode] = useState(true);

  const [newPostDates, setNewPostDates] = useState(initialPostDates());
  const [postToCreate, setPostToCreate] = useState(initialPostState);

  const [currentPost, setCurrentPost] = useState(null);
  const [currentPostDates, setCurrentPostDates] = useState(null);

  const [posts, setPosts] = useState([]);
  const [postSaving, setPostSaving] = useState(false);

  const { admin } = useAdmin();

  const resetPost = useCallback(() => {
    setPostToCreate(initialPostState);
    setNewPostDates(initialPostDates());
  }, [setNewPostDates, setPostToCreate]);

  const resetCurrentPost = useCallback(() => {
    setCreateMode(true);
    setCurrentPost(null);
    setCurrentPostDates(null);
  }, [setCreateMode, setCurrentPost, setCurrentPostDates]);

  const loadPosts = useCallback(async (page) => {
    if (isNil(admin?.id)) return { error: true };

    try {
      const { data, pagination } = await AdminPostApi.listPosts(page);
      if (data.length === 0) return { empty: true };

      if (page === 0 ) setPosts(data);
      else setPosts((prev) => [...prev, ...data]);
      return { empty: false, hasNext: pagination.totalPages > pagination.currentPage + 1 };
    } catch (e) {
      toast.error(toastMessages.error.FETCH_POSTS);
      return { error: true }
    }
  }, []);

  const createPost = useCallback(async (platform, data) => {
    if (isNil(admin?.id)) return { created: false };

    setPostSaving(true);
    try {
      const response = await AdminPostApi.createPost(platform, data);

      toast.success(toastMessages.success.CREATE_POST);
      setPostSaving(false);
      return { created: true , post: response};
    } catch (e) {
      toast.error(toastMessages.error.CREATE_POST);
      setPostSaving(false);
      return { created: false, post: null };
    }
  }, []);

  const deletePost = useCallback(async ({ id }) => {
    if (isNil(admin?.id)) return;

    setPostSaving(true);
    try {
      await AdminPostApi.deletePost(id);
      resetCurrentPost();

      setPostSaving(false);
      toast.success(toastMessages.success.DELETE_POST);
      return true;
    } catch (e) {
      setPostSaving(false);
      toast.error(toastMessages.error.DELETE_POST);
      return false;
    }
  }, [resetCurrentPost]);

  const updatePost = useCallback(async (post, data) => {
    if (isNil(admin?.id) /*|| createMode*/) return { updated: false };

    setPostSaving(true);
    try {
      const response = await AdminPostApi.updatePost(post.id, data);

      toast.success(toastMessages.success.UPDATE_POST);
      setCreateMode(true);
      setPostSaving(false);
      return { updated: true , post: response};
    } catch (e) {
      toast.error(toastMessages.error.UPDATE_POST);
      setPostSaving(false);
      return { updated: false, post: null };
    }
  }, [createMode]);

  const value = useMemo(
    () => ({
      createMode,
      currentPost,
      currentPostDates,
      newPostDates,
      posts,
      postSaving,
      postToCreate,
    }), [
      createMode,
      currentPost,
      currentPostDates,
      newPostDates,
      posts,
      postSaving,
      postToCreate,
    ]
  );

  const functions = useMemo(
    () => ({
      createPost,
      deletePost,
      loadPosts,
      resetCurrentPost,
      resetPost,
      setCreateMode,
      setCurrentPost,
      setCurrentPostDates,
      setNewPostDates,
      setPosts,
      setPostToCreate,
      updatePost,
    }), [
      createPost,
      deletePost,
      loadPosts,
      resetCurrentPost,
      resetPost,
      setCreateMode,
      setCurrentPost,
      setCurrentPostDates,
      setNewPostDates,
      setPosts,
      setPostToCreate,
      updatePost,
    ]
  );

  return (
    <PostContext.Provider value={value}>
      <PostFunctionsContext.Provider value={functions}>
        {children}
      </PostFunctionsContext.Provider>
    </PostContext.Provider>
  )
}

/**
 * Return the items values.
 * @return {{
 *  currentPost: Object,
 *  posts: Array,
 * }}
 */
function usePost() {
  return useGenericContext(
    PostContext,
    'usePost cannot be used outside of a PostProvider component.',
  );
}

/**
 * Return the functions manipulating items.
 * @return {{  }}
 */
function usePostFunctions() {
  return useGenericContext(
    PostFunctionsContext,
    'usePostFunctions cannot be used outside of a PostProvider component',
  );
}

export { PostProvider, usePost, usePostFunctions };
