import DataTable from 'components/DataTable';
import { Button } from '@material-ui/core';
import { providersStatus } from 'config/constants';
import { useState, useMemo } from 'react';
import { ProviderApi } from 'services';
import { toastMessages } from 'config/constants';
import toast from 'react-hot-toast';
import DataTableExportToolbar from "components/DataTableExportToolbar";

const headCells = [
    { id: 'providerId', label: 'ID Prestataire', sortable: true },
    { id: 'status', label: 'Statut', sortable: true },
    { id: 'termsAcceptation', label: 'Contrat', sortable: true },
    { id: 'firstName', label: 'Prénom', sortable: true },
    { id: 'lastName', label: 'Nom', sortable: true },
    { id: 'age', label: 'Age', sortable: true },
    { id: 'email', label: 'Email', sortable: true },
    { id: 'creationDate', label: "Date d'inscription", sortable: true },
    { id: 'phone', label: 'Téléphone', sortable: true },
    { id: 'city', label: 'Ville', sortable: true },
    { id: 'country', label: 'Pays', sortable: true },
    { id: 'socialName', label: 'Nom Social', sortable: true },
    { id: 'siren', label: 'SIREN', sortable: false },
    { id: 'bankName', label: 'Nom Banque', sortable: true },
    { id: 'insuranceName', label: 'Nom Assurance', sortable: true },
    { id: 'iban', label: 'IBAN', sortable: false },
    { id: 'insuranceNumber', label: 'N° Assurance', sortable: true },
    { id: 'kbisDocument', label: 'KBIS', sortable: false },
    { id: 'bankDocument', label: 'Banque', sortable: false },
    { id: 'insuranceDocument', label: 'Assurance', sortable: false },
    { id: 'idCardDocument', label: "Carte d'identité", sortable: false },
    { id: 'validation', label: 'Validation', sortable: false },
    { id: 'deletion', label: 'Suppression', sortable: false },
    { id: 'ban', label: 'Bannissement', sortable: false }
];

function ProvidersTable({ data, loading, onValidateClick, onDeletionClick, onBanClick, openFile }) {

    const [searchValue, setSearchValue] = useState('');
    const [exporting, setExporting] = useState(false);

    const normalizedData = useMemo(() => Array.from(data).map((provider, index) => ({
        ...provider,
        providerId: provider.providerId,
        status: {
            component: (
                <>
                    {provider.confirmationStatus === providersStatus.confirmed && <div
                        style={{
                            backgroundColor: "rgba(0,190,0, 0.6)",
                            fillOpacity: "0.5",
                            borderRadius: "15px",
                            flex: "diplay",
                            textAlign: "center",
                        }}
                    >
                        {providersStatus.confirmed}
                    </div>}
                    {provider.confirmationStatus === providersStatus.unconfirmed && <div
                        style={{
                            backgroundColor: "rgba(255,10,0, 0.6)",
                            fillOpacity: "0.5",
                            borderRadius: "15px",
                            flex: "diplay",
                            textAlign: "center",
                        }}
                    >
                        {providersStatus.unconfirmed}
                    </div>
                    }
                    {provider.confirmationStatus === providersStatus.uncompleted && <div
                        style={{
                            backgroundColor: "rgba(255,215,0, 1)",
                            fillOpacity: "0.5",
                            borderRadius: "15px",
                            flex: "diplay",
                            textAlign: "center",
                        }}
                    >
                        {providersStatus.uncompleted}
                    </div>
                    }
                </>
            )
        },
        termsAcceptation: {
            component: (
                <>
                    {provider.contract_checked ? (<div
                        style={{
                            backgroundColor: "rgba(22,184,78, 1.0)",
                            fillOpacity: "0.5",
                            borderRadius: "15px",
                            flex: "diplay",
                            textAlign: "center",
                        }}
                    >
                        Accepté
                    </div>
                    ) : (<div
                        style={{
                            backgroundColor: "rgba(255,10,0, 0.6)",
                            fillOpacity: "0.5",
                            borderRadius: "15px",
                            flex: "diplay",
                            textAlign: "center",
                        }}
                    >
                        En attente
                    </div>
                    )}
                </>
            )
        },
        firstName: provider.firstName,
        lastName: provider.lastName,
        age: provider.age,
        email: provider.email,
        creationDate: provider.creationDate,
        phone: provider.phone,
        city: provider.city,
        country: provider.country,
        socialName: provider.socialName,
        siren: provider.siren,
        bankName: provider.bankName,
        insuranceName: provider.insuranceName,
        iban: "", // TODO
        insuranceNumber: provider.insuranceNumber, // TODO
        kbisDocument: {
            component: (
                <Button
                    disabled={provider.kbis !== null ? false : true}
                    style={{ margin: "1em" }}
                    variant="contained"
                    onClick={(event) =>
                        openFile(event, provider.kbis)
                    }
                >
                    KBIS
                </Button>
            )
        },
        bankDocument: {
            component: (
                <Button
                    disabled={provider.bank !== null ? false : true}
                    style={{ margin: "1em" }}
                    variant="contained"
                    onClick={(event) =>
                        openFile(event, provider.bank)
                    }
                >
                    Banque
                </Button>
            )
        },
        insuranceDocument: {
            component: (
                <Button
                    disabled={provider.insurance !== null ? false : true}
                    style={{ margin: "1em" }}
                    variant="contained"
                    onClick={(event) =>
                        openFile(event, provider.insurance)
                    }
                >
                    Assurance
                </Button>
            )
        },
        idCardDocument: {
            component: (
                <Button
                    variant="contained"
                    disabled={provider.idCard !== null ? false : true}
                    style={{ margin: "1em" }}
                    onClick={(event) =>
                        openFile(event, provider.idCard)
                    }
                >
                    Carte d'identité
                </Button>
            )
        },
        validation: {
            component: (
                <>
                    {provider.confirmationStatus !== providersStatus.confirmed && <Button
                        color="primary"
                        variant="contained"
                        style={{ margin: "1em" }}
                        onClick={(event) => {
                            onValidateClick(event, provider);
                        }}
                    >
                        Valider
                    </Button>
                    }
                </>
            )
        },
        deletion: {
            component: (
                <>
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ margin: "1em" }}
                        onClick={(event) => {
                            onDeletionClick(event, provider);
                        }}
                    >
                        Supprimer
                    </Button>
                </>
            )
        },
        ban: {
            component: (
                <>
                    <Button
                        disabled={provider.isBanned}
                        color="primary"
                        variant="contained"
                        style={{ margin: "1em" }}
                        onClick={(event) => {
                            onBanClick(event, provider);
                        }}
                    >
                        {provider.isBanned ? "Banni" : "Bannir"}
                    </Button>
                </>
            )
        },
    })).filter(v => Object.values(v).map(v => String(v)).find(f => searchValue ? f.toLowerCase().includes(searchValue.toLowerCase()) : true)), [data, searchValue]);

    const handleExport = (selectedData, format, useSelected) => {
        if (exporting) return;

        setExporting(true);

        let providersIds;
        if (useSelected) {
            providersIds = selectedData.sort((a, b) => a - b);
        } else {
            providersIds = data.map(b => b.providerId);
        }

        setExporting(false);

        ProviderApi.exportProviders(format, { ids: providersIds })
            .then((result) => {
                let blob;
                if (format === 'csv') blob = new Blob(['\ufeff' + result], { type: 'text/csv;' });
                else blob = new Blob([result], { type: 'application/pdf' });

                const filename = `Prestataires Maia Partners.${format}`;

                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, filename);
                } else {
                    let link = document.createElement('a');
                    if (link.download !== undefined) {
                        link.setAttribute('href', URL.createObjectURL(blob));
                        link.setAttribute('download', filename);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }

                setExporting(false);
            })
            .catch(() => {
                toast.error(toastMessages.error.EXPORT_PROVIDERS);
                setExporting(false);
            });
    }

    return <DataTable
        headCells={headCells}
        data={normalizedData}
        loading={loading}
        ToolbarComponent={DataTableExportToolbar}
        toolbarProps={{
            onSearchChange: (event) => setSearchValue(event.target.value),
            onExport: (data, format, useSelected) => handleExport(data, format, useSelected),
            nbSelectedItems: 0,
        }}
    />;
}

export default ProvidersTable;
