import { Box, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import DefaultButton from 'components/DefaultButton';
import useStyles from './styles';

function DefaultModal(props) {
  const {
    cancelButtonText = 'Annuler',
    children,
    confirmButtonText = 'Valider',
    disableConfirm,
    forceConfirm,
    hideActions = false,
    info,
    infoButtonText = 'OK',
    loading = false,
    onClose = () => {},
    onConfirm = () => {},
    title = null,
    square = false,
    ...other
  } = props;
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (!forceConfirm) onClose(event, reason);
  };
  let squareStyle = {};

  if (square) {
    squareStyle = { maxWidth: '420px', height: '350px', width: '420px', minWidth: '0px' };
  }

  return (
    <Dialog
      className={classes.root}
      onClose={handleClose}
      PaperProps={{ style: squareStyle }}
      disableScrollLock
      fullWidth
      {...other}
    >
      <DialogContent className={classes.content}>
        <Box className={classes.title}>
          <Typography variant="h2" color="primary">
            {title}
          </Typography>
        </Box>
        {children}
      </DialogContent>

      {!hideActions && (
        <DialogActions>
          <Grid container justifyContent="center">
            {!!info ? (
              <DefaultButton disabled={loading} standard onClick={onClose}>
                {infoButtonText}
              </DefaultButton>
            ) : (
              <>
                <DefaultButton cancel disabled={loading} className={classes.button} onClick={onClose}>
                  {cancelButtonText}
                </DefaultButton>
                <DefaultButton
                  className={classes.button}
                  disabled={!!disableConfirm || loading}
                  standard
                  onClick={onConfirm}
                >
                  {loading ? <CircularProgress size={20} /> : confirmButtonText}
                </DefaultButton>
              </>
            )}
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default DefaultModal;
