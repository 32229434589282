import React, { useEffect, useState } from "react";

import ProvidersTable from './components/ProvidersTable';
import "../../components/Dashboard/Activities.css";

import { Grid, Button, Dialog } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ProviderApi } from "services";
import { popupMessages, providersStatus } from "config/constants";
import { computeAge } from "utils";

const Providers = ({ company }) => {
  const [providers, setProviders] = useState([]);
  const [openValidate, setOpenValidate] = useState(false);
  const [openSupp, setOpenSupp] = useState(false);
  const [openBan, setOpenBan] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [forceValidation, setForceValidation] = useState(0);
  const [currentProvider, setCurrentProvider] = useState(0);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const [forceValidate, setForceValidate] = useState(false);
  const [loginValidate, setLoginValidate] = useState("");
  const [dateValidate, setDateValidate] = useState("");

  const [activateFilters, setActivateFilters] = useState([]);
  const [useFilters, setUseFilters] = useState(false);

  useEffect(() => {
    fetchProviders();
  }, []);

  function openFilter() {
    setUseFilters(!useFilters);
    setActivateFilters([]);
  }

  async function banPrestataire() {
    await ProviderApi.banProvider(currentProvider.providerId).then((result) => {
      for (var i = 0; i < providers.length; i++) {
        if (providers[i].providerId === id) {
          let providersCopy = [...providers];
          let provider = { ...providers[i] };
          provider.isBanned = true;
          providersCopy[i] = provider;
          setProviders(providersCopy);
        }
      }
      setOpenBan(false);
    });
  }

  async function removePrestataire() {
    await ProviderApi.deleteProvider(currentProvider.providerId).then((_) => {
      for (var i = 0; i < providers.length; i++) {
        if (providers[i].providerId === id) {
          let providersCopy = [...providers];
          providersCopy.splice(i, 1);
          setProviders(providersCopy);
          setCurrentProvider(null);
        }
      }
      setOpenSupp(false);
    });
  }

  async function updateConfirmed() {
    let data = new FormData();
    data.append('force_validate', forceValidation);
    await ProviderApi.confirmProvider(currentProvider.providerId, data)
      .then((_) => {
        for (var i = 0; i < providers.length; i++) {
          if (providers[i].providerId === id) {
            let providersCopy = [...providers];
            let provider = { ...providers[i] };
            var today = Date.now();
            provider.force_validate = forceValidation === 1 ? true : false;
            provider.date_validation = new Intl.DateTimeFormat("fr-FR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(today);
            provider.login_validation = localStorage.getItem("email");
            provider.confirmationStatus = providersStatus.confirmed;
            provider.is_confirmed = true;
            providersCopy[i] = provider;
            setProviders(providersCopy);
          }
        }
        setOpenValidate(false);
      })
  }

  const openFile = async (event, file) => {
    event.preventDefault();
    if (file) {
      window.open(file);
    }
  };

  function formatStatus(row) {
    if (row.is_confirmed === true) return providersStatus.confirmed;
    else if (
      row.kbis != null ||
      row.bank != null ||
      row.insurance != null ||
      row.cardId != null
    )
      return providersStatus.uncompleted;
    else return providersStatus.unconfirmed;
  }

  async function fetchProviders() {
    setIsTableLoading(true);
    ProviderApi.getProviders()
      .then(
        (providers_data) => {
          setIsTableLoading(false);
          var data = [];
          if (providers_data && providers_data.providers) {
            let providers = providers_data.providers;
            for (var i = 0; i < providers.length; i++) {
              var company = null;
              if (providers[i].provider && providers[i].provider.company)
                company = providers[i].provider.company;
              data.push({
                providerId: providers[i].id,
                firstName: providers[i].first_name,
                lastName: providers[i].last_name,
                age: computeAge(providers[i].birthday),
                email: providers[i].email,
                creationDate: providers[i].creation_date ? new Date(providers[i].creation_date).toISOString().replace(/T/, ' ').replace(/\..+/, '') : "",
                phone: providers[i].phone,
                country: providers[i].country,
                city: providers[i].address ? providers[i].address.city_name : null,
                bankName: company ? company.bank_name : null,
                insuranceName: company ? company.insurance_name : null,
                insuranceNumber: company ? company.insurance_number : null,
                iban: company ? company.iban : null,
                siren: company ? company.siren : null,
                date: company ? company.insurance_validity_date : null,
                socialName: company ? company.social_name : null,
                kbis: company && company.kbisURL ? company.kbisURL : null,
                bank: company && company.bankURL ? company.bankURL : null,
                insurance: company && company.insuranceURL ? company.insuranceURL : null,
                idCard: providers[i].idCardURL ? providers[i].idCardURL : null,
                force_validate: providers[i].force_validate,
                login_validation: providers[i].login_validation,
                contract_checked: providers[i].contract_checked,
                is_confirmed: providers[i].is_confirmed,
                confirmationStatus: formatStatus({
                  is_confirmed: providers[i].is_confirmed,
                  kbis: company && company.kbisURL,
                  bank: company && company.bankURL,
                  insurance: company && company.insuranceURL,
                  idCard: providers[i].idCardURL,
                }),
                date_validation: providers[i].date_validation,
                isBanned: providers[i].status
              });
            }
          }
          setProviders(data);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsTableLoading(false);
        }
      );
  }

  const onProviderValidateClick = (event, selectedProvider) => {
    setId(selectedProvider.providerId);
    setCurrentProvider(selectedProvider);
    setForceValidation(0);
    setTitle(popupMessages.title.providerValidation);
    if (selectedProvider.kbis !== null &&
      selectedProvider.bank !== null &&
      selectedProvider.insurance !== null &&
      selectedProvider.idCard !== null)
      setMessage(popupMessages.message.providerValidation);
    else {
      setForceValidation(1);
      setMessage(popupMessages.message.providerForceValidation);
    }
    setOpenValidate(true);
    // setAnchorEl(event.currentTarget);
  };

  const onProviderDeleteClick = (event, selectedProvider) => {
    setId(selectedProvider.providerId);
    setCurrentProvider(selectedProvider);
    if (selectedProvider.confirmationStatus === providersStatus.confirmed) {
      setTitle(popupMessages.title.confirmedProviderDeletion);
      setMessage(popupMessages.message.confirmedProviderDeletion);
    } else {
      setTitle(popupMessages.title.unconfirmedProviderDeletion);
      setMessage(popupMessages.message.unconfirmedProviderDeletion);
    }
    setOpenSupp(true);
  };

  const onProviderBanClick = (event, selectedProvider) => {
    setOpenBan(true);
    setId(selectedProvider.providerId);
    setCurrentProvider(selectedProvider);
    setTitle(popupMessages.title.providerBan);
    setMessage(popupMessages.message.providerBan);
  };

  const handleClose = () => {
    setOpenValidate(false);
    setOpenSupp(false);
    setOpenBan(false);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const headerCsv = [
    {
      label: "Id Client",
      key: "providerId",
    },
    {
      label: "Prénom",
      key: "firstName",
    },
    {
      label: "Nom",
      key: "lastName",
    },
    {
      label: "Status",
      key: "is_confirmed",
    },
    {
      label: "Age",
      key: "birthday",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Date d'inscription",
      key: 'creationDate',
    },
    {
      label: "Téléphone",
      key: "phone",
    },
    {
      label: "Ville",
      key: "city",
    },
    {
      label: "Pays",
      key: "country",
    },
    {
      label: "Nom Social",
      key: "socialName",
    },
    {
      label: "SIREN",
      key: "siren",
    },
    {
      label: "Nom Banque",
      key: "bankName",
    },
    {
      label: "Nom Assurance",
      key: "insuranceName",
    },
    {
      label: "IBAN",
      key: "iban",
    },
    {
      label: "N° Assurance",
      key: "insuranceNumber",
    },
  ];

  return (
    <Grid className="Clients">
      <div id="headers">
        <h1 style={{ fontWeight: "500" }}>Prestataires</h1>
        {/* <div> */}
        {/* <img
            alt="logoFilter"
            src={filterLogo}
            onClick={(e) => {
              openFilter();
            }}
          />
          <CSVLink data={providers} headers={headerCsv} filename="prestataires.csv">
            <img alt="logoDownload" src={downloadLogo} />
          </CSVLink> */}
        {/* </div> */}
      </div>
      <Dialog
        open={openInfo}
        onClose={handleCloseInfo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ textAlign: "center", color: "#4459C6" }}
          id="alert-dialog-title"
        >
          {"Information supplémentaire !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ textAlign: "center", color: "#4459C6" }}
            id="alert-dialog-description"
          >
            <div style={{ color: "#4459C6" }}>
              Force validé : {forceValidate === true ? "oui" : "non"}
            </div>
            <div style={{ color: "#4459C6" }}>Validé par : {loginValidate}</div>
            <div style={{ color: "#4459C6" }}>Le : {dateValidate}</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleCloseInfo} color="primary">
            <div style={{ color: "#4459C6" }}>Ok</div>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openValidate}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ textAlign: "center", color: "red" }}
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ textAlign: "center", color: "#4459C6" }}
            id="alert-dialog-description"
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              updateConfirmed();
            }}
            color="primary"
          >
            <div style={{ color: "#4459C6" }}>Oui</div>
          </Button>
          <Button onClick={handleClose} color="primary">
            <div style={{ color: "#4459C6" }}>Non</div>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSupp}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ textAlign: "center", color: "red" }}
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ textAlign: "center", color: "#4459C6" }}
            id="alert-dialog-description"
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {<Button
            onClick={() => {
              removePrestataire();
            }}
            color="primary"
          >
            <div style={{ color: "#4459C6" }}>Oui</div>
          </Button>}
          <Button onClick={handleClose} color="primary">
            <div style={{ color: "#4459C6" }}>Non</div>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBan}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ textAlign: "center", color: "red" }}
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ textAlign: "center", color: "#4459C6" }}
            id="alert-dialog-description"
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {<Button
            onClick={() => {
              banPrestataire();
            }}
            color="primary"
          >
            <div style={{ color: "#4459C6" }}>Oui</div>
          </Button>}
          <Button onClick={handleClose} color="primary">
            <div style={{ color: "#4459C6" }}>Non</div>
          </Button>
        </DialogActions>
      </Dialog>

      <ProvidersTable
        data={providers}
        loading={isTableLoading}
        onValidateClick={onProviderValidateClick}
        onDeletionClick={onProviderDeleteClick}
        onBanClick={onProviderBanClick}
        openFile={openFile}
      />
    </Grid>
  );
};

export default Providers;
