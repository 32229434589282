import axios from 'axios';

async function getProviders() {
  const response = await axios.get(`/providers`);

  return response.data;
}

async function authorize() {
  const response = await axios.get(`/admin/authorize`);
  return response.data;
}

async function banProvider(providerId) {
  const response = await axios.delete(`/providers/` + providerId + `?disable=true`);

  return response.data;
}

async function deleteProvider(providerId) {
  const response = await axios.delete(`/providers/` + providerId);

  return response.data;
}

async function confirmProvider(providerId, data) {
  const response = await axios.put(`/providers/` + providerId + `/confirmed`, data);

  return response.data;
}

async function exportProviders(format, data) {
  let options = null;
  if (format === 'pdf')
    options = { responseType: 'blob' };
  const response = await axios.post(`/providers/export?format=` + format, data, options);

  return response.data;
};

const providerService = {
  getProviders,
  banProvider,
  deleteProvider,
  confirmProvider,
  authorize,
  exportProviders
}

export default providerService;
