import env from 'config/env';

const facebookSDKConfig = {
  appID: env.facebookAdminAppId,
}

function initFacebookSdk() {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: facebookSDKConfig.appID,
      cookie: true,
      status: true,
      xfbml: true,
      version: 'v13.0',
    });
  };

  // load facebook sdk script
  (function (d, s, id) {
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/fr_FR/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}

export { facebookSDKConfig, initFacebookSdk };
