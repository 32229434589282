import { Box, Container } from '@material-ui/core';
import { SidebarWidth } from 'themes/main';
import { withStyles } from '@material-ui/core/styles';
import SideBar from './SideBar';

const MainWrapper = withStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden',
    width: '100%',
  },
}))(Box);

const PageWrapper = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
}))(Box);

const PageContainer = withStyles(() => ({
  root: {
    paddingLeft: `${SidebarWidth}px !important`,
  },
}))(Container);

function Layout({ children }) {
  return (
    <MainWrapper>
      <SideBar />
      <PageWrapper>
        <PageContainer maxWidth='xl'>
          <Box style={{ padding: '16px' }}>
            {children}
          </Box>
        </PageContainer>
      </PageWrapper>
    </MainWrapper>
  )
}

export default Layout;
