import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import { usePostFunctions } from './contexts/PostContext';
import CustomSelect from 'components/CustomSelect';
import Posts from './components/Posts';
import PostsMonitoring from './components/PostsMonitoring';

const pages = [{ id: 1, displayName: 'Posts' }, { id: 2, displayName: 'Suivi Posts' }];

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(1),
    }
}));

function SocialNetworks() {

    const classes = useStyles();
    const { setPosts } = usePostFunctions();
    const [page, setPage] = useState(pages[0]);

    const onPageClick = (page) => {
        if (page.id === 2) setPosts([]);
        setPage(page);
    }

    const handleSelectPost = () => {
        setPage(pages[0]);
    }

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={3}>
            <Grid container item xs={12} justifyContent='center'>
                <Typography variant='h1'>Réseaux Sociaux</Typography>
            </Grid>

            <Grid container item xs={6} justifyContent='center'>
                <CustomSelect
                    itemList={pages}
                    justify='center'
                    labelField='displayName'
                    idField='id'
                    selectedItem={page}
                    onClick={onPageClick}
                />
            </Grid>

            <Grid className={classes.container} container item xs={12}>
                {page.id === 1 ? <Posts /> : <PostsMonitoring onSelectPost={handleSelectPost} />}
            </Grid>
        </Grid>
    );
};

export default SocialNetworks;