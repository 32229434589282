import { makeStyles } from '@material-ui/core';
import colors from 'themes/colors';

export default makeStyles((theme) => ({
  switchChecked: {},
  switchTrack: {
    backgroundColor:theme.palette.common.white,
    borderColor: theme.palette.blue.light,
    borderWidth: 1,
    borderStyle:'solid',
    borderRadius: 30,
  },
  switchBase: {
    '&.Mui-checked': {
      color: ({ color }) => color && color,
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: ({ color }) => color && color,
    },
  },
  switchThumbs: {
    alignItems: 'center',
    backgroundColor: ({ color }) => color ?? theme.palette.secondary.main,
    borderRadius: '30px',
    color: colors.main.MAIA_WHITE,
    display: 'flex',
    height: '20px',
    justifyContent: 'center',
    width: '20px',
  },
}));
