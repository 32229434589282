import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import Layout from 'pages/Layout';
import routes from "config/routes";

const PrivateRoute = ({ children, hasLayout, ...rest }) => {
  const { isAuthenticated, provider } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated && provider ?
          provider.is_travel_agency === false ? (!!hasLayout ? <Layout>{children}</Layout> : children) : <Redirect to={{ pathname: routes.bookings.root, state: { from: location } }} />
          :
          <Redirect to={{ pathname: routes.login.root, state: { from: location } }} />
      }
    />
  );
}

export default PrivateRoute;
