import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  switch: {
    marginLeft: 0,
    marginRight: 10,
  },
  switchContainer: {
    display:'flex',
    justifyContent:'flex-start',
  },
  imageContainer: {
    display: 'flex',
  },
  input: {
    display: 'none',
  },
}));
