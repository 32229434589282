import axios from 'axios';

async function loadAdmin() {
    const response = await axios.get(`/admin`);
    return response.data;
}

async function associateSocialToken(socialNetwork, data) {
    const response = await axios.put(`/admin/${socialNetwork}/associate`, data);
    return response.data;
}

async function revokeSocialToken(socialNetwork) {
    const response = await axios.put(`/admin/${socialNetwork}/revoke`);
    return response.data;
}

const adminService = {
    loadAdmin,
    associateSocialToken,
    revokeSocialToken
}

export default adminService;
