import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    maxWidth: '400px',
    minWidth: '350px',
    padding: theme.spacing(3),
  },
  header: {
    position: 'relative',
    textAlign: 'center',
  },
  logo: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
  },
  statusContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  picture: {
    borderRadius: '100%',
    marginRight: theme.spacing(1),
  },
  status: {
    backgroundColor: ({ status }) => status ? 'mediumseagreen' : 'red',
    borderRadius: '100%',
    height: '15px',
    marginRight: theme.spacing(1),
    width: '15px',
  },
  userName: {
    fontWeight: 700,
  },
}));
