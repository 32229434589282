import React, { useEffect, useMemo, useState } from 'react'
import { Autocomplete, outlinedInputClasses, TextField } from '@mui/material'
import { throttle } from 'lodash'
import { DestinationsApi } from 'services';

// eslint-disable-next-line no-unused-vars
// type SearchADECallbackType = (results?: readonly DestinationEntityType[]) => void

// type AutoCompleteSearchDestinationPropsType = {
//   value: DestinationEntityType | null
//   // eslint-disable-next-line no-unused-vars
//   setValue: (value: DestinationEntityType | null) => void
//   // eslint-disable-next-line no-unused-vars
//   setLabelValue: (value: string) => void
// }

const LocalisationAutoCompletePost = ({ value, setValue, setLabelValue }) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [open, setOpen] = useState(false)
  const [initialRender, setInitialRender] = useState(true)
  const fetch = useMemo(
    () =>
      throttle(async (value, callback) => {
        await DestinationsApi.getDestinationCallBack(value, callback)
      }, 500),
    [],
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch(inputValue, (result) => {
      if (active) {
        let newOptions = []

        if (value) newOptions = [value]
        if (result) newOptions = [...newOptions, ...result]
        setOptions(newOptions)
      }
    })
    return () => {
      active = false
    }
  }, [fetch, inputValue, value])

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Autocomplete
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option.label
      }}
      freeSolo
      isOptionEqualToValue={(option, value) => option.id === value.id}
      open={open}
      options={options}
      noOptionsText="Aucune option"
      value={value}
      onChange={(_, newValue) => {
        setInitialRender(false)
        setOptions(typeof newValue !== 'string' && newValue ? [newValue, ...options] : options)
        setValue(typeof newValue !== 'string' ? newValue : null)
      }}
      onClose={() => setOpen(false)}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
        setLabelValue(newInputValue)
        if (newInputValue.length > 0 && !initialRender) return setOpen(true)
        setOpen(false)
      }}
      onOpen={handleOpen}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Rechercher une ville, un lieu..."
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      sx={{ [`& .${outlinedInputClasses.root}`]: { pr: '16px !important' } }}
    />
  )
}

export default LocalisationAutoCompletePost;
