import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  logo: {
    width: '100%',
  },
  picker: {
    '& input': {
      fontSize: '0.75rem',
      padding: '8px',
      position: 'relative',
      '&::-webkit-calendar-picker-indicator': {
        background: 'transparent',
        bottom: 0,
        color: 'transparent',
        cursor: 'pointer',
        height: 'auto',
        left: -24,
        position: 'absolute',
        right: 8,
        top: 0,
        width: 'auto',
      },
    },
  },
}));
