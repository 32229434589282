import { Grid, makeStyles, Typography, IconButton, Menu, MenuItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PendingIcon from '@mui/icons-material/Pending';
import CloseIcon from '@mui/icons-material/Close';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import CustomSelect from 'components/CustomSelect';
import { months } from 'config/constants';
import { HelpRequestsApi } from 'services';
import useStyles from './styles';
import { mainTheme } from 'themes/main';
import LoadingBackdrop from 'components/LoadingBackdrop';

const pages = [{ id: 1, displayName: 'Activités' }, { id: 2, displayName: 'Evènements' }, { id: 3, displayName: 'Destinations' }];

function HelpRequests() {

    const classes = useStyles();
    const [page, setPage] = useState(pages[0]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [helpRequests, setHelpRequests] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const isEditStatusMenuOpened = Boolean(anchorEl);
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        fetchHelpRequests();
    }, []);

    async function fetchHelpRequests() {
        setFetching(true);
        await HelpRequestsApi.getHelpRequests().then((helpRequests) => {
            let data = [];
            if (helpRequests && helpRequests.data) {
                for (var i = 0; i < helpRequests.data.length; i++) {
                    data.push({
                        id: helpRequests.data[i].id,
                        firstName: helpRequests.data[i].first_name,
                        lastName: helpRequests.data[i].last_name,
                        subject: helpRequests.data[i].subject,
                        message: helpRequests.data[i].message,
                        state: helpRequests.data[i].state,
                        creationDate: new Date(helpRequests.data[i].creation_date),
                        providerEmail: helpRequests.data[i].provider.email
                    });
                }
            }
            setHelpRequests(data);
        }).finally(_ => {
            setFetching(false);
        });
    }

    const ticketStateColor = (state) => {
        switch (state) {
            case 'opened':
                return mainTheme.palette.primary.light;
            case 'reviewing':
                return '#FFA500';
            case 'closed':
                return mainTheme.palette.secondary.main;
        }
    }

    const onPageClick = (page) => {
        //        if (page.id === 2) setPosts([]);
        setPage(page);
    }

    async function handleCloseEditTicketStatus() {
        setAnchorEl(null);
        setSelectedTicket(null);
    }

    async function onEditTicket(event, ticket) {
        setAnchorEl(event.currentTarget);
        setSelectedTicket(ticket);
    }

    async function handleClosedStatus() {
        if (selectedTicket.state === 'closed') {
            handleCloseEditTicketStatus();
            return;
        }
        let formData = new FormData();
        formData.append('state', 'closed');
        await HelpRequestsApi.updateHelpRequest(selectedTicket.id, formData).finally(_ => {
            setFetching(false);
            handleCloseEditTicketStatus();
            fetchHelpRequests();
        });
    }

    async function handleReviewingStatus() {
        if (selectedTicket.state === 'reviewing') {
            handleCloseEditTicketStatus();
            return;
        }
        let formData = new FormData();
        formData.append('state', 'reviewing');
        await HelpRequestsApi.updateHelpRequest(selectedTicket.id, formData).finally(_ => {
            setFetching(false);
            handleCloseEditTicketStatus();
            fetchHelpRequests();
        });
    }

    async function handleOpenedStatus() {
        if (selectedTicket.state === 'opened') {
            handleCloseEditTicketStatus();
            return;
        }
        let formData = new FormData();
        formData.append('state', 'opened');
        await HelpRequestsApi.updateHelpRequest(selectedTicket.id, formData).finally(_ => {
            setFetching(false);
            handleCloseEditTicketStatus();
            fetchHelpRequests();
        });
    }

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={3}>
            <Grid container item xs={12} justifyContent='center'>

                <div id="headers">
                    <h1 style={{ fontWeight: "500" }}>Demandes d'assistance</h1>
                </div>
            </Grid>

            {/* <Grid container item xs={12} justifyContent='center'>
                <CustomSelect
                    itemList={pages}
                    justify='center'
                    labelField='displayName'
                    idField='id'
                    selectedItem={page}
                    onClick={onPageClick}
                />
            </Grid> */}

            {/* <Grid className={classes.container} container item xs={12}> */}
            {/* {page.id === 1 && <Activities />}
                {page.id === 2 && <Events />}
                {page.id === 3 && <Destinations />} */}
            {/* </Grid> */}

            <Grid container direction="column">
                {!isFetching &&
                    helpRequests.map((ticket) => {
                        return (
                            <Grid key={ticket.id} justifyContent="center" style={{ display: 'flex' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '1vw 1vw 0 0',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography className={classes.dateTitle}>
                                        {ticket.creationDate.getDate()}
                                    </Typography>
                                    <Typography className={classes.dateTitle}>
                                        {months[ticket.creationDate.getMonth()]}
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        flexGrow: 1,
                                        // height: '',
                                        border: '1px solid grey',
                                        color: '#828282',
                                        marginBottom: '3vh',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <div>
                                        <div
                                            className={classes.ticketContent}
                                            style={{ borderBottom: '1px solid grey', height: '6vh' }}
                                        >
                                            <span>N°{ticket.id} par {ticket.firstName} {ticket.lastName} ({ticket.providerEmail})</span>
                                            <span style={{ fontSize: '0.7vw' }}>
                                                {format(ticket.creationDate, 'dd/MM/yy')}
                                            </span>
                                        </div>
                                        <div className={classes.ticketContent}
                                            style={{ borderBottom: '1px solid grey ' }}>
                                            <span>{ticket.subject}</span>
                                            <span style={{ fontSize: '0.7vw', color: ticketStateColor(ticket.state) }}>{ticket.state}</span>
                                        </div>
                                        <div className={classes.ticketContent}>
                                            <span>{ticket.message}</span>
                                            <IconButton size="small" onClick={(event) => onEditTicket(event, ticket)}>
                                                <EditIcon color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}

                <Menu
                    open={isEditStatusMenuOpened}
                    anchorEl={anchorEl}
                    onClose={handleCloseEditTicketStatus}
                >
                    <MenuItem onClick={handleOpenedStatus} disableRipple>
                        <FiberNewIcon />
                        Ouvert
                    </MenuItem>
                    <MenuItem onClick={handleReviewingStatus} disableRipple>
                        <PendingIcon />
                        En cours de traitement
                    </MenuItem>
                    <MenuItem onClick={handleClosedStatus} disableRipple>
                        <CloseIcon />
                        Fermé
                    </MenuItem>
                </Menu>

                {isFetching && <LoadingBackdrop size={50} />}
            </Grid>
        </Grid>
    );
};

export default HelpRequests;