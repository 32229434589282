import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { usePost, usePostFunctions } from '../../contexts/PostContext';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CreateIcon from '@material-ui/icons/Create';
import FacebookLogo from '_assets/common/facebook-logo.svg';
import InstagramLogo from '_assets/common/instagram-logo-bold.svg';
import moment from 'moment';
import PostsTable from './components/PostsTable';
import ScheduleIcon from '@material-ui/icons/Schedule';
import UpdatePostModal from 'components/UpdatePostModal';

// const headCells = [
//   { id: 'mainImage', label: '', sortable: false },
//   { id: 'id', label: 'ID activité', sortable: true },
//   { id: 'name', label: 'Nom', sortable: true },
//   { id: 'label', label: 'Label', sortable: true },
//   { id: 'description', label: 'Description', sortable: true },
//   { id: 'destination', label: 'Destination', sortable: true },
//   { id: 'latitude', label: 'Latitude', sortable: true },
//   { id: 'longitude', label: 'Longitude', sortable: true },
//   { id: 'popularScore', label: 'Notation', sortable: true },
//   { id: 'creationDate', label: 'Date de création', sortable: true },
//   { id: 'edit', label: 'Editer', sortable: false }
// ];

const tableHead = [
  { id: 'mediaUrl', label: 'Photos', sortable: false },
  { id: 'text', label: 'Commentaires', sortable: false },
  { id: 'hashtag', label: 'Hashtags', sortable: false },
  { id: 'localisation_label', label: 'Localisation', sortable: false },
  { id: 'date', label: 'Timestamps', sortable: true },
  { id: 'edit', label: '', sortable: false },
  { id: 'delete', label: '', sortable: false }
  // { id: 6, label: 'Plateformes', sortable: false },
  // { id: 7, label: 'Plateforme Maïa', sortable: true }
];

const postRows = [
  {
    accessor: 'mediaUrl',
    width: 175,
    Cell: (data) => (
      <img alt='mignature' src={data} style={{ borderRadius: '5px', width: '100%' }} />
    ),
  },
  {
    accessor: 'description',
    width: 300,
  },
  {
    accessor: 'hashtag',
    width: 200,
  },
  {
    accessor: 'localisation_label',
    width: 250,
    // Cell: (data) => {
    //   if (data) {
    //     return <Typography variant='body2'>{`${data.postcode} - ${data.label}`}</Typography>
    //   }
    // }
  },
  {
    accessor: 'date',
    Cell: (data) => {
      return moment(data).format('DD/MM/YYYY HH:mm');
    },
  }
  // {
  //   accessor: 'admin_posts_socials',
  //   width: 175,
  //   Cell: (data) => {
  //     const facebookData = data.find(s => s.social_network === 'facebook');
  //     const instagramData = data.find(s => s.social_network === 'instagram');

  //     const socials = [
  //       {
  //         id: 'sn_t-' + instagramData.id,
  //         date: instagramData.published_date ?
  //           moment(instagramData.published_date).format('DD/MM/YYYY HH:mm') : null,
  //       },
  //       {
  //         id: 'sn_t-' + facebookData.id,
  //         date: facebookData.published_date ?
  //           moment(facebookData.published_date).format('DD/MM/YYYY HH:mm') : null,
  //       },
  //     ]

  //     return (
  //       <>
  //         {socials.map(sn => (
  //           <Typography key={sn.id} variant='body2'>{sn.date ?? '-'}</Typography>
  //         ))}
  //       </>
  //     )
  //   },
  // },
  // {
  //   accessor: 'admin_posts_socials',
  //   width: 100,
  //   Cell: (data) => {
  //     const facebookData = data.find(s => s.social_network === 'facebook');
  //     const instagramData = data.find(s => s.social_network === 'instagram');

  //     const socials = [
  //       {
  //         id: 'sn_s-' + instagramData.id,
  //         icon: InstagramLogo,
  //         state: instagramData.state,
  //       },
  //       {
  //         id: 'sn_s-' + facebookData.id,
  //         icon: FacebookLogo,
  //         state: facebookData.state,
  //       },
  //     ];

  //     return (
  //       <>
  //         {socials.map(sn => {
  //           let status;
  //           switch (sn.state) {
  //             case 'disable':
  //               status = { alt: 'désactivé', icon: <CancelOutlinedIcon style={{ color: 'red' }} /> };
  //               break;
  //             case 'pending':
  //               status = { alt: 'en attente', icon: <ScheduleIcon style={{ color: '#e1c933' }} /> };
  //               break;
  //             case 'published':
  //               status = { alt: 'publié', icon: <CheckCircleOutlineIcon style={{ color: '#23ce35' }} /> };
  //               break;
  //             case 'draft':
  //               status = { alt: 'brouillon', icon: <CreateIcon style={{ color: '#545864' }} /> };
  //               break;
  //             default:
  //               status = { alt: 'error', icon: <CancelOutlinedIcon style={{ color: 'red' }} /> };
  //               break;
  //           }

  //           return (
  //             <Box key={sn.id} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '4px' }}>
  //               <img alt='réseau social' src={sn?.icon} width={20} style={{ margin: '0 8px' }} />
  //               {status?.icon}
  //             </Box>
  //           )
  //         })}
  //       </>
  //     )
  //   }
  // },
  // {
  //   accessor: 'platform',
  //   width: 50,
  // },
];

function PostsMonitoring({ onSelectPost }) {
  const { posts } = usePost();
  const { loadPosts, setCreateMode, setCurrentPost, setCurrentPostDates, setPosts, deletePost } = usePostFunctions();

  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [postPage, setPostPage] = useState(0);
  const [postToUpdate, setPostToUpdate] = useState(null);

  const observer = useRef();
  const lastPostElement = useCallback(elem => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0]?.isIntersecting && hasMore) {
        setPostPage(prevPage => prevPage + 1);
      }
    });
    if (elem) observer.current.observe(elem);
  }, [hasMore, isLoading]);

  useEffect(() => {
    setPosts([]);
  }, [setPosts]);

  useEffect(() => {
    setIsLoading(true);
    loadPosts(postPage).then((result) => {
      setIsLoading(false);
      if (!result.error && result.empty) setHasMore(false);
      if (!result.error && !result.hasNext) setHasMore(false);
    });
  }, [loadPosts, postPage]);

  const handlePostClick = (post) => {
    setCurrentPost({ ...post, location: '' }); // TODO: manage location

    const ig = post.admin_posts_socials.find(ps => ps.social_network === 'instagram');
    const fb = post.admin_posts_socials.find(ps => ps.social_network === 'facebook');
    setCurrentPostDates({
      instagram: {
        format: moment(ig.planning_date).format('ddd DD MMM HH:mm'),
        value: moment(ig.planning_date),
      },
      facebook: {
        format: moment(fb.planning_date).format('ddd DD MMM HH:mm'),
        value: moment(fb.planning_date),
      },
      maia: {
        format: moment().format('ddd DD MMM HH:mm'),
        value: moment(),
      },
    });
    setCreateMode(false);

    onSelectPost(post);
  }

  const onDeletePost = async (post) => {
    const result = await deletePost({ id: post.id });
    if (result === true)
      await refreshPosts();
  }

  const onEditPost = async (post) => {
    setPostToUpdate(post);
  }

  const onSubmitEditChanges = async () => {
    await refreshPosts();
  }

  const refreshPosts = async () => {
    setIsLoading(true);
    loadPosts(postPage).then((result) => {
      setIsLoading(false);
    });
  }

  return (
    <>
      {postToUpdate && <UpdatePostModal post={postToUpdate} setUpdatePost={setPostToUpdate} onSubmitChanges={onSubmitEditChanges} />}
      <PostsTable
        data={posts}
        head={tableHead}
        lastPostRef={lastPostElement}
        loading={isLoading}
        onRowClick={handlePostClick}
        rows={postRows}
        onDeleteClick={onDeletePost}
        onEditClick={onEditPost}
      />
    </>
  )
}

export default PostsMonitoring;
