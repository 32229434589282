import axios from 'axios';

async function createPaymentSupplier(data) {
    const config = {
        maxContentLength: 100000000,
        maxBodyLength: 1000000000
    }; // TODO: check if needed
    const response = await axios.post(`/admin/payment/suppliers`, data, config);

    return response.data;
}

const paymentService = {
    createPaymentSupplier,
}

export default paymentService;
