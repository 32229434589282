import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import leftArrow from '_assets/register/arrow-left.svg';

export default function GoBack({ isSubmitting = false, onClick, ...props }) {
  const history = useHistory();

  const handleClick = () => {
    if (isSubmitting) return;
    history.goBack();
  }

  return (
    <IconButton
      onClick={onClick ?? handleClick}
      {...props}
    >
      <img src={leftArrow} alt='Flèche vers la gauche' width='45' height='45' />
    </IconButton>
  )
}
