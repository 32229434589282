import axios from 'axios';

async function createTgxSuppliers() {
    const response = await axios.post(`/admin/tgx/generate`);

    return response.data;
}

const tgxService = {
    createTgxSuppliers,
}

export default tgxService;
