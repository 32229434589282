import React, { useState } from "react";

import { Grid, Button, Dialog } from "@material-ui/core";
import { computeAge } from "utils";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ClientApi } from "services";
import ClientsTable from "./components/ClientsTable";


const Clients = () => {
    const [clients, setClients] = React.useState([]);
    const [isInit, setInit] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [id, setId] = React.useState(0);
    const [isTableLoading, setIsTableLoading] = useState(true);

    if (isInit === false) {
        setInit(true);
        getClientsData();
    }

    async function getClientsData() {
        setIsTableLoading(true);
        await ClientApi.getClients()
            .then(
                (clients_data) => {
                    setIsTableLoading(false);
                    var data = [];
                    if (clients_data.users) {
                        let clients = clients_data.users
                        for (var i = 0; i < clients.length; i++) {
                            var gender = "";
                            if (!clients[i].gender) gender = "Non défini";
                            else if (clients[i].gender === "female") gender = "F";
                            else if (clients[i].gender === "male") gender = "H";
                            data.push({
                                id: clients[i].id,
                                firstName: clients[i].firstname,
                                lastName: clients[i].lastname,
                                nickname: clients[i].nickname,
                                contactEmail: clients[i].contact_email,
                                email: clients[i].email,
                                phone: clients[i].phone,
                                age: computeAge(clients[i].birthday),
                                nationality: clients[i].nationality,
                                country: clients[i].residence_country,
                                gender: gender,
                            });
                        }
                    }
                    setClients(data);
                },
                (error) => {
                }
            );
    }

    const onClientDeleteClick = async (event, client) => {
        setOpen(true);
        setEmail(client.email);
        setId(client.id);
        await deleteClient();
    };

    const deleteClient = async () => {
        return await ClientApi.deleteClient(id)
            .then(
                (response) => {
                    let filteredArray = clients.filter(
                        (item) => item.id !== id && item.email !== email
                    );
                    setClients(filteredArray);
                    handleClose();
                },
                (error) => {
                }
            );
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid className="Clients">
            <div id="headers">
                <h1 style={{ fontWeight: "500" }}>Clients</h1>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    style={{ textAlign: "center", color: "red" }}
                    id="alert-dialog-title"
                >
                    {"Suppression d'un utilisateur !"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{ textAlign: "center", color: "#4459C6" }}
                        id="alert-dialog-description"
                    >
                        <div style={{ color: "#4459C6" }}>
                            Attention vous allez supprimer un <b>utilisateur</b>.<br></br>Les
                            données seront perdues, voulez vous continuer ?
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button onClick={deleteClient} color="primary">
                        <div style={{ color: "#4459C6" }}>Oui</div>
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        <div style={{ color: "#4459C6" }}>Non</div>
                    </Button>
                </DialogActions>
            </Dialog>
            <ClientsTable
                data={clients}
                loading={isTableLoading}
                onDeletionClick={onClientDeleteClick}
            />
        </Grid>
    );
};

export default Clients;
