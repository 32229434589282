import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import Layout from 'pages/Layout';
import routes from "config/routes";

const AgencyPrivateRoute = ({ children, hasLayout, ...rest}) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ?
          !!hasLayout ? <Layout>{children}</Layout> : children
          :
          <Redirect to={{ pathname: routes.login.root, state: { from: location } }} />
      }
    />
  );
}

export default AgencyPrivateRoute;
