import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { toastMessages } from 'config/constants';
import toast from 'react-hot-toast';
import useGenericContext from 'hooks/useGenericContext';
import useLocalStorage from 'hooks/useLocalStorage';
import * as FirebaseConfig from '../firebaseConfig';
import { AdminApi } from 'services';

const AuthContext = createContext(null);
const AuthFunctionsContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage('auth-token', null);
  const [user, setUser] = useLocalStorage('user', null);

  const [admin, setAdmin] = useLocalStorage('admin', null);
  const [provider, setProvider] = useLocalStorage('adminProvider', null);
  const [loading, setLoading] = useState(true);

  const logout = useCallback(async () => {
    await FirebaseConfig.signOut();
    setToken(null);
    setUser(null);
    localStorage.clear();
  }, [setToken, setUser]);

  useEffect(() => {
    localStorage.removeItem('userData');
    localStorage.removeItem('companyData');
    setLoading(false);
  }, []);

  useEffect(() => {
    onAuthStateChanged(FirebaseConfig.auth, async (u) => {
      if (u) {
        setUser(u);
        await u.getIdToken().then(t => {
          setToken(t);
        });
      } else {
        if (token && user) {
          await logout();
        }
      }
    });
  }, [logout, setToken, setUser, token, user]);

  const signUp = useCallback(async ({ email, firstName, lastName, password }) => {
    try {
       const { user } = await FirebaseConfig.createUser(email, password);
       await user.getIdToken(true)
         .then(token => {
           setToken(token);
           setUser(user);
         });
      //await FirebaseConfig.generateUserDocument(user, { firstName, lastName });
    } catch (error) {}
  }, [setToken, setUser]);

  const loginWithEmailAndPassword = useCallback(async (email, password, remember) => {
    let result = { connected: false, errorCode: null };

    try {
      const { code, user } = await FirebaseConfig.signIn(email, password, remember);
      if (user) {
        await user.getIdToken(true)
          .then(async token => {
            setToken(token);
            setUser(user);
            const { data } = await AdminApi.loadAdmin();
            setAdmin(data.admin);
            setProvider(data.provider);
            result.connected = true;
          });
      } else {
        result.errorCode = code;
      }
    } catch (e) {}

    return result;
  }, [setToken, setUser]);

  const updatePassword = useCallback(async password => {
    return await FirebaseConfig.updatePassword(password)
      .then(() => true)
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          toast.error(toastMessages.error.TOO_LONG_SESSION);
        }
        return false;
      });
  }, []);

  const loginWithGoogle = useCallback(async (remember) => {
    let result = { additionalUserInfo: null, connected: false, user: null };

    try {
      const { _tokenResponse: additionalUserInfo, user } = await FirebaseConfig.signInWithGoogle(remember);
      await user.getIdToken(true)
        .then(function (token) {
          setToken(token);
          setUser(user);
          result.additionalUserInfo = additionalUserInfo;
          result.connected = true;
          result.user = user;
        });
    } catch (error) {
      result.connected = false;
    }

    return result;
  }, [setToken, setUser]);

  const signInMethodsForEmail = useCallback(async (email) => {
    return await FirebaseConfig.signInMethodsForEmail(email);
  }, []);

  const sendResetPasswordEmail = useCallback(async (email, redirect) => {
    return await FirebaseConfig.sendResetPasswordEmail(email, redirect);
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated: token !== null,
      token,
      user,
      admin,
      provider
    }),
    [token, user, admin, provider]
  );

  const functions = useMemo(
    () => ({
      loginWithEmailAndPassword,
      loginWithGoogle,
      logout,
      sendResetPasswordEmail,
      signInMethodsForEmail,
      signUp,
      updateUserPassword: updatePassword,
    }),
    [
      loginWithEmailAndPassword,
      loginWithGoogle,
      logout,
      sendResetPasswordEmail,
      signInMethodsForEmail,
      signUp,
      updatePassword
    ]
  );

  return (
    <AuthContext.Provider value={value}>
      <AuthFunctionsContext.Provider value={functions}>
        {!loading && children}
      </AuthFunctionsContext.Provider>
    </AuthContext.Provider>
  )
}

/**
 * Return the items values.
 */
function useAuth() {
  return useGenericContext(
    AuthContext,
    'useAuth cannot be used outside of a AuthProvider component.',
  );
}

/**
 * Return the functions manipulating items.
 */
function useAuthFunctions() {
  return useGenericContext(
    AuthFunctionsContext,
    'useAuthFunctions cannot be used outside of a AuthProvider component',
  );
}

export { AuthProvider, useAuth, useAuthFunctions };
