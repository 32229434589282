import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import {
    Box,
    CardMedia,
    Chip,
    Grid,
    Typography,
    Switch,
    Button,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    Modal
} from '@mui/material'
import { DateTime } from 'luxon'
import { ExpandMore } from '@mui/icons-material'
import { mutate } from 'swr'
import { TextField } from '@mui/material'
import { useFormik } from 'formik'
import { popupMessages } from 'config/constants'

import * as Yup from 'yup'
import DefaultTextField from 'components/DefaultTextField';
import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon'
import useWidth from 'hooks/useWidth'
import { useSnackbar } from 'contexts/SnackbarContext';
import LocalisationAutoCompletePost from '../LocalisationAutoCompletePost'
import { useTheme } from '@mui/system'
import { trimHashtag } from '../../utils';
import { usePostFunctions } from 'pages/SocialNetworks/contexts/PostContext'

const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' }

const UpdatePostModal = ({ post, setUpdatePost, onSubmitChanges }) => {
    const tagRef = useRef({ value: '' })
    const width = useWidth()
    const { updatePost } = usePostFunctions();
    const theme = useTheme()
    const showSnackbar = useSnackbar()

    const matchesXS = width === 'xs'
    const matchesSM = width === 'sm'

    useEffect(() => {
        console.log('post : ' + JSON.stringify(post));
    }, [post]);

    const [stringHashtag, setStringHashtag] = useState('')
    const [destinationLabel, setDestinationLabel] = useState(
        post.localisation ? post.localisation.label : post.localisation_label,
    )

    const initialEditPostValue = {
        date: new Date(post.date).toLocaleDateString('fr-CA', dateOptions),
        time: DateTime.fromISO(post.date).toLocaleString({
            hour: 'numeric',
            minute: 'numeric',
        }),
        id: post.id,
        localisation: post.localisation ?? {
            id: '',
            code: '',
            creation_date: '',
            label: post.localisation_label,
            latitude: 0,
            longitude: 0,
            name: '',
            main_image: '',
            nb_rating: 0,
            rating: 0,
            popular_score: 0,
            subtitle: '',
        },
        localisation_label: destinationLabel,
        hashtags: post.hashtag ? post.hashtag.split(',') : [],
        description: post.text,
        instagram: post.instagram,
        facebook: post.facebook,
    }

    const { values, touched, errors, resetForm, setFieldTouched, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialEditPostValue,
        validationSchema: Yup.object({
            date: Yup.date().required('Veuillez rentrer une date de programmation'),
            time: Yup.string().required('Veuillez entrez une heure de programmation'),
            localisation: Yup.object().nullable(),
            hashtags: Yup.array(),
            description: Yup.string().max(1000, 'La description ne peut pas dépasser 1000 caractères'),
            instagram: Yup.boolean().required('Veuillez renseigner ce champ'),
            facebook: Yup.boolean().required('Veuillez renseigner ce champ'),
        }),
        onSubmit: async () => {
            return await changePost()
        },
    })

    const handleSelectChange = async (e) => {
        if (e) {
            await setFieldValue('localisation', e)
            await setDestinationLabel(e.label)
            await setFieldTouched('localisation', true)
        } else {
            await setFieldValue('localisation', null)
        }
    }

    const changePost = async () => {
        try {
            if (DateTime.fromFormat(values.date + values.time, 'yyyy-MM-dd:mm:hh').diffNow('hour').years < 0) return false
            const body = {
                id: values.id,
                date: new Date(values.date + 'T' + values.time).toISOString(),
                localisation_id:
                    values.localisation?.label === destinationLabel && values.localisation?.id.length > 0
                        ? values.localisation.id
                        : null,
                localisation_label: destinationLabel,
                hashtag: values.hashtags.join(','),
                instagram: values.instagram,
                facebook: values.facebook,
                text: values.description,
            }
            const UpdatePost = async () => {
                // @ts-ignore
                const formData = new FormData();
                formData.append('text', values.description);
                formData.append('hashtags', values.hashtags.join(','));
                formData.append('localisation_id', values.localisation.id);
                formData.append('localisation_label', values.localisation?.label);
                // TODO : all form data fields
                const { data } = await updatePost(post, formData);
                onSubmitChanges();
                return data;
            }

            const data = mutate(`/admin/posts`, UpdatePost)
            setUpdatePost(null)
            resetForm()
            return !!data
        } catch (e) {
            return e
        }
    }

    const handleDelete = (value) => {
        const newtags = values.hashtags.filter((val) => val !== value)
        setStringHashtag(newtags.join(','))
        setFieldValue('hashtags', newtags)
    }
    const handleChangeLabelDestination = (value) => {
        setDestinationLabel(value)
    }

    const handleOnSubmit = (e) => {
        if (tagRef.current && e.key === 'Enter') {
            const newHash = trimHashtag(tagRef.current.value)
            setFieldValue('hashtags', [...values.hashtags, newHash])
            setStringHashtag([...values.hashtags, newHash].join(','))
            tagRef.current.value = ''
        }
    }

    const handleHashtag = () => {
        if (tagRef.current.value !== '') {
            const newHash = trimHashtag(tagRef.current.value)
            setFieldValue('hashtags', [...values.hashtags, newHash])
            setStringHashtag([...values.hashtags, newHash].join(','))
            tagRef.current.value = ''
        }
    }

    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault()
        }
    }

    return (

        <Modal open={post !== null} onClose={() => setUpdatePost(null)}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                }}
            >
                <form onKeyDown={onKeyDown} onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            bgcolor: 'background.paper',
                            borderRadius: '4px',
                            p: 2.5,
                            width: matchesXS || matchesSM ? '88vw' : '736px',
                            display: 'flex',
                        }}
                    >
                        <Grid container direction="row" columnSpacing={1.5}>
                            <Grid item xs={12} md={4}>
                                <Grid container direction="column" rowSpacing={2} justifyContent="flex-start">
                                    <Grid item xs={6}>
                                        {post.mediaUrl && (
                                            <CardMedia
                                                component="img"
                                                alt={'image' + post.mediaUrl}
                                                height={168}
                                                image={post.mediaUrl}
                                                sx={{ minHeight: 168, borderRadius: 4, width: 168 }}
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sx={{ mb: 3 }}>
                                        <Grid container direction="row" rowSpacing={1} alignItems="center">
                                            <Grid item xs={4} md={12}>
                                                <Typography variant="paragraph_bold">Reposté sur</Typography>
                                            </Grid>
                                            <Grid item xs={4} md={12}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <InstagramIcon />
                                                    <Switch id="instagram" name="instagram" checked={values.instagram} onChange={handleChange} />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} md={12}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <FacebookIcon />
                                                    <Switch id="facebook" name="facebook" checked={values.facebook} onChange={handleChange} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container direction="row">
                                    {!post.is_publish && (
                                        <Grid item xs={12}>
                                            {!matchesXS && !matchesSM ? (
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item xs={3}>
                                                        <Typography>Programmé le</Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <DefaultTextField
                                                            fullWidth
                                                            name="date"
                                                            type="date"
                                                            value={values.date}
                                                            onChange={handleChange}
                                                            error={touched.date && Boolean(errors.date)}
                                                            helperText={touched.date && errors.date}
                                                            inputProps={{
                                                                min: new Date().toLocaleDateString('fr-FR', dateOptions),
                                                                max: DateTime.now().plus({ year: 100 }).toFormat('yyyy-mm-dd'),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Typography sx={{ ml: 1 }}>à</Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <DefaultTextField
                                                            fullWidth
                                                            name="time"
                                                            type="time"
                                                            value={values.time}
                                                            onChange={handleChange}
                                                            error={touched.time && Boolean(errors.time)}
                                                            helperText={touched.time && errors.time}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <Accordion
                                                    sx={{
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        m: matchesXS ? '8px 0' : '12px 0',
                                                        '&:first-of-type': { m: matchesXS ? '0 0 8px' : '0 0 12px' },
                                                        '&:before': { opacity: 0 },
                                                        '&.Mui-expanded': { m: matchesXS ? '8px 0' : '12px 0' },
                                                        '&.Mui-expanded:first-of-type': { m: matchesXS ? '0 0 8px' : '0 0 12px' },
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMore color="primary" />}
                                                        sx={{
                                                            p: matchesXS ? '0 12px' : '0 20px',
                                                            '& .MuiAccordionSummary-content': { m: matchesXS ? '12px 0' : '20px 0' },
                                                            '& .Mui-expanded': { mb: '4px !important' },
                                                            '&.Mui-expanded': { minHeight: 'unset' },
                                                        }}
                                                    >
                                                        <Typography variant="paragraph_big">Programmation</Typography>
                                                    </AccordionSummary>

                                                    <AccordionDetails sx={{ p: matchesXS ? '0 12px 8px' : '0 20px 8px' }}>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Grid item xs={12}>
                                                                <Typography>Programmé le</Typography>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <DefaultTextField
                                                                    fullWidth
                                                                    name="date"
                                                                    type="date"
                                                                    value={values.date}
                                                                    onChange={handleChange}
                                                                    error={touched.date && Boolean(errors.date)}
                                                                    helperText={touched.date && errors.date}
                                                                    inputProps={{
                                                                        min: new Date().toLocaleDateString('fr-FR', dateOptions),
                                                                        max: DateTime.now().plus({ year: 100 }).toFormat('yyyy-mm-dd'),
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Typography sx={{ ml: 1 }}>à</Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <DefaultTextField
                                                                    fullWidth
                                                                    name="time"
                                                                    type="time"
                                                                    value={values.time}
                                                                    onChange={handleChange}
                                                                    error={touched.time && Boolean(errors.time)}
                                                                    helperText={touched.time && errors.time}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </Grid>
                                    )}

                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography>Localisation</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <LocalisationAutoCompletePost
                                            setValue={handleSelectChange}
                                            value={values.localisation}
                                            setLabelValue={handleChangeLabelDestination}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography>Hashtags</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row">
                                            <Box
                                                sx={{
                                                    overflowX: 'auto',
                                                    minWidth: '100%',
                                                    borderColor: 'black',
                                                    borderStyle: 'thin',
                                                    maxWidth: `${values.hashtags.length * 50 + 80}px`,
                                                }}
                                            >
                                                <TextField
                                                    inputRef={tagRef}
                                                    variant="outlined"
                                                    size="small"
                                                    id="hashtag"
                                                    sx={{ minWidth: `${values.hashtags.length * 100 + 300}px` }}
                                                    disabled={stringHashtag.length > 255}
                                                    margin="none"
                                                    placeholder={values.hashtags.length < 5 ? 'Entrez vos hashtags' : ''}
                                                    InputProps={{
                                                        onBlur: handleHashtag,
                                                        onKeyDown: handleOnSubmit,
                                                        startAdornment: (
                                                            <Box sx={{ display: 'flex' }}>
                                                                {values.hashtags.map((data, index) => {
                                                                    return (
                                                                        <Box key={index}>
                                                                            {index === 0 ? (
                                                                                <Chip key={index} tabIndex={-1} label={'#' + data} />
                                                                            ) : (
                                                                                <Chip
                                                                                    key={index}
                                                                                    tabIndex={-1}
                                                                                    label={'#' + data}
                                                                                    onDelete={() => {
                                                                                        handleDelete(data)
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Box>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            {stringHashtag.length > 255 && (
                                                <Typography sx={{ color: theme.palette.error.main }}>
                                                    La liste des hashtags est trop grande
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography>Description du post</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="description"
                                            sx={{ minHeight: '141px', maxHeight: '200px', overflowY: 'auto' }}
                                            defaultValue={values.description}
                                            onChange={handleChange}
                                            multiline
                                            minRows={4}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Grid container direction="row" justifyContent={matchesXS ? 'center' : 'flex-end'} columnSpacing={2.5}>
                                        <Grid item xs={6} sm={'auto'}>
                                            <Button
                                                variant="outlinedGradientLarge"
                                                sx={{ mx: 'auto', mb: 3, display: 'flex' }}
                                                onClick={() => setUpdatePost(null)}
                                                fullWidth={matchesXS}
                                            >
                                                Annuler
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={'auto'}>
                                            <Button
                                                variant="containedGradientLarge"
                                                sx={{ mx: 'auto', mb: 3, display: 'flex' }}
                                                fullWidth={matchesXS}
                                                type="submit"
                                            >
                                                Enregistrer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>
        </Modal >
    )
}

export default UpdatePostModal;
