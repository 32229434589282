import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import useIconDimension from 'hooks/useIconDimension'

const CheckCircleIcon = ({ width, height, sx, ...rest }) => {
  const dimension = useIconDimension()

  return (
    <SvgIcon sx={{ width: width ?? dimension.width, height: height ?? dimension.height, ...sx }} {...rest}>
      <path
        d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12L11 14L15 10"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default CheckCircleIcon;
