import axios from 'axios';

async function getEventsBookings() {
    const response = await axios.get(`/admin/eventsBookings`);
    return response.data;
}

async function uploadDocument(eventBookingId, data) {
    const config = {
        maxContentLength: 100000000,
        maxBodyLength: 1000000000
    };
    const response = await axios.post(`/admin/eventsBookings/`+ eventBookingId + `/documents`, data, config);
    return response.data;
}

async function updateEventBooking(id, data) {
    const response = await axios.put(`/admin/eventsBookings/` + id.toString(), data);
    return response.data;
}

async function exportEventsBookings(format, data) {
    let options = null;
    if (format === 'pdf')
        options = { responseType: 'blob' };
    const response = await axios.post(`/admin/eventsBookings/export?format=` + format, data, options);
    return response.data;
}

const eventsBookingsService = {
    getEventsBookings,
    uploadDocument,
    updateEventBooking,
    exportEventsBookings
}

export default eventsBookingsService;
