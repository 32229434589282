import { createTheme, darken } from '@material-ui/core/styles';
import { frFR } from '@material-ui/core/locale';
import colors from './colors';
import typography from './typography';
import shadows from './shadows';

const SidebarWidth = 275;

const mainTheme = createTheme(
  {
    direction: 'ltr',
    palette: {
      common: {
        black: colors.main.MAIA_BLACK,
      },
      primary: {
        main: colors.main.MAIA_BLUE,
        contrastText: colors.main.MAIA_WHITE,
      },
      secondary: {
        main: colors.main.MAIA_GREEN,
        contrastText: colors.main.MAIA_WHITE,
      },
      tertiary: {
        main: colors.main.MAIA_RED,
        contrastText: colors.main.MAIA_WHITE,
      },
      background: {
        default: '#fff',
        leftMenu: '#48d5b533',
        leftMenuSelected: '#48d5b533',
        event: '#7585D9',
      },
      success: {
        main: '#39cb7f',
        contrastText: '#ffffff',
      },
      danger: {
        main: '#fc4b6c',
      },
      error: {
        main: '#fc4b6c',
      },
      warning: {
        main: '#fdd43f',
        contrastText: '#ffffff',
      },
      text: {
        primary: colors.main.MAIA_BLACK,
        danger: '#fc4b6c',
        title: colors.main.MAIA_BLUE,
      },
      blue: {
        lightest: colors.blue.MAIA_LIGHTEST_BLUE,
        lighter: colors.blue.MAIA_LIGHTER_BLUE,
        light: colors.blue.MAIA_LIGHT_BLUE,
      },
      chips: {
        green: colors.chips.GREEN_CHIP,
        orange: colors.chips.ORANGE_CHIP,
        red: colors.chips.RED_CHIP,
      },
    },
    shape: {
      borderRadius: 5,
    },

    overrides: {
      MuiDrawer: {
        paperAnchorDockedLeft: {
          borderRight: 'none',
        },
      },

      MuiContainer: {
        root: {
          paddingLeft: '15px !important',
          paddingRight: '15px !important',
          maxWidth: '1600px',
        },
      },

      MuiButton: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          minWidth: '150px',
        },
      },

      MuiListItem: {
        root: {
          borderRadius: '5px',
        },
      },

      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: colors.blue.MAIA_LIGHTEST_BLUE,
          },
        },
      },

      MuiCard: {
        root: {
          borderRadius: '10px',
          padding: '14px',
          margin: '15px',
        },
      },

      MuiListItemIcon: {
        root: {
          minWidth: '40px',
        },
      },

      MuiGridItem: {
        root: {
          paddingTop: '30px',
          paddingLeft: '30px !important',
        },
      },

      MuiInputBase: {
        root: {
          backgroundColor: colors.blue.MAIA_LIGHTEST_BLUE,
        },
      },

      MuiOutlinedInput: {
        root: {
          '&:hover': {
            borderRadius: 5,
            backgroundColor: darken(colors.blue.MAIA_LIGHTER_BLUE, 0.1),
          },
          '&.Mui-focused': {
            backgroundColor: colors.main.MAIA_WHITE,
          },
          '&.Mui-focused fieldset': {
            borderStyle: 'solid',
          },
        },
        notchedOutline: {
          borderStyle: 'none',
          '&.Mui-focused': {
            borderStyle: 'solid',
          },
        },
      },

      MuiTableCell: {
        root: {
          borderBottom: 0,
        },
      },
    },

    status: {
      danger: '#e53e3e',
    },
    typography,
    shadows,
  },
  frFR,
);

export { mainTheme, SidebarWidth };
