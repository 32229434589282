import { Switch } from '@material-ui/core';
import useStyles from './styles';

const SwitchThumb = ({ color, Icon }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.switchThumbs}>
      <Icon fontSize="small" />
    </div>
  );
};

const CustomSwitch = (props) => {
  const { classes, styles, ...other } = props;
  return (
    <Switch
      classes={{
        checked: styles.switchChecked,
        switchBase: styles.switchBase,
        track: styles.switchTrack,
        ...classes,
      }}
      {...other}
    />
  );
};

function DefaultSwitch(props) {
  const { checkedIcon, children, color, disabled, ...other } = props;
  const styles = useStyles(props);

  if (checkedIcon)
    return (
      <CustomSwitch
        checkedIcon={<SwitchThumb color={color} Icon={checkedIcon} />}
        icon={<SwitchThumb color={color} Icon={checkedIcon} />}
        disabled={disabled}
        styles={styles}
        {...other}
      />
    );
  return <CustomSwitch disabled={disabled} styles={styles} {...other} />;
}

export default DefaultSwitch;
