import React, { useState } from "react";
import * as FirebaseConfig from '../firebaseConfig';

import { useHistory } from "react-router-dom";
import { useAuthFunctions } from 'contexts/AuthContext';
import routes from 'config/routes';
import env from "config/env";
import axios from "axios";

import {
  makeStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import "./LoginPage.css";
import { getToken } from "../firebaseConfig";

import maiaLogo from "../_assets/common/maia-logo.svg";
import sideImage from "../_assets/login/login.svg";

import whiteArrow from "../_assets/login/arrow-right-white.svg";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { ProviderApi } from "services";

//for the responsive on tablets and phones
window.onload = async () => {
  let vh = window.innerHeight * 0.01;
  await document.documentElement.style.setProperty("--vh", `${vh}px`);
};

window.onresize = async () => {
  let vh = window.innerHeight * 0.01;
  await document.documentElement.style.setProperty("--vh", `${vh}px`);
};

const breakpoints = createBreakpoints({});
const txtResponsive = createTheme({
  breakpoints,
  overrides: {
    MuiTypography: {
      body1: {
        fontWeight: "550",
        color: "#4459C6",
        [breakpoints.down("xs")]: {
          fontSize: "45%",
        },
        [breakpoints.between("sm", "md")]: {
          fontSize: "70%",
        },
        [breakpoints.between("md", "lg")]: {
          fontSize: "100%",
        },
        [breakpoints.between("lg", "xl")]: {
          fontSize: "120%",
        },
        [breakpoints.up("xl")]: {
          fontSize: "150%",
        },
      },
      body2: {
        display: "flex",
        alignItems: "center",
        [breakpoints.down("xs")]: {
          fontSize: "100%",
        },
        [breakpoints.between("sm", "md")]: {
          fontSize: "150%",
        },
        [breakpoints.between("md", "lg")]: {
          fontSize: "250%",
        },
        [breakpoints.between("lg", "xl")]: {
          fontSize: "250%",
        },
        [breakpoints.up("xl")]: {
          fontSize: "250%",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        border: 0,
        background: "rgba(231, 235, 255, 0.7)",
        height: "70%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "15px 15px",
        fontWeight: "700",
        whiteSpace: "nowrap",
        width: "50%",
        padding: "10%",
        color: "rgba(68, 89, 198, 0.7)",
        [breakpoints.down("xs")]: {
          borderRadius: "7px 7px",
          width: "80%",
          fontSize: "50%",
        },
        [breakpoints.only("sm")]: {
          width: "80%",
          fontSize: "80%",
        },
        [breakpoints.only("md")]: {
          fontSize: "80%",
        },
        [breakpoints.up("lg")]: {
          fontSize: "100%",
        },
        [breakpoints.up("xl")]: {
          fontSize: "150%",
        },
      },
      endIcon: {
        width: "auto",
        height: "90%",
        marginLeft: "1vw",
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "rgba(231, 235, 255, 0.7)",
        },
      },
    },
  },
});

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const { loginWithEmailAndPassword, logout } = useAuthFunctions();


  const history = useHistory();

  const signInWithEmailAndPasswordHandler = async (event, email, password) => {
    event.preventDefault();
    try {
      const { connected, errorCode } = await loginWithEmailAndPassword(email, password, false /*remember*/);
      try {
        const res = await ProviderApi.authorize();
        if (res.authorize === true)
          history.push(routes.home.root);
        else
          setShowError(false);
        setTimeout(() => setShowError(true), 5000);
      } catch (e) {
      }
    } catch (error) {
      var errorMessage = "Impossible de se connecter";
      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "L'adresse mail n'est pas au bon format";
          break;
        case "auth/wrong-password":
          errorMessage = "Le mot de passe est incorrect pour cet utilisateur";
          break;
        default:
          break;
      }
      setErrorMessage(errorMessage);
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiPaper-root": {
        background: "#F2FAFF",
        justifyContent: "center",
      },
      width: "100%",
    },
    button: {
      width: "10vw",
    },
  }));

  const classes = useStyles();

  const hyperlinkStyle = { color: "#4459C6" };
  const welcomeMessage = (
    <p className="welcomeMsg">Connectez-vous à votre compte Maïa ! </p>
  );

  return (
    <MuiThemeProvider theme={txtResponsive}>
      <Grid container className="Login" direction="row" wrap="nowrap">
        <Grid
          container
          xs={4}
          className="sideBar"
          direction="column"
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid
            item
            className="companyLogo"
            justify="center"
            alignSelf="flex-start"
            alignItems="flex-start"
            spacing={1}
            style={{ height: "20%" }}
          >
            <img className="logoImg" src={maiaLogo} />
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ height: "auto", flexGrow: "2" }}
            wrap="nowrap"
          >
            <Grid
              container
              alignItems="center"
              alignSelf="flex-start"
              justify="center"
              xs={5}
            >
              <img
                src={sideImage}
                alt="Computer"
                className="computerImg"
                alignSelf="center"
              />
            </Grid>
            <Grid
              container
              direction="column"
              alignSelf="center"
              justify="space-evenly"
              wrap="nowrap"
              style={{ height: "auto", marginTop: "3em" }}
            >
              <div
                style={{
                  margin: "0 10%",
                  fontSize: "38px",
                }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  style={{ color: "#F38AB0", fontWeight: "normal" }}
                >
                  Welcome
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ color: "#F38AB0", fontWeight: "normal" }}
                >
                  to
                </Typography>
                <Typography variant="body1" align="center">
                  Maïa Admin
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          xs={8}
          style={{ height: "100%" }}
          direction="column"
          alignItems="center"
        >
          {showError && <Alert
            severity="error"
            style={{
              position: "absolute",
              width: "40vw",
              top: "10vh",
              left: "45vw",
            }}
          >
            Utilisateur non abilité
          </Alert>}

          {welcomeMessage}
          <form
            onSubmit={(event) => {
              signInWithEmailAndPasswordHandler(event, email, password);
            }}
            className="formLogin"
          >
            <Grid
              container
              direction="column"
              justify="space-evenly"
              style={{
                height: "30%",
                width: "100%",
                alignSelf: "flex-start",
                flexWrap: "nowrap",
              }}
            >
              <TextField
                className="emailInput"
                label="Adresse email"
                variant="outlined"
                name="userEmail"
                onChange={(event) => onChangeHandler(event)}
              />
              <TextField
                className="passwordInput"
                label="Mot de passe"
                variant="outlined"
                type="password"
                name="userPassword"
                onChange={(event) => onChangeHandler(event)}
              />
              {errorMessage && (
                <div
                  class="alert alert-danger"
                  role="alert"
                  style={{ marginTop: "10px" }}
                >
                  {errorMessage}
                </div>
              )}
            </Grid>
            <Grid
              container
              direction="column"
              className="buttonLogin"
              alignItems="flex-start"
              alignSelf="center"
              wrap="nowrap"
            >
              <button className="buttonConnect" type="submit" color="primary">
                <div style={{ width: "90%" }}>Se connecter</div>
                <img className="whiteArrow" src={whiteArrow} alt="arrow" />
              </button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export default LoginPage;
