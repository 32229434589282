import Api from 'services/api';

/**
 * Put here all that needs to be initialized
 */

function init() {
  Api.init();
}

export default init;