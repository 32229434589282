import { Dialog, DialogContent, CircularProgress, makeStyles, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DestinationsApi } from 'services';
import DestinationsTable from './components/DestinationsTable';
import EditDestinationForm from "./EditDestinationForm";
import CreateDestinationForm from "./CreateDestinationForm";

function Destinations() {
    const [destinations, setDestinations] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [isRefreshingDocument, setIsRefreshingDocument] = useState(false);
    const [editDestinationOpened, setEditDestinationOpened] = useState(false);
    const [createDestinationOpened, setCreateDestinationOpened] = useState(false);
    const [selectedDestination, setSelectedDestination] = useState(null);

    useEffect(() => {
        fetchDestinations();
    }, []);

    // Edit destination

    async function handleOpenEditDestination(event, destination) {
        setEditDestinationOpened(true);
        setSelectedDestination(destination);
    }

    async function closeEditDestination() {
        setEditDestinationOpened(false);
    }

    async function onSubmitEditDestination(event) {
        closeEditDestination(event);
        fetchDestinations();
    }

    // Create destination

    async function openCreateDestination() {
        setCreateDestinationOpened(true);
    }

    async function closeCreateDestination(event) {
        setCreateDestinationOpened(false);
    }

    async function onSubmitCreateDestination(event) {
        closeCreateDestination(event);
        fetchDestinations();
    }

    //

    async function fetchDestinations() {
        setIsTableLoading(true);
        DestinationsApi.getDestinations()
            .then(
                (destinations) => {
                    setIsTableLoading(false);
                    var data = [];
                    if (destinations && destinations.data) {
                        for (var i = 0; i < destinations.data.length; i++) {
                            data.push({
                                id: destinations.data[i].id,
                                name: destinations.data[i].name,
                                label: destinations.data[i].label,
                                subtitle: destinations.data[i].subtitle,
                                latitude: destinations.data[i].latitude,
                                longitude: destinations.data[i].longitude,
                                mainImage: destinations.data[i].main_image,
                                popularScore: destinations.data[i].popular_score,
                                creationDate: destinations.data[i].creation_date,
                                pictureURL: destinations.data[i].pictureURL
                            });
                        }
                    }
                    setDestinations(data);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsTableLoading(false);
                }
            );
    }

    return (
        <>
            <Dialog open={editDestinationOpened} onClose={closeEditDestination}>
                <DialogContent>
                    <EditDestinationForm destination={selectedDestination} onSubmit={onSubmitEditDestination} />
                </DialogContent>
            </Dialog>
            <Dialog open={createDestinationOpened} onClose={closeCreateDestination}>
                <DialogContent>
                    <CreateDestinationForm onSubmit={onSubmitCreateDestination} />
                </DialogContent>
            </Dialog>
            <Dialog open={isRefreshingDocument}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            <Button standard onClick={openCreateDestination}>
                Créer une destination
            </Button>
            <DestinationsTable
                data={destinations}
                loading={isTableLoading}
                onEditDestination={handleOpenEditDestination}
            />
        </>
    );
}

export default Destinations;