import { CircularProgress, TextField, Box, IconButton, Typography } from "@material-ui/core";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DefaultButton from 'components/DefaultButton';
import React, { useState, useEffect } from "react";
import { DestinationsApi } from 'services';
import { toastMessages } from 'config/constants';
import toast from 'react-hot-toast';
import useStyles from './styles';


function EditDestinationForm({ destination, onSubmit }) {
    const [destinationCode, setDestinationCode] = useState(destination?.code);
    const [destinationName, setDestinationName] = useState(destination?.name);
    const [destinationLabel, setDestinationLabel] = useState(destination?.label);
    const [destinationSubtitle, setDestinationSubtitle] = useState(destination?.subtitle);
    const [destinationPopularScore, setDestinationPopularScore] = useState(destination?.popularScore);

    const [picture, setPicture] = useState({ file: null, url: destination?.pictureURL ?? null });

    const [isSubmitting, setSubmitting] = useState(false);

    const classes = useStyles();

    useEffect(() => {
    }, []);

    async function handleChange(event) {
        switch (event.target.name) {
            case 'code':
                setDestinationCode(event.target.value);
                break;
            case 'name':
                setDestinationName(event.target.value);
                break;
            case 'label':
                setDestinationLabel(event.target.value);
                break;
            case 'subtitle':
                setDestinationSubtitle(event.target.value);
                break;
            case 'popularScore':
                setDestinationPopularScore(event.target.value);
                break;
        }
    }

    async function handleSubmit(event) {
        setSubmitting(true);
        event.preventDefault();
        let formData = new FormData();
        formData.append('code', destinationCode);
        formData.append('name', destinationName);
        formData.append('label', destinationLabel);
        formData.append('subtitle', destinationSubtitle);
        formData.append('popular_score', destinationPopularScore);
        if (picture && picture?.file)
            formData.append('file', picture?.file);

        await DestinationsApi.updateDestination(destination?.id, formData).then((_) => {
            setSubmitting(false);
            onSubmit();
        }).catch((error) => {
            setSubmitting(false);
            toast.error(toastMessages.error.EDIT_DESTINATION);
        });
    }

    const handlePictureChange = async (event) => {
        if (event.target.files != null && event.target.files.length > 0) {
            const file = new Blob([event.currentTarget.files[0]], { type: 'image/*' });
            const url = URL.createObjectURL(file);
            setPicture({ file: event.currentTarget.files[0], fileBlob: file, url: url });
            document.getElementById('destinationImage').src = url;
        }
    };

    const handlePictureClick = (event) => {
        event.target.value = '';
    };

    return (
        <>
            <div id="headers">
                <h1 style={{ fontWeight: "500" }}>Editer une destination</h1>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit} style={{ height: '100%', width: '100%' }}>

                <Box className={classes.imageContainer}>
                    <img
                        id="destinationImage"
                        src={destination?.pictureURL /*?? placeholder*/}
                        alt="photo de la destination"
                        width="122px"
                        height="122px"
                        style={{ borderRadius: '5px' }}
                    />

                    <Box>
                        <input
                            accept="image/*"
                            className={classes.input}
                            //   disabled={(!autoSavePicture && disabled) || isUpdatingPicture}
                            id={'destinationPictureInput'}
                            name="destinationPictureInput"
                            type="file"
                            onChange={handlePictureChange}
                            onClick={handlePictureClick}
                        />
                        <label htmlFor={'destinationPictureInput'}>
                            <IconButton
                                aria-label="upload"
                                color="secondary"
                                component="span"
                            // disabled={(!autoSavePicture && disabled) || isUpdatingPicture}
                            >
                                <ControlPointIcon style={{ height: '25px', width: '25px' }} fontSize="medium" />
                            </IconButton>
                        </label>
                        <Typography style={{ width: '80%', marginLeft: '10px' }}>
                            Vérifiez que l’image soit bien au format PNG ou JPG et qu’elle ne dépasse pas 5 Mo
                        </Typography>
                    </Box>
                </Box>

                <TextField
                    disabled
                    fullWidth
                    label="ID"
                    margin="dense"
                    name="id"
                    variant="outlined"
                    value={destination?.id}
                /*
                                    onChange={handleChange}
                error={touched.last_name && Boolean(errors.last_name)}
                helperText={touched.last_name && errors.last_name}
                */
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Code"
                    margin="dense"
                    name="code"
                    variant="outlined"
                    value={destinationCode}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Nom"
                    margin="dense"
                    name="name"
                    variant="outlined"
                    value={destinationName}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Label"
                    margin="dense"
                    name="label"
                    variant="outlined"
                    value={destinationLabel}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Sous-titre"
                    margin="dense"
                    name="subtitle"
                    variant="outlined"
                    value={destinationSubtitle}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    type="number"
                    label="Note"
                    margin="dense"
                    name="popularScore"
                    variant="outlined"
                    value={destinationPopularScore}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    disabled
                    label="Latitude"
                    margin="dense"
                    name="latitude"
                    variant="outlined"
                    value={destination?.latitude}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    disabled
                    label="Longitude"
                    margin="dense"
                    name="longitude"
                    variant="outlined"
                    value={destination?.longitude}
                    onChange={handleChange}
                >
                </TextField>

                <DefaultButton
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                >
                    {isSubmitting ? <CircularProgress size={20} /> : 'Valider'}
                </DefaultButton>

            </form>
        </>
    );
}

export default EditDestinationForm;