import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { convertBytesToKiloBytes } from 'utils';
import { AdminApi } from 'services';
import { find, get, map, isArray, isEmpty, isNil } from 'lodash';
import { toastMessages } from 'config/constants';
import toast from 'react-hot-toast';
import useGenericContext from 'hooks/useGenericContext';
import useLocalStorage from 'hooks/useLocalStorage';
import LoadingBackdrop from 'components/LoadingBackdrop';

const AdminContext = createContext(undefined);
const AdminFunctionsContext = createContext(undefined);

function AdminProvider({ children }) {
    const [admin, setAdmin] = useLocalStorage('admin', null);
    const [provider, setProvider] = useLocalStorage('adminProvider', null);
    const [adminLoading, setAdminLoading] = useState(true);


    const loadAdmin = useCallback(async () => {
        const { data } = await AdminApi.loadAdmin();
        setAdmin(data.admin);
        setProvider(data.provider);
        setAdminLoading(false);
    }, [admin?.id, setAdmin]);

    useEffect(() => {
        loadAdmin();
      }, [loadAdmin]);

    const value = useMemo(
        () => ({
            admin,
            provider
        }),
        [
            admin,
            provider
        ],
    );

    const functions = useMemo(
        () => ({
            loadAdmin,
            setAdmin,
        }),
        [
            loadAdmin,
            setAdmin,
        ],
    );

    // if (adminLoading) return <LoadingBackdrop size={100} />

    return (
        <AdminContext.Provider value={value}>
            <AdminFunctionsContext.Provider value={functions}>{children}</AdminFunctionsContext.Provider>
        </AdminContext.Provider>
    );
}

/**
 * Return the items values.
 * @return {{
 *  admin: Object,
 *  provider: Object,
 * }}
 */
function useAdmin() {
    return useGenericContext(AdminContext, 'useAdmin cannot be used outside of a AdminProvider component.');
}

// /**
//  * Return the functions manipulating items.
//  * @return {{ editCompany: Function }}
//  */
// function useAdminFunctions() {
//     return useGenericContext(
//         AdminFunctionsContext,
//         'useAdminFunctions cannot be used outside of a AdminProvider component',
//     );
// }

export { AdminProvider, useAdmin/*, useAdminFunctions*/ };
