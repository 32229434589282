import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import useStyles from './styles';
import { useEffect, useMemo, useState } from 'react';
import DataTable from 'components/DataTable';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
      cursor: 'pointer',
    },
  },
}))(TableRow);

/*
edit: {
        component: (
            <>
                <IconButton size="small" onClick={(event) => onEditActivity(event, activity)}>
                    <EditIcon color="primary" />
                </IconButton>
            </>
        )
    },
*/

const StyledTableCell = withStyles((theme) => ({
  root: {
    border: 'none',
    '&:first-child': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
  head: {
    color: theme.palette.text.title,
  },
}))(TableCell);

function PostsTable({ data, head, lastPostRef, loading, onRowClick = () => { }, rows, onDeleteClick, onEditClick }) {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    console.log('data : ' + JSON.stringify(data));
  }, [data]);

  const normalizedData = useMemo(() => Array.from(data).map((post, index) => ({
    mediaUrl: {
      component: post.mediaUrl ? <img src={post.mediaUrl} alt={"image du post "} /> : <span />,
    },
    text: post.text,
    hashtag: post.hashtag,
    localisation_label: post.localisation_label,
    date: post.date,
    edit: {
      component: (
        <>
          <IconButton size="small" onClick={(event) => onEditClick(post)}>
            <EditIcon color="primary" />
          </IconButton>
        </>
      )
    },
    delete: {
      component: (
        <>
        <IconButton size="small" onClick={(event) => onDeleteClick(post)}>
          <DeleteIcon color="primary" />
        </IconButton>
      </>
      )
    }
  })).filter(v => Object.values(v).map(v => String(v)).find(f => searchValue ? f.toLowerCase().includes(searchValue.toLowerCase()) : true)), [data, searchValue]);

  return <DataTable
    headCells={head}
    data={normalizedData}
    loading={loading}
  />

  // return (
  //   <TableContainer component={Box}>
  //     <Table className={classes.table}>
  //       <TableHead>
  //         <TableRow>
  //           {head.map(col =>
  //             <StyledTableCell key={col.id}>
  //               <Typography className={classes.titles}>{col.label}</Typography>
  //             </StyledTableCell>
  //           )}
  //         </TableRow>
  //       </TableHead>

  //       <TableBody>
  //         {data.map((post, postIndex) => {
  //           const row = rows.map((r, index) => {
  //             const d = post[r.accessor];

  //             return (
  //               <StyledTableCell key={post.id + '/' + index} style={{ minWidth: r.width ?? '', width: r.width ?? '' }}>
  //                 {!r.Cell ? <Typography variant='body2'>{d}</Typography> : r.Cell(d)}
  //               </StyledTableCell>
  //             )
  //           });

  //           if (data.length === postIndex + 1) return (
  //             <StyledTableRow key={`row-${postIndex}`} ref={lastPostRef} onClick={() => onRowClick(post)}>
  //               {row.map(r => r)}
  //             </StyledTableRow>
  //           )

  //           return (
  //             <StyledTableRow key={`row-${postIndex}`} onClick={() => onRowClick(post)}>
  //               {row.map(r => r)}
  //             </StyledTableRow>
  //           )
  //         })}

  //         {loading &&
  //           <TableRow>
  //             <StyledTableCell className={classes.rowCell} colSpan={head.length}>
  //               <CircularProgress size={50} />
  //             </StyledTableCell>
  //           </TableRow>
  //         }

  //         {data.length === 0 && !loading &&
  //           <StyledTableRow>
  //             <StyledTableCell className={classes.rowCell} colSpan={head.length}>
  //               <Typography>Aucun post n'a été créé pour le moment</Typography>
  //             </StyledTableCell>
  //           </StyledTableRow>
  //         }
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  // )
}

export default PostsTable;
