import { useContext } from 'react';

function useGenericContext(context, errorMessage) {
  const value = useContext(context);
  if (!value) {
    throw new Error(
      errorMessage || 'You are trying to use a context value outside of its provider',
    );
  }

  return value;
}

export default useGenericContext;
