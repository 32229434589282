import axios from 'axios';

async function getNotifications() {
    const response = await axios.get(`/admin/notifications`);

  return response.data;
}

async function markNotificationAsRead() {
  const response = await axios.put(`/admin/notifications/markAsRead`)

  return response.data;
}

const notificationsService = {
    getNotifications,
    markNotificationAsRead
}

export default notificationsService;