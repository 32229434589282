import React from "react";

// Import React Table

// import { addClient, editClient, deleteClient, getClients } from "./demo-data/manage-clients-data";

import "./Activities.css";

import { Grid } from "@material-ui/core";

const Analytics = () => {
  return (
    <Grid className="Clients">
      <div
        style={{ marginTop: "10vh", marginBottom: "5vh", marginLeft: "1em" }}
      >
        <h1>Analytics</h1>
        <h2>À venir</h2>
      </div>
    </Grid>
  );
};

export default Analytics;
