import DataTable from 'components/DataTable';
import { IconButton } from "@material-ui/core";
import { useState, useMemo } from 'react';
import EditIcon from '@material-ui/icons/Edit';

const headCells = [
    { id: 'mainImage', label: '', sortable: false },
    { id: 'id', label: 'ID activité', sortable: true },
    { id: 'name', label: 'Nom', sortable: true },
    { id: 'label', label: 'Label', sortable: true },
    { id: 'description', label: 'Description', sortable: true },
    { id: 'destination', label: 'Destination', sortable: true },
    { id: 'latitude', label: 'Latitude', sortable: true },
    { id: 'longitude', label: 'Longitude', sortable: true },
    { id: 'popularScore', label: 'Notation', sortable: true },
    { id: 'creationDate', label: 'Date de création', sortable: true },
    { id: 'edit', label: 'Editer', sortable: false }
];

function ActivitiesTable({ data, loading, onEditActivity }) {

    const [searchValue, setSearchValue] = useState('');

    const normalizedData = useMemo(() => Array.from(data).map((activity, index) => ({
        ...activity,
        edit: {
            component: (
                <>
                    <IconButton size="small" onClick={(event) => onEditActivity(event, activity)}>
                        <EditIcon color="primary" />
                    </IconButton>
                </>
            )
        },
        destination: activity.destination?.name ?? '',
        mainImage: {
            component: activity.pictureURL ? <img src={activity.pictureURL} alt={"image de l'activité " + activity.name} /> : <span />,
        },
        // id: activityBooking.id,
        // activityName: activityBooking.activityName,
    })).filter(v => Object.values(v).map(v => String(v)).find(f => searchValue ? f.toLowerCase().includes(searchValue.toLowerCase()) : true)), [data, searchValue]);

    const handleExport = async (selectedData, format, useSelected) => {
        // if (exporting) return;

        // setExporting(true);

        // let activitiesIds;
        // if (useSelected) {
        //     activitiesIds = selectedData.sort((a, b) => a - b);
        // } else {
        //     activitiesIds = data.map(b => b.id);
        // }

        // setExporting(false);

        // await ActivitiesApi.exportActivities(format, { ids: activitiesIds })
        //     .then((result) => {
        //         let blob;
        //         if (format === 'csv')
        //             blob = new Blob(['\ufeff' + result], { type: 'text/csv;' });
        //         else
        //            blob = new Blob([result], { type: 'application/pdf' });
        //         const filename = `Réservations activités Maia Partners.${format}`;
        //         if (navigator.msSaveBlob) { // IE 10+
        //             navigator.msSaveBlob(blob, filename);
        //         } else {
        //             let link = document.createElement('a');
        //             if (link.download !== undefined) {
        //                 link.setAttribute('href', URL.createObjectURL(blob));
        //                 link.setAttribute('download', filename);
        //                 link.style.visibility = 'hidden';
        //                 document.body.appendChild(link);
        //                 link.click();
        //                 document.body.removeChild(link);
        //             }
        //         }

        //         setExporting(false);
        //     })
        //     .catch(() => {
        //         toast.error(toastMessages.error.EXPORT_ACTIVITIES_BOOKINGS);
        //         setExporting(false);
        //     });
    }

    return <DataTable
        headCells={headCells}
        data={normalizedData}
        loading={loading}
    // ToolbarComponent={DataTableExportToolbar}
    // toolbarProps={{
    //     onSearchChange: (event) => setSearchValue(event.target.value),
    //     onExport: (data, format, useSelected) => handleExport(data, format, useSelected),
    //     nbSelectedItems: 0,
    // }}
    // isCollapsable={true}
    />;
}

export default ActivitiesTable;
