import axios from 'axios';

async function listPosts() {
  const response = await axios.get(`/admin/posts`);

  return response.data;
}

async function createPost(platform, data) {
  const response = await axios.post(`/admin/posts?platform=` + platform, data);

  return response.data;
}

async function deletePost(postId) {
  const response = await axios.delete(`/admin/posts/${postId}`);

  return response.data;
}

async function updatePost(postId, data) {
  const response = await axios.put(`/admin/posts/${postId}`, data);

  return response.data;
}

const postService = {
  listPosts,
  createPost,
  deletePost,
  updatePost
}

export default postService;
