import axios from 'axios';

async function getActivitiesBookings() {
    const response = await axios.get(`/admin/activitiesBookings`);
    return response.data;
}

async function uploadDocument(activityBookingId, data) {
    const config = {
        maxContentLength: 100000000,
        maxBodyLength: 1000000000
    };
    const response = await axios.post(`/admin/activitiesBookings/` + activityBookingId + `/documents`, data, config);
    return response.data;
}

async function exportActivitiesBookings(format, data) {
    let options = null;
    if (format === 'pdf')
        options = { responseType: 'blob' };
    const response = await axios.post(`/admin/activitiesBookings/export?format=` + format, data, options);
    return response.data;
}

async function updateActivityBooking(id, data) {
    const response = await axios.put(`/admin/activitiesBookings/` + id.toString(), data);
    return response.data;
}

const activitiesBookingsService = {
    getActivitiesBookings,
    uploadDocument,
    exportActivitiesBookings,
    updateActivityBooking
}

export default activitiesBookingsService;
