import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

const SwitchStatus = ({ status, textOn, textOff, ...other }) => {
  const classes = useStyles({ status });

  return (
    <Box className={classes.root} {...other}>
      <Box className={clsx(classes.status, classes.statusOn)}>
        <Typography className={classes.textOn}>{textOn}</Typography>
      </Box>
      <Box className={clsx(classes.status, classes.statusOff)}>
        <Typography className={classes.textOff}>{textOff}</Typography>
      </Box>
    </Box>
  )
}

export default SwitchStatus;
