import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 9999,
    //top: theme.spacing(6),
    backgroundColor: 'rgb(255,255,255,0.1)'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
}));

function LoadingBackdrop({ size }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <CircularProgress size={size}/>
      </div>
    </div>
  )
}

export default LoadingBackdrop;
