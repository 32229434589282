import { MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const DefaultMenuItem = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.blue.lightest,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.blue.lightest,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.blue.lightest,
      '&:hover': {
        backgroundColor: theme.palette.blue.lightest,
      },
    },
  },
}))(MenuItem);

export default DefaultMenuItem;
