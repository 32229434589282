const routes = {
    analytics: {
        root: '/analytics',
    },
    clients: {
        root: '/clients',
    },
    prestataires: {
        root: '/prestataires',
    },
    helpRequests: {
        root: '/help-requests'
    },
    parameters: {
        root: '/parameters',
        socialNetworks: {
            root: '/parameters/social-networks',
        },
    },
    home: {
        root: '/',
    },
    login: {
        root: '/login',
    },
    bookings: {
        root: '/trips',
    },
    socialNetworks: {
        root: '/social-networks'
    },
    contents: {
        root: '/contents'
    },
    payment: {
        root: '/payment'
    },
    tgx: {
        root: '/tgx'
    },
    root: '/',
};

export default routes;
