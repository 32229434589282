import { mainTheme } from 'themes/main';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from 'contexts/AuthContext';
import Pages from 'pages';

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <AuthProvider>
        <Toaster />
        <Router>
          <Pages />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}
export default App;
