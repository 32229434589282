import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import DefaultButton from 'components/DefaultButton';
import { toastMessages } from 'config/constants';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { PaymentApi } from 'services';
// import CustomSelect from 'components/CustomSelect';
// import Activities from './components/Activities';
// import Events from './components/Events';
// import Destinations from './components/Destinations';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(1),
    }
}));

function Payment() {

    const classes = useStyles();

    const [isSubmitting, setSubmitting] = useState(false);
    const [bankAccountsDocument, setBankAccountsDocument] = useState({ file: null, url: null });
    const [idCardDocument, setIdCardDocument] = useState({ file: null, url: null });

    const isSubmitButtonDisabled = bankAccountsDocument.file === null;

    const onBankAccountsDocumentChange = async (event) => {
        if (event.target.files != null && event.target.files.length > 0) {
            const file = new Blob([event.currentTarget.files[0]], { type: 'text/csv' }); // TODO: what about excel files ?
            const url = URL.createObjectURL(file);
            setBankAccountsDocument({ file: event.currentTarget.files[0], fileBlob: file, url: url });
        }
    };

    const onIdentityCardChange = async (event) => {
        if (event.target.files != null && event.target.files.length > 0) {
            const file = new Blob([event.currentTarget.files[0]], { type: 'image/*' }); // TODO
            const url = URL.createObjectURL(file);
            setIdCardDocument({ file: event.currentTarget.files[0], fileBlob: file, url: url });
        }
    };

    const onButtonClick = async () => {
        try {
            setSubmitting(true);
            let formData = new FormData();
            if (bankAccountsDocument && bankAccountsDocument?.file)
                formData.append('bankAccountsFile', bankAccountsDocument?.file);
            if (idCardDocument && idCardDocument?.file)
                formData.append('idCardFile', idCardDocument?.file);
            const result = await PaymentApi.createPaymentSupplier(formData);
        } catch (error) {
            console.log('error : ' + JSON.stringify(error));
            toast.error(error.toString());
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={6}>
                <Typography variant="h2">Fichier Excel comptes bancaires</Typography>
                <input
                    type="file"
                    id="bankAccountsInput"
                    // style={{ display: 'none' }}
                    multiple={true}
                    accept="text/csv" // TODO: excel ?
                    onChange={onBankAccountsDocumentChange}
                />
            </Grid>

            <Grid item xs={6}>
                <Typography variant="h2">Carte identité fournisseur</Typography>
                <input
                    type="file"
                    id="supplierIdCardInput"
                    // style={{ display: 'none' }}
                    multiple={true}
                    accept="image/*, video/*" // TODO
                    onChange={onIdentityCardChange}
                />
            </Grid>

            <DefaultButton
                color="secondary"
                disabled={isSubmitting || isSubmitButtonDisabled}
                type="submit"
                variant="contained"
                onClick={onButtonClick}
            >
                {isSubmitting ? <CircularProgress size={20} /> : 'Envoyer'}
            </DefaultButton>
        </Grid>
    );
};

export default Payment;