import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import HeaderParameters from '../components/HeaderParameters';
import { SocialNetworkCard } from './components';
import FacebookLogo from '_assets/common/facebook-logo.svg';
import InstagramLogo from '_assets/common/instagram-logo.svg';
import useStyles from './styles';

function SocialNetworks() {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={3} justifyContent='center'>
      <HeaderParameters text='Réseaux sociaux' />
      
      <Typography variant="h2" color="textSecondary" component="div">
        Maïa Travel
        </Typography>


      <Grid container item xs={12}>
        <Grid container item xs alignItems='center' justifyContent='center'>
          <SocialNetworkCard
            logo={InstagramLogo}
            name='instagram'
            title='Instagram'
            platform='travel'
          />
        </Grid>

        <Divider className={classes.columnSeparator} flexItem orientation='vertical' />

        <Grid container item xs alignItems='center' justifyContent='center'>
          <SocialNetworkCard
            logo={FacebookLogo}
            name='facebook'
            title='Facebook'
            platform='travel'
          />
        </Grid>
      </Grid>

      <Typography variant="h2" color="textSecondary" component="div">
        Maïa Partners
        </Typography>

      <Grid container item xs={12}>
        <Grid container item xs alignItems='center' justifyContent='center'>
          <SocialNetworkCard
            logo={InstagramLogo}
            name='instagram'
            title='Instagram'
            platform='partners'
          />
        </Grid>

        <Divider className={classes.columnSeparator} flexItem orientation='vertical' />

        <Grid container item xs alignItems='center' justifyContent='center'>
          <SocialNetworkCard
            logo={FacebookLogo}
            name='facebook'
            title='Facebook'
            platform='partners'
          />
        </Grid>
      </Grid>

    </Grid>
  );
}

export default SocialNetworks;
