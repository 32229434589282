import axios from 'axios';
import env from 'config/env';
import * as FirebaseConfig from '../../firebaseConfig';

function init() {
  setBaseUrl();

  // Maïa Travel requests interceptor
  axios.interceptors.request.use(async config => {
    if (config.url.charAt(0) === '/') {
      const token = await FirebaseConfig.getToken();
      config.headers = { ...config.headers, 'Authorization': `Bearer ${token}` };
    }
    return config;
  }, error => Promise.reject(error));

  axios.interceptors.response.use(null, async error => {
    if (error.response) {
      // Server responded with status code out of the 2xx range
      console.error('API responded with an error', error.response);
    } else if (error.request) {
      console.error('API call failed for request', error.request);
    } else {
      console.error('An error occurred while setting up an API request');
    }
    console.error(error.config);

    return Promise.reject(error);
  });
}

function setBaseUrl() {
  axios.defaults.baseURL = env.apiBaseUrl;
}

function setAuth(token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

function clearAuth() {
  delete axios.defaults.headers.common['Authorization'];
}

const apiService = {
  init,
  setAuth,
  clearAuth
}

export default apiService;
