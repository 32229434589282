import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    alignItems: 'center',
    borderRadius: '10px',
    display: 'flex',
    height: '54px',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0.5),
    width: '198px'
  },
  rightPart: {
    alignItems: 'center',
    display: 'flex',
  },
  status: {
    alignItems: 'center',
    borderRadius: '4px',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '40px',
    padding: theme.spacing(0.5),
    textAlign: 'center',
  },
  date: {
    marginTop: theme.spacing(1),
  },
}));
