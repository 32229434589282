import { Dialog, DialogContent, CircularProgress, makeStyles, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ActivitiesApi } from 'services';
import ActivitiesTable from './components/ActivitiesTable';
import EditActivityForm from "./EditActivityForm";
import CreateActivityForm from "./CreateActivityForm";


function Activities() {
    const [activities, setActivities] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [editActivityOpened, setEditActivityOpened] = useState(false);
    const [createActivityOpened, setCreateActivityOpened] = useState(false);

    useEffect(() => {
        fetchActivities();
    }, []);

    async function openEditActivity(event, activity) {
        setSelectedActivity(activity);
        setEditActivityOpened(true);
    }

    async function closeEditActivity(event) {
        setEditActivityOpened(false);
        setSelectedActivity(null);
    }

    async function onSubmit(event) {
        closeEditActivity(event);
        fetchActivities();
    }

    async function openCreateActivity(event) {
        setCreateActivityOpened(true);
    }

    async function closeCreateActivity(event) {
        setCreateActivityOpened(false);
    }

    async function onSubmitCreateActivity(event) {
        closeCreateActivity(event);
        fetchActivities();
    }

    async function fetchActivities() {
        setIsTableLoading(true);
        ActivitiesApi.getActivities()
            .then(
                (activities) => {
                    console.log('activities : ' + JSON.stringify(activities));
                    setIsTableLoading(false);
                    var data = [];
                    if (activities && activities.data) {
                        for (var i = 0; i < activities.data.length; i++) {
                            let destination = null;
                            if (activities.data[i].destination) {
                                destination = {
                                    id: activities.data[i].destination.id,
                                    name: activities.data[i].destination.name
                                };
                            }
                            data.push({
                                id: activities.data[i].id,
                                name: activities.data[i].name,
                                label: activities.data[i].label,
                                description: activities.data[i].description,
                                mainImage: activities.data[i].main_image,
                                latitude: activities.data[i].latitude,
                                longitude: activities.data[i].longitude,
                                popularScore: activities.data[i].popular_score,
                                creationDate: activities.data[i].creation_date,
                                pictureURL: activities.data[i].pictureURL,
                                destination: destination
                            });
                        }
                    }
                    setActivities(data);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsTableLoading(false);
                }
            );
    }

    return (
        <>
            <Dialog open={editActivityOpened} onClose={closeEditActivity}>
                <DialogContent>
                    <EditActivityForm activity={selectedActivity} onSubmit={onSubmit} />
                </DialogContent>
            </Dialog>
            <Dialog open={createActivityOpened} onClose={closeCreateActivity}>
                <DialogContent>
                    <CreateActivityForm onSubmit={onSubmitCreateActivity} />
                </DialogContent>
            </Dialog>
            <Button standard onClick={openCreateActivity}>
                Créer une activité
            </Button>
            <ActivitiesTable
                data={activities}
                loading={isTableLoading}
                onEditActivity={openEditActivity}
            />
        </>
    );
}

export default Activities;