import LoginPage from 'components/LoginPage';
import { useEffect, useState } from 'react';
import useWidth from 'hooks/useWidth';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'PrivateRoute';
import { Typography } from '@material-ui/core';
import DefaultModal from 'components/DefaultModal';
import { useAdmin } from "contexts/AdminContext";
import { SocialNetworksProvider } from "contexts/SocialNetworksContext";
import { AdminProvider } from "contexts/AdminContext";
import routes from "config/routes";
import SecuredArea from "./SecuredArea";

const SocialNetworkContext = ({ children }) => {

    const { admin } = useAdmin();


    return (
        <SocialNetworksProvider>
            {children}
        </SocialNetworksProvider>
    );
}


function Pages() {
    const width = useWidth();
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (!['lg', 'xl'].includes(width)) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
        }
    }, [width]);

    return (
        <>
            <Switch>
                <Route path={routes.login.root}>
                    <LoginPage />
                </Route>
                <AdminProvider>
                    <SocialNetworkContext>
                        <PrivateRoute path={routes.root}>
                            <SecuredArea />
                        </PrivateRoute>
                    </SocialNetworkContext>
                </AdminProvider>
            </Switch>
            <DefaultModal open={openDialog} disableScrollLock hideActions onCallback={() => { }} title="Mauvaise Résolution">
                <Typography>
                    La résolution actuel ne permet pas l'affichage de l'application. Veuillez changer l'affichage ou de
                    dispositif.
                </Typography>
            </DefaultModal>
        </>
    );
}

export default Pages;
