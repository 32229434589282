import { Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useSocialNetworks } from 'contexts/SocialNetworksContext';
import DefaultButton from 'components/DefaultButton';
import useStyles from './styles';

function SocialNetworkCard({ logo, name, title, platform }) {
  const { connexion, isLoggingOut, login, logout, user } = useSocialNetworks();
  const classes = useStyles({ status: connexion[name] });

  const handleClick = () => {
    if (connexion[name]) {
      logout();
    } else {
      login(name);
    }
  }

  return (
    <Card className={classes.root} variant='outlined'>
      <Grid container spacing={1}>
        <Grid className={classes.header} item xs={12}>
          <img className={classes.logo} alt='Réseau social' src={logo} />
          <Typography variant='h4'>{title}</Typography>
        </Grid>

        <Grid className={classes.statusContainer} container item xs={12} alignItems='center'>
          {connexion[name] ? (
            <>
              <img alt='Utilisateur' className={classes.picture} src={user.picture.url} />
              <Typography>Connecté en tant que <span className={classes.userName}>{user.name}</span></Typography>
            </>
          ) :
            <>
              <div className={classes.status} />
              <Typography>Déconnecté</Typography>
            </>
          }
        </Grid>

        <Grid container item xs={12} justifyContent='center'>
          <DefaultButton disabled={isLoggingOut} standard onClick={handleClick}>
            {isLoggingOut ? <CircularProgress size={20} /> : connexion[name] ? 'Se déconnecter' : 'Se connecter'}
          </DefaultButton>
        </Grid>
      </Grid>
    </Card>
  );
}

export default SocialNetworkCard;
