import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { toastMessages } from 'config/constants';
import { Typography } from '@material-ui/core';
import { useAdmin } from 'contexts/AdminContext';
import colors from 'themes/colors';
import DefaultModal from 'components/DefaultModal';
import LoadingBackdrop from 'components/LoadingBackdrop';
import toast from 'react-hot-toast';
import useGenericContext from 'hooks/useGenericContext';
import { AdminApi } from 'services';

const initialConnexion = {
  facebook: false,
  instagram: false
}

const initialStatus = {
  facebook: false,
  instagram: false
}

const initialUser = {
  id: null,
  name: null,
  picture: null,
}

const scopeValues = {
  INSTAGRAM_BASIC: 'instagram_basic',
  PAGES_MANAGE_POSTS: 'pages_manage_posts',
  PAGES_READ_ENGAGEMENT: 'pages_read_engagement',
  PAGES_SHOW_LIST: 'pages_show_list',
}

const permissions = {
  facebook: {
    array: [scopeValues.PAGES_MANAGE_POSTS, scopeValues.PAGES_READ_ENGAGEMENT],
    string: `${scopeValues.PAGES_MANAGE_POSTS},${scopeValues.PAGES_READ_ENGAGEMENT}`,
  },
  instagram: {
    array: [scopeValues.INSTAGRAM_BASIC, scopeValues.PAGES_SHOW_LIST],
    string: `${scopeValues.INSTAGRAM_BASIC},${scopeValues.PAGES_SHOW_LIST}`,
  }
}

const SocialNetworksContext = createContext(undefined);

const checkPermissions = (scopes) => ({
  facebook: !permissions.facebook.array.some(p => !scopes.find(s => s.permission === p && s.status === 'granted')),
  instagram: !permissions.instagram.array.some(p => !scopes.find(s => s.permission === p && s.status === 'granted')),
});

const getPermissions = () => {
  return new Promise((resolve) => {
    window.FB.api('/me/permissions', (response) => {
      resolve(response);
    });
  });
}

const getUserInfo = () => {
  return new Promise((resolve) => {
    window.FB.api('/me?fields=id,name,picture', (response) => {
      resolve(response);
    })
  });
}

function SocialNetworksProvider({ children }) {
  const { admin } = useAdmin();

  const [connexion, setConnexion] = useState(initialConnexion);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [socialNetworksLoading, setSocialNetworksLoading] = useState(false);
  const [status, setStatus] = useState(initialStatus);
  const [user, setUser] = useState(initialUser);

  const [openModal, setOpenModal] = useState(false);

  const statusChanged = useCallback(async (response) => {
    const { authResponse, status } = response;

    // If user connected
    if (admin.facebook_status === 'connected' && status === 'connected' && authResponse?.accessToken) {
      // Set user info
      const userInfo = await getUserInfo();
      setUser({ ...userInfo, picture: userInfo.picture.data });

      // Get permissions
      const { data } = await getPermissions();

      // Checking the permissions
      const { facebook, instagram } = checkPermissions(data);

      if (facebook) {
        setStatus((prev) => ({ ...prev, facebook }));
        setConnexion((prev) => ({ ...prev, facebook }));
      }

      if (instagram) {
        setStatus((prev) => ({ ...prev, instagram }));
        setConnexion((prev) => ({ ...prev, instagram }));
      }
    }

    setSocialNetworksLoading(false);
  }, [admin]);

  const load = useCallback(async () => {
    if (!admin?.id) return;

    try {
      // Checking connexion status
      await window.FB.getLoginStatus(statusChanged);
    } catch (e) {
      toast.error(toastMessages.error.FACEBOOK_LOAD);
    }

  }, [admin?.id, statusChanged]);

  const login = useCallback((name) => {
    return new Promise((resolve) => {
      window.FB.login((response) => {
        const { authResponse, status } = response;

        if (status === 'connected' && authResponse) {
          setTimeout(async () => {
            try {
              // Set user info
              const userInfo = await getUserInfo();
              setUser({ ...userInfo, picture: userInfo.picture.data });

              // Get permissions
              const { data } = await getPermissions();

              // Checking the permissions
              const perms = checkPermissions(data);

              setConnexion((prev) => ({ ...prev, [name]: perms[name] }));
              setStatus((prev) => ({ ...prev, [name]: perms[name] }));

              if (name === 'facebook' && perms[name]) {
                const body = { token: authResponse.accessToken, user_id: userInfo.id }
                await AdminApi.associateSocialToken('facebook', body);
              }

              // reset toggle if user did not set correct permissions
              if (!perms[name]) {
                setOpenModal(true);

                setStatus((prev) => ({ ...prev, [name]: true }));
                setTimeout(() => {
                  setStatus((prev) => ({ ...prev, [name]: false }));
                }, 100);
              }
              resolve();
            } catch (_) {
              toast.error(toastMessages.error.FACEBOOK_LOGIN);
              setStatus((prev) => ({ ...prev, [name]: true }));
              setTimeout(() => {
                setStatus((prev) => ({ ...prev, [name]: false }));
              }, 100);
              resolve();
            }
          });
        } else {
          setStatus((prev) => ({ ...prev, [name]: true }));
          setTimeout(() => {
            setStatus((prev) => ({ ...prev, [name]: false }));
          }, 100);
          resolve();
        }
      }, {
        scope: permissions[name].string,
        return_scopes: true,
        enable_profile_selector: true,
      });
    });
  }, [admin?.id]);

  const changeStatus = useCallback(async (name, value) => {
    const currentValue = status[name];

    if (!currentValue) {
      if (!admin?.id) {
        setStatus((prev) => ({ ...prev, [name]: false }));
        setConnexion((prev) => ({ ...prev, [name]: false }));
        return;
      }

      if (!connexion[name]) await login(name);
      else setStatus((prev) => ({ ...prev, [name]: value }));
    } else {
      setStatus((prev) => ({ ...prev, [name]: value }));
    }
  }, [connexion, admin?.id, login, status]);

  const logout = useCallback(() => {
    return new Promise((resolve) => {
      if (!connexion.instagram && !connexion.facebook) resolve();

      // Annulation des permissions, puis déconnexion
      setIsLoggingOut(true);
      window.FB.api('/me/permissions', 'delete', null, () => {
        window.FB.logout(() => {
          setTimeout(async () => {
            if (connexion.facebook || connexion.instagram) await AdminApi.revokeSocialToken('facebook');

            setStatus(initialStatus);
            setConnexion(initialConnexion);
            setUser(initialUser);
            setIsLoggingOut(false);

            resolve();
          });
        });
      });
    });
  }, [connexion.instagram, connexion.facebook, admin?.id]);

  useEffect(() => {
    setSocialNetworksLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(load);
  }, [load]);

  const value = useMemo(
    () => ({
      changeStatus,
      connexion,
      isLoggingOut,
      login,
      logout,
      socialNetworksLoading,
      status,
      user,
    }),
    [
      changeStatus,
      connexion,
      isLoggingOut,
      login,
      logout,
      socialNetworksLoading,
      status,
      user
    ]
  );

  return (
    <SocialNetworksContext.Provider value={value}>
      {socialNetworksLoading ? <LoadingBackdrop size={100} /> : children}

      <DefaultModal
        forceConfirm
        info
        onClose={() => setOpenModal(false)}
        open={openModal}
        title='Avertissement'
      >
        <Typography
          style={{
            color: colors.main.MAIA_RED,
            fontSize: '18px',
            fontStyle: 'italic',
            fontWeight: 300,
            textAlign: 'center',
          }}
        >
          Certaines fonctionnalités nécessitent des permissions particulières,
          vous devez les accepter afin d'utiliser pleinement ce service.
        </Typography>
      </DefaultModal>
    </SocialNetworksContext.Provider>
  )
}

/**
 * Return the items values.
 * @return {{
 *
 * }}
 */
function useSocialNetworks() {
  return useGenericContext(
    SocialNetworksContext,
    'useSocialNetworks cannot be used outside of a SocialNetworksProvider component.',
  );
}

export { SocialNetworksProvider, useSocialNetworks };
