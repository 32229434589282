import { createContext, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, AlertColor, Snackbar } from '@mui/material'
import useGenericContext from 'hooks/useGenericContext'


const initialState = {
  message: undefined,
  open: false,
}

const SnackbarContext = createContext({})

function SnackbarProvider({ children }) {
  const [pack, setPack] = useState([])
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (pack.length && !state.message) {
      setPack((prev) => prev.slice(1))
      setState({ message: { ...pack[0] }, open: true })
    } else if (pack.length && state.message && state.open) {
      setState((prev) => ({ ...prev, open: false }))
    }
  }, [pack, state.message, state.open])

  const showSnackbar = useCallback((severity, message) => {
    setPack((prev) => [...prev, { key: new Date().getTime(), text: message, severity }])
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setState((prev) => ({ ...prev, open: false }))
  }

  const handleExited = () => {
    setState((prev) => ({ ...prev, message: undefined }))
  }

  const value = useMemo(() => showSnackbar, [showSnackbar])

  return (
    <SnackbarContext.Provider value={value}>
      <Snackbar
        key={state.message?.key}
        autoHideDuration={5000}
        open={state.open}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        <Alert onClose={handleClose} severity={state.message?.severity}>
          {state.message?.text}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  )
}

function useSnackbar() {
  return useGenericContext(SnackbarContext, 'useSnackbar cannot be used outside of a SnackbarProvider component')
}

export { SnackbarProvider, useSnackbar }
