import { makeStyles } from '@material-ui/core';
import DefaultButton from 'components/DefaultButton';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zIndex: 100,
  },
  selected: {
    fontWeight: 500,
  },
  notSelected: {
    borderColor: theme.palette.blue.light,
    color: theme.palette.primary.main,
    fontWeight: 300,
  },
}));

const CustomSelect = ({ disabled, idField, itemList, labelField, onClick = () => {}, selectedItem }) => {
  const classes = useStyles();

  return (
    <>
      {itemList.map((item, index) => (
        <DefaultButton
          key={`btn-${index}`}
          className={clsx(
            classes.button,
            selectedItem && selectedItem[idField] === item[idField] ? classes.selected : classes.notSelected,
          )}
          color={selectedItem && selectedItem[idField] === item[idField] ? 'primary' : 'default'}
          disabled={disabled}
          standard={selectedItem && selectedItem[idField] === item[idField]}
          variant={selectedItem && selectedItem[idField] === item[idField] ? 'contained' : 'outlined'}
          onClick={() => onClick(item)}
        >
          {item[labelField]}
        </DefaultButton>
      ))}
    </>
  );
};

export default CustomSelect;
