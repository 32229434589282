import useWidth from 'hooks/useWidth'

const useIconDimension = () => {
  const width = useWidth()

  if (width === 'xs') return { width: 16, height: 16 }
  if (['sm', 'md'].includes(width)) return { width: 20, height: 20 }
  return { width: 24, height: 24 }
}

export default useIconDimension