import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper' : {
      minWidth: '500px',
    },
  },
  content: {
    display: 'flex',
    flexDirection:'column',
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
}));
