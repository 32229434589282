import { makeStyles } from '@material-ui/core';
import colors from 'themes/colors';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  status: {
    alignItems: 'center',
    borderRadius: '4px',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '40px',
    padding: theme.spacing(0.5),
    textAlign: 'center',
  },
  statusOn: {
    backgroundColor: ({ status }) => status && colors.fields.BUTTON_BACKGROUND,
  },
  statusOff: {
    backgroundColor: ({ status }) => !status && colors.fields.BUTTON_BACKGROUND,
  },
  textOn: {
    color: ({ status }) => status && theme.palette.text.title,
    fontWeight: ({ status }) => status && 'bold',
  },
  textOff: {
    color: ({ status }) => !status && theme.palette.text.title,
    fontWeight: ({ status }) => !status && 'bold',
  },
}));
