import { Dialog, DialogContent, CircularProgress, makeStyles, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { convertBytesToKiloBytes } from 'utils';
import { EventsApi } from 'services';
import toast from 'react-hot-toast';
import EventsTable from './components/EventsTable';
import EditEventForm from "./EditEventForm";
import CreateEventForm from "./CreateEventForm";


function Events() {
    const [events, setEvents] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [editEventOpened, setEditEventOpened] = useState(false);
    const [createEventOpened, setCreateEventOpened] = useState(false);

    useEffect(() => {
        fetchEvents();
    }, []);

    async function fetchEvents() {
        setIsTableLoading(true);
        EventsApi.getEvents()
            .then(
                (events) => {
                    setIsTableLoading(false);
                    var data = [];
                    if (events && events.data) {
                        for (var i = 0; i < events.data.length; i++) {
                            let destination = null;
                            if (events.data[i].destination) {
                                destination = {
                                    id: events.data[i].destination.id,
                                    name: events.data[i].destination.name
                                }
                            }
                            data.push({
                                id: events.data[i].id,
                                name: events.data[i].name,
                                label: events.data[i].label,
                                description: events.data[i].description,
                                mainImage: events.data[i].main_image,
                                startDate: events.data[i].start_date,
                                endDate: events.data[i].end_date,
                                summary: events.data[i].summary,
                                access: events.data[i].access,
                                tips: events.data[i].tips,
                                latitude: events.data[i].latitude,
                                longitude: events.data[i].longitude,
                                popularScore: events.data[i].popular_score,
                                creationDate: events.data[i].creation_date,
                                pictureURL: events.data[i].pictureURL,
                                destination: destination
                            });
                        }
                    }
                    setEvents(data);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsTableLoading(false);
                }
            );
    }

    // Edit event

    async function openEditEvent(event, eventObject) {
        setSelectedEvent(eventObject);
        setEditEventOpened(true);
    }

    async function closeEditEvent(event) {
        setEditEventOpened(false);
        setSelectedEvent(null);
    }

    async function onSubmit(event) {
        closeEditEvent(event);
        fetchEvents();
    }

    // Create event

    async function openCreateEvent(event) {
        setCreateEventOpened(true);
    }

    async function closeCreateEvent(event) {
        setCreateEventOpened(false);
    }

    async function onSubmitCreateEvent(event) {
        closeCreateEvent(event);
        fetchEvents();
    }

    return (
        <>
            <Dialog open={editEventOpened} onClose={closeEditEvent}>
                <DialogContent>
                    <EditEventForm eventObject={selectedEvent} onSubmit={onSubmit} />
                </DialogContent>
            </Dialog>
            <Dialog open={createEventOpened} onClose={closeCreateEvent}>
                <DialogContent>
                    <CreateEventForm onSubmit={onSubmitCreateEvent} />
                </DialogContent>
            </Dialog>
            <Button standard onClick={openCreateEvent}>
                Créer un évènement
            </Button>
            <EventsTable
                data={events}
                loading={isTableLoading}
                onEditEvent={openEditEvent}
            />
        </>
    );
}

export default Events;