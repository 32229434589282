function readEnv(name, required = false, fallback =  null) {
    if (process.env[name]) return process.env[name];
    if (required) throw new Error(`Environment variable "${name}" is required.`);
  
    return fallback;
  }
  
  const env = {
    apiBaseUrl: readEnv('REACT_APP_SERVER_URL', true),
    firebaseAPIKey: readEnv('REACT_APP_FIREBASE_API_KEY', true),
    firebaseAuthDomain: readEnv('REACT_APP_FIREBASE_AUTH_DOMAIN', true),
    firebaseProjectId: readEnv('REACT_APP_FIREBASE_PROJECT_ID', true),
    firebaseStorageBucket: readEnv('REACT_APP_FIREBASE_STORAGE_BUCKET', true),
    firebaseMessagingSenderId: readEnv('REACT_APP_FIREBASE_MESSAGING_SENDER_ID', true),
    firebaseAppId: readEnv('REACT_APP_FIREBASE_APP_ID', true),
    firebaseMeasurementId: readEnv('REACT_APP_FIREBASE_MEASUREMENT_ID', true),
    facebookAdminAppId: readEnv('REACT_APP_FACEBOOK_ADMIN_APP_ID', true)
  };
  
  export default env;
  