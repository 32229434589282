// Main colors
const MAIA_RED = '#F38AB0';
const MAIA_BLUE = '#4459C6';
const MAIA_GREEN = '#48D5B5';
const MAIA_WHITE = '#FFFFFF';
const MAIA_BLACK = '#6B6B6B';

// Blue shades
const MAIA_LIGHTEST_BLUE = '#EEF1FF';
const MAIA_LIGHTER_BLUE = '#E7EBFF';
const MAIA_LIGHT_BLUE = '#DADEF4';

// Main colors with less opacity
const MAIA_RED_TRANSPARENT = 'rgba(243,138,176,0.29)';
const MAIA_BLUE_TRANSPARENT = 'rgba( 68, 89,198,0.29)';
const MAIA_GREEN_TRANSPARENT = 'rgba( 71,213,181,0.29)';
const MAIA_BLACK_TRANSPARENT = 'rgba(  0,  0,  0,0.39)';

const BUTTON_BACKGROUND = '#E7EBFF';
const FIELD_BACKGROUND = 'rgba(231, 235, 255, 0.7)';
const TABLE_ROW_BACKGROUND = 'rgba(231, 235, 255, 0.3)';

// Chips colors
const GREEN_CHIP = '#34C83C';
const ORANGE_CHIP = '#Fb6D35';
const RED_CHIP = '#F42762';

const colors = {
  blue: {
    MAIA_LIGHTER_BLUE,
    MAIA_LIGHTEST_BLUE,
    MAIA_LIGHT_BLUE,
  },
  chips: {
    GREEN_CHIP,
    ORANGE_CHIP,
    RED_CHIP,
  },
  fields: {
    BUTTON_BACKGROUND,
    FIELD_BACKGROUND,
    TABLE_ROW_BACKGROUND,
  },
  main: {
    MAIA_RED,
    MAIA_BLUE,
    MAIA_GREEN,
    MAIA_WHITE,
    MAIA_BLACK,
  },
  transparent: {
    MAIA_RED_TRANSPARENT,
    MAIA_BLUE_TRANSPARENT,
    MAIA_GREEN_TRANSPARENT,
    MAIA_BLACK_TRANSPARENT,
  },
};

export default colors;
