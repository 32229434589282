import { Select, CircularProgress, makeStyles, TextField, Box, IconButton, Typography } from "@material-ui/core";
import DefaultMenuItem from 'components/DefaultMenuItem';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DefaultButton from 'components/DefaultButton';
import React, { useState, useEffect } from "react";
import { convertBytesToKiloBytes } from 'utils';
import { ActivitiesApi, DestinationsApi } from 'services';
import { toastMessages, bookingStatus } from 'config/constants';
import toast from 'react-hot-toast';
import useStyles from './styles';


function EditActivityForm({ activity, onSubmit }) {
    const [activityName, setActivityName] = useState(activity?.name);
    const [activityLabel, setActivityLabel] = useState(activity?.label);
    const [activityDescription, setActivityDescription] = useState(activity?.description);
    const [activityPopularScore, setActivityPopularScore] = useState(activity?.popularScore);
    const [activityDestination, setActivityDestination] = useState({
        id: activity?.destination.id,
        name: activity?.destination.name
    });
    const [destinations, setDestinations] = useState([]);

    const [picture, setPicture] = useState({ file: null, url: activity?.pictureURL ?? null });

    const [isSubmitting, setSubmitting] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        // fetchActivities();
        fetchDestinations();
    }, []);

    async function fetchDestinations() {
        await DestinationsApi.getDestinations().then((destinations) => {
            var data = [];
            if (destinations && destinations.data) {
                for (var i = 0; i < destinations.data.length; i++) {
                    data.push({
                        id: destinations.data[i].id,
                        name: destinations.data[i].name,
                        subtitle: destinations.data[i].subtitle
                    });
                }
                setDestinations(data);
            }
        });
    }

    async function handleChange(event) {
        switch (event.target.name) {
            case 'name':
                setActivityName(event.target.value);
                break;
            case 'label':
                setActivityLabel(event.target.value);
                break;
            case 'description':
                setActivityDescription(event.target.value);
                break;
            case 'popularScore':
                setActivityPopularScore(event.target.value);
                break;
        }
    }

    async function handleSubmit(event) {
        setSubmitting(true);
        event.preventDefault();
        let formData = new FormData();
        formData.append('name', activityName);
        formData.append('label', activityLabel);
        formData.append('description', activityDescription);
        formData.append('popular_score', activityPopularScore);
        if (activityDestination && activityDestination.id)
            formData.append('destination_id', activityDestination.id);
        if (picture && picture?.file)
            formData.append('file', picture?.file);

        await ActivitiesApi.updateActivity(activity?.id, formData).then((_) => {
            setSubmitting(false);
            onSubmit();
        }).catch((error) => {
            setSubmitting(false);
            toast.error(toastMessages.error.EDIT_ACTIVITY);
        });
    }

    const handlePictureChange = async (event) => {
        if (event.target.files != null && event.target.files.length > 0) {
            const file = new Blob([event.currentTarget.files[0]], { type: 'image/*' });
            const url = URL.createObjectURL(file);
            setPicture({ file: event.currentTarget.files[0], fileBlob: file, url: url });
            document.getElementById('activityImage').src = url;
        }
    };

    const handlePictureClick = (event) => {
        event.target.value = '';
    };

    return (
        <>
            <div id="headers">
                <h1 style={{ fontWeight: "500" }}>Editer une activité</h1>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit} style={{ height: '100%', width: '100%' }}>

                <Box className={classes.imageContainer}>
                    <img
                        id="activityImage"
                        src={activity?.pictureURL /*?? placeholder*/}
                        alt="photo de l'activité"
                        width="122px"
                        height="122px"
                        style={{ borderRadius: '5px' }}
                    />

                    <Box>
                        <input
                            accept="image/*"
                            className={classes.input}
                            //   disabled={(!autoSavePicture && disabled) || isUpdatingPicture}
                            id={'activityPictureInput'}
                            name="activityPictureInput"
                            type="file"
                            onChange={handlePictureChange}
                            onClick={handlePictureClick}
                        />
                        <label htmlFor={'activityPictureInput'}>
                            <IconButton
                                aria-label="upload"
                                color="secondary"
                                component="span"
                            // disabled={(!autoSavePicture && disabled) || isUpdatingPicture}
                            >
                                <ControlPointIcon style={{ height: '25px', width: '25px' }} fontSize="medium" />
                            </IconButton>
                        </label>
                        <Typography style={{ width: '80%', marginLeft: '10px' }}>
                            Vérifiez que l’image soit bien au format PNG ou JPG et qu’elle ne dépasse pas 5 Mo
                        </Typography>
                    </Box>
                </Box>

                <TextField
                    disabled
                    fullWidth
                    label="ID"
                    margin="dense"
                    name="id"
                    variant="outlined"
                    value={activity?.id}
                /*
                                    onChange={handleChange}
                error={touched.last_name && Boolean(errors.last_name)}
                helperText={touched.last_name && errors.last_name}
                */
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Nom"
                    margin="dense"
                    name="name"
                    variant="outlined"
                    value={activityName}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Label"
                    margin="dense"
                    name="label"
                    variant="outlined"
                    value={activityLabel}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    label="Description"
                    margin="dense"
                    name="description"
                    variant="outlined"
                    value={activityDescription}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    type="number"
                    label="Note"
                    margin="dense"
                    name="popularScore"
                    variant="outlined"
                    value={activityPopularScore}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    disabled
                    label="Latitude"
                    margin="dense"
                    name="latitude"
                    variant="outlined"
                    value={activity?.latitude}
                    onChange={handleChange}
                >
                </TextField>

                <TextField
                    fullWidth
                    disabled
                    label="Longitude"
                    margin="dense"
                    name="longitude"
                    variant="outlined"
                    value={activity?.longitude}
                    onChange={handleChange}
                >
                </TextField>

                <label htmlFor={'destinationSelect'}>
                    Destination associée
                </label>
                <Select
                    id='destinationSelect'
                    displayEmpty
                    fullWidth
                    margin="dense"
                    value={activityDestination ?? ''}
                    onChange={(event) => {
                        const index = destinations.findIndex((destination) => destination.name == event.target.value);
                        setActivityDestination({ id: destinations[index].id, name: event.target.value });
                    }}
                    renderValue={(activityDestination) => {
                        if (!activityDestination) return 'Choisir une destination associée';
                        return activityDestination.name;
                    }}
                >
                    {destinations.length === 0 && (
                        <DefaultMenuItem value="" disabled>
                            Aucune destination disponible
                        </DefaultMenuItem>
                    )}
                    {destinations.map((destination, index) => (
                        <DefaultMenuItem key={index} value={destination.name}>
                            {destination.name}
                        </DefaultMenuItem>
                    ))}
                </Select>

                <DefaultButton
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                >
                    {isSubmitting ? <CircularProgress size={20} /> : 'Valider'}
                </DefaultButton>

            </form>
            {/* <Dialog open={isRefreshingDocument}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog> */}
            {/* <ActivitiesTable
                data={activities}
                loading={isTableLoading}
            // onUploadDocument={onDocumentUpload}
            // onConfirmationClick={onConfirmationClick}
            //                onDeleteActivityDocument={onDocumentDelete}
            //                onValidateClick={onProviderValidateClick}
            //                onDeletionClick={onProviderDeleteClick}
            //                onBanClick={onProviderBanClick}
            //                openFile={openFile}
            /> */}
        </>
    );
}

export default EditActivityForm;