import axios from 'axios';

async function getClients() {
  const response = await axios.get(`/users`);

  return response.data;
}

async function deleteClient(clientId) {
  const response = await axios.delete(`/users/` + clientId);

  return response.data;
}

async function exportClients(format, data) {
  let options = null;
  if (format === 'pdf')
    options = { responseType: 'blob' };
  const response = await axios.post(`/users/export?format=` + format, data, options);

  return response.data;
}

const clientService = {
  getClients,
  deleteClient,
  exportClients
}

export default clientService;
