import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import useIconDimension from 'hooks/useIconDimension'

const FacebookIcon = ({ width, height, sx, ...rest }) => {
  const dimension = useIconDimension()

  return (
    <SvgIcon sx={{ width: width ?? dimension.width, height: height ?? dimension.height, ...sx }} {...rest}>
      <path
        d="M7.31929 12.652H9.48929V21.6774C9.48929 21.8556 9.6322 22 9.80858 22H13.4879C13.6643 22 13.8072 21.8556 13.8072 21.6774V12.6945H16.3018C16.464 12.6945 16.6004 12.5715 16.619 12.4088L16.9978 9.08606C17.0082 8.99465 16.9796 8.9031 16.919 8.83452C16.8584 8.76587 16.7717 8.72658 16.6807 8.72658H13.8073V6.64374C13.8073 6.01587 14.1419 5.69748 14.802 5.69748C14.896 5.69748 16.6807 5.69748 16.6807 5.69748C16.8571 5.69748 17 5.55303 17 5.3749V2.32497C17 2.14677 16.8571 2.00239 16.6807 2.00239H14.0915C14.0733 2.00148 14.0327 2 13.973 2C13.5237 2 11.9622 2.0891 10.7287 3.23555C9.36195 4.506 9.55193 6.02716 9.59734 6.2909V8.72652H7.31929C7.14292 8.72652 7 8.8709 7 9.0491V12.3294C7 12.5075 7.14292 12.652 7.31929 12.652Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default FacebookIcon
