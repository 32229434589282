export const providersStatus = {
  confirmed: "Confirmé",
  unconfirmed: "A faire",
  uncompleted: "Incomplet"
}

export const tripStatus = {
  processed: 'processed',
  processing: 'processing',
  error: 'error'
}

export const bookingStatus = {
  paid: 'Payed',
  onHold: 'On Hold'
}

export const MAX_POST_FILE_SIZE = 20000000; // 20 Mo

// Popup messages
export const popupMessages = {
  title: {
    providerValidation: "Validation d'un prestataire",
    confirmedProviderDeletion: "Suppression d'un prestataire",
    unconfirmedProviderDeletion: "Suppression d'une demande de validation",
    providerBan: "Ban d'un prestataire",
    tripConfirmation: 'Confirmation d\'un voyage'
  },
  message: {
    providerValidation: "Voulez-vous valider ce prestataire ?",
    providerForceValidation: "Attention, vous êtes sur le point de forcer la validation d'un prestataire (documents manquants) ! Voulez-vous quand même valider ce prestataire ?",
    confirmedProviderDeletion: "Attention vous êtes sur le point de supprimer un prestataire ! Voulez vous quand même supprimer ce prestataire ?",
    unconfirmedProviderDeletion: "Attention vous êtes sur le point de supprimer la demande de validation d'un prestataire ! Voulez vous quand même supprimer cette demande ?",
    providerBan: "Attention vous êtes sur le point de ban un prestataire ! Voulez vous quand même ban ce prestataire ?",
    tripConfirmation: 'Voulez-vous confirmer ce voyage ?'
  },
  error: {
    post: {
      upload: 'Une erreur est survenue pendant l\'upload du post',
      update: 'Une erreur est survenue pendant la mise à jour du post',
    }
  },
  success: {
    post: {
      update: 'Le post a été mis a jour avec succès',
    }
  }
}

// Months

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// TOAST messages
export const toastMessages = {
  error: {
    ACTIVITY_CANNOT_ENABLE: "Veuillez d'abord enregistrer un moniteur et un lieu.",
    CONTRACT_CHECKED: 'Erreur lors de la validation des conditions générales',
    CREATE_ACTIVITY: "Erreur lors de la création de l'activité",
    CREATE_ACTIVITY_MISSING_PICTURE: "Vous devez ajouter une photo pour l'activité",
    CREATE_ADDRESS: "Erreur lors de la création de l'adresse",
    CREATE_AGENDA: "Erreur lors de la creation de l'agenda",
    CREATE_BOOKING: 'Erreur lors de la creation de la réservation',
    CREATE_COMPANY: "Erreur lors de la création de l'entreprise",
    CREATE_DESTINATION: 'Erreur lors de la création d\'une destination',
    CREATE_EMPLOYEE: 'Erreur lors de la création du moniteur',
    CREATE_HELP_REQUEST: 'Erreur lors de la création de la demande',
    CREATE_POST: 'Erreur lors de la création du post',
    DELETE_ACTIVITY: "Erreur lors de la suppression de l'activité",
    DELETE_ADDRESS: "Erreur lors de la suppression de l'adresse",
    DELETE_BOOKING: 'Erreur lors de la suppression de la réservation',
    DELETE_COMPANY: "Erreur lors de la suppression de l'entreprise",
    DELETE_DASHBOARD_NOTIFICATION: 'Erreur lors de la suppression de la notification',
    DELETE_EMPLOYEE: 'Erreur lors de la suppression du moniteur',
    DELETE_POST: 'Erreur lors de la suppression du post',
    EDIT_ACTIVITY: "Erreur survenue lors de la modification de l'activité",
    EDIT_DESTINATION: 'Erreur lors de la modification d\'une destination',
    EXPORT_ACTIVITIES_BOOKINGS: 'Erreur lors de l\'exportation des réservations d\'activités',
    EXPORT_BILL: "Erreur lors de l'exportation des factures",
    EXPORT_CLIENTS: "Erreur lors de l'exportation des clients",
    EXPORT_EVENTS_BOOKINGS: 'Erreur lors de l\'exportation des réservations d\'évènements',
    EXPORT_PROVIDERS: "Erreur lors de l'exportation des prestataires",
    EXPORT_TRIPS_BOOKINGS: 'Erreur lors de l\'exportation des réservations de voyages',
    FACEBOOK_LOAD: 'Une erreur est survenue lors de la vérification de la connexion avec Facebook ...',
    FACEBOOK_LOGIN: 'Une erreur est survenue lors de la connexion à Facebook',
    FETCH_ACTIVITIES: 'Erreur lors du chargement des activités',
    FETCH_ADDRESSES: 'Erreur lors du chargement des adresses',
    FETCH_AGENDA_CONFIGURATION: 'Erreur lors du chargement de la configuration du moniteur',
    FETCH_AGENDA: "Erreur lors du chargement de l'agenda du moniteur",
    FETCH_CLIENTS: 'Erreur lors du chargement des clients',
    FETCH_COMPANIES: 'Erreur lors du chargement des entreprises',
    FETCH_COMPANY_DETAILS: "Erreur lors du chargement des détails de l'entreprise courante",
    FETCH_DASHBOARD: 'Erreur lors du chargement du tableau de bord',
    FETCH_DASHBOARD_NOTIFICATIONS: 'Erreur lors du chargement des notifications du tableau de bord',
    FETCH_EMPLOYEES: 'Erreur lors du chargement des moniteurs',
    FETCH_HELP_REQUESTS: 'Erreur lors du chargement des requêtes',
    FETCH_POSTS: 'Erreur lors du chargement des posts',
    FETCH_PROFILE_PICTURE: 'Erreur lors du chargement de la photo de profil',
    FETCH_PROVIDER: 'Erreur lors du chargement des données utilisateur',
    FETCH_NOTIFICATIONS: 'Erreur lors du chargement des paramètres de notification',
    FETCH_STEPS: "Erreur lors du chargement des étapes d'enregistrement",
    FILE_MAX_DATA: 'Vous dépassez la limite de données autorisée',
    FILE_MAX_NUMBER: 'Vous dépassez la limite de nombre de fichiers autorisée',
    POST_FILE_FORMAT: 'Format de fichier non autorisé',
    POST_FILE_SIZE: 'Fichier trop volumineux (8MB image / 100MB vidéo)',
    TRIP_DOCUMENT_FILE_SIZE: 'L\'un ou plusieurs des documents de voyage est trop volumineux (10 Mo maximum)',
    TRIP_DOCUMENT_UPLOAD: 'Une erreur est survenue lors de l\'upload d\'un ou de plusieurs documents de voyage',
    ACTIVITY_BOOKING_DOCUMENT_FILE_SIZE: 'Le document de réservation d\'activité est trop volumineux (10 Mo maximum)',
    ACTIVITY_BOOKING_DOCUMENT_UPLOAD: 'Une erreur est survenue lors de l\'upload du document de réservation d\'activité',
    ACTIVITY_BOOKING_CONFIRM_ERROR: 'Une erreur est survenue lors de la confirmation de la réservation d\'activité',
    EVENT_BOOKING_DOCUMENT_FILE_SIZE: 'Le document de réservation d\'évènement est trop volumineux (10 Mo maximum)',
    EVENT_BOOKING_DOCUMENT_UPLOAD: 'Une erreur est survenue lors de l\'upload du document de réservation d\'évènement',
    EVENT_BOOKING_CONFIRM_ERROR: 'Une erreur est survenue lors de la confirmation de la réservation d\'évènement',
    REGISTER_CONFIG: "Erreur lors de l'enregistrement de la configuration",
    SESSION_EXPIRED: 'Session expirée, veuillez vous reconnecter',
    SEND_INVITATION: "Une erreur est survenue lors de l'envoi des invitations",
    TOO_LONG_SESSION:
      'Impossible de modifier votre mot de passe car vous êtes connecté depuis trop longtemps.' +
      ' Veuillez vous reconnecter avant de faire la modification.',
    TRANSFER_AGENDA: "Une erreur est survenue lors du transfert de l'agenda",
    TRANSFER_ADDRESS: "Une erreur est survenue lors du transfert de l'adresse",
    RESOLVED_CONFLICTS: 'Une erreur est survenue lors de la résolution des conflits',
    UPDATE_ACTIVITY: "Erreur lors de la mise à jour de l'activité",
    UPDATE_EVENT: "Erreur lors de la mise à jour de l'évènement",
    UPDATE_ADDRESS: "Erreur lors de la mise à jour de l'adresse",
    UPDATE_BOOKING: 'Erreur lors de la mise à jour de la réservation',
    UPDATE_COMPANY: "Erreur lors de la mise à jour des données de l'entreprise",
    UPDATE_COMPANY_DETAILS: "Erreur lors de la mise à jour des détails de l'entreprise",
    UPDATE_COMPANY_DETAILS_PICTURE_SIZE_LIMIT: 'La taille totale des fichiers ne doit pas dépasser 6 Mo',
    UPDATE_DOCUMENT: 'Erreur lors de la mise à jour du document',
    UPDATE_EMPLOYEE: 'Erreur lors de la modification du moniteur',
    UPDATE_EMPLOYEE_ACTIVITY: "Erreur lors de l'assignation de l'activité au moniteur",
    UPDATE_EMPLOYEE_PROFILE_PICTURE: 'Erreur lors de la modification de la photo de profil du moniteur',
    UPDATE_NOTIFICATIONS: 'Erreur lors de la mise à jour des paramètres de notification',
    UPDATE_POST: 'Erreur lors de la mise à jour du post',
    UPDATE_PROFILE_PICTURE: 'Erreur lors de la mise à jour de la photo de profil',
    UPDATE_PROVIDER: 'Erreur lors de la mise à jour des données utilisateur',
    UPLOAD_PICTURE_SIZE_LIMIT: "La taille de l'image ne doit pas dépasser 6 Mo",
    VIDEO_DURATION: 'Durée de vidéo non autorisée (3 - 60 secondes)',
  },
  success: {
    CREATE_ACTIVITY: "L'activité a été créée avec succès",
    CREATE_ADDRESS: "L'adresse a été créée avec succès",
    CREATE_AGENDA: "L'agenda a été créé avec succès",
    CREATE_BOOKING: 'La réservation a été créée avec succès',
    CREATE_COMPANY: "L'entreprise a été créée avec succès",
    CREATE_EMPLOYEE: 'Le moniteur a été créé avec succès',
    CREATE_HELP_REQUEST: 'Votre demande a été créé avec succès',
    CREATE_POST: 'Le post a été créé avec succès',
    DELETE_ACTIVITY: "L'activité a été supprimée avec succès",
    DELETE_ADDRESS: "L'adresse a été supprimée avec succès",
    DELETE_BOOKING: 'La réservation a été supprimée avec succès',
    DELETE_COMPANY: "L'entreprise a été supprimée avec succès",
    DELETE_DASHBOARD_NOTIFICATION: 'La notification a été supprimée avec succès',
    DELETE_EMPLOYEE: 'Le moniteur a été supprimé avec succès',
    DELETE_POST: 'Le post a été supprimé avec succès',
    RESOLVED_CONFLICTS: 'Les conflits ont été résolus avec succès',
    SEND_INVITATION: 'Invitation(s) envoyée(s) avec succès !',
    TRANSFER_AGENDA: "L'agenda a été transféré avec succès",
    TRIP_DOCUMENT_FILE_UPLOADED: 'Le ou les documents de voyage ont été uploadé avec succès',
    ACTIVITY_BOOKING_DOCUMENT_FILE_UPLOADED: 'Le document de réservation d\'activité a été uploadé avec succès',
    EVENT_BOOKING_DOCUMENT_FILE_UPLOADED: 'Le document de réservation d\'évènement a été uploadé avec succès',
    UPDATE_ACTIVITY: "L'activité a été modifiée avec succès",
    UPDATE_ADDRESS: "L'adresse a été modifiée avec succès",
    UPDATE_BOOKING: 'La réservation a été modifiée avec succès',
    UPDATE_COMPANY: "Les données de l'entreprise ont été mises à jour",
    UPDATE_COMPANY_DETAILS: "Les détails de l'entreprise on bien été mis à jour",
    UPDATE_DOCUMENT: 'Le document a bien été déposé',
    UPDATE_EMPLOYEE: 'Le moniteur a été modifié avec succès',
    UPDATE_EMPLOYEE_PROFILE_PICTURE: "L'image du moniteur a bien été mise à jour",
    UPDATE_NOTIFICATIONS: 'Les paramètres de notification ont été mis à jour',
    UPDATE_POST: 'Le post a été modifié avec succès',
    UPDATE_PROFILE_PICTURE: 'La photo de profil a été mise à jour',
    UPDATE_PROVIDER: 'Les données utilisateur ont été mises à jour',
  },
};