import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  ticketContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '2vh',
    paddingRight: '2vh',
  },
  dateTitle: {
    color: theme.palette.text.title,
  },
}));
