import { Badge } from "@material-ui/core";
import DataTable from "components/DataTable";
import { useMemo } from "react";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

function NotificationsTable({ data, loading }) {

    const notificationsHeadCells = [
        { id: 'isRead', label: '', sortable: false },
        { id: 'id', label: 'ID notif', sortable: true },
        { id: 'type', label: 'Type', sortable: true },
        { id: 'date', label: 'Date', sortable: true },
        { id: 'title', label: 'Titre', sortable: true },
        { id: 'description', label: 'Description', sortable: true },
    ];

    const normalizedData = useMemo(() => Array.from(data).map((notification, index) => ({
        ...notification,
        isRead: {
            component: (
                notification.isRead ? <></> : <PriorityHighIcon />
            )
        }
    })), [data]);


    return <DataTable
        headCells={notificationsHeadCells}
        data={normalizedData}
        loading={loading}        
    />;
}

export default NotificationsTable;
