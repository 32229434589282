import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'App';
import init from 'config/init';
import reportWebVitals from './reportWebVitals';
import UserProvider from "./providers/UserProvider";
import { initFacebookSdk } from './config/facebookAPI';


init();
setTimeout(initFacebookSdk);

ReactDOM.render(
  <UserProvider>
    <App />
  </UserProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();