import React from "react";

import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import socialNetworksLogo from "../../_assets/dashboard/social-networks-logo.svg";

import "./Parameters.css";
import { useHistory } from "react-router-dom";

const Parameters = () => {
  const history = useHistory();

  const Section = ({ title, desc, path, logo }) => {
    return (
      <Card
        className="cards"
        style={{
          border: "1px solid #6B6B6B",
          borderRadius: "10px",
        }}
      >
        <Grid container xs={12}>
          <Grid container xs={10}>
            <Grid item xs={2}>
              <img
                style={{
                  marginTop: "1em",
                  marginLeft: "1em",
                }}
                src={logo}
                width="30px"
                height="30px"
                alt="logo"
              ></img>
            </Grid>
            <Grid container xs={10} alignItems="center">
              <div
                style={{
                  fontSize: "22px",
                  fontWeight: "500",
                  lineHeight: "23px",
                  marginLeft: "0.5em",
                  marginTop: "1em",
                  height: "1em",
                }}
              >
                {title}
              </div>
            </Grid>
            <Grid container justify="center" xs={12}>
              <div
                style={{
                  fontSize: "19px",
                  fontWeight: "normal",
                  lineHeight: "30px",
                  marginTop: "10%",
                  marginLeft: "5%",
                  height: "7em",
                }}
              >
                {desc}
              </div>
            </Grid>
          </Grid>
          <Grid container xs={2} alignItems="flex-end">
            <IconButton
              onClick={() => history.push(path)}
              aria-label="navigate"
              name="securityButton"
              style={{ height: "25%" }}
            >
              <ArrowForwardIosIcon
                fontSize="large"
                style={{ color: "#51D7B9" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    );
  };

  const manager = [
    {
      title: "Réseaux sociaux",
      desc: "Connectez vos comptes de réseaux sociaux",
      path: "/parameters/social-networks",
      logo: socialNetworksLogo,
    },
  ];

  return (
    <Grid container xs={12}>
      <div
        style={{ marginTop: "10vh", marginBottom: "5vh", marginLeft: "1em" }}
      >
        <h1 style={{ fontWeight: "500" }}>Paramètres</h1>
      </div>
      <Grid container xs={12}>
        {manager.map((card) => (
          <Section {...card} />
        ))}
      </Grid>
    </Grid>
  );
};

export default Parameters;
