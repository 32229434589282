import { Dialog, DialogContent, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { convertBytesToKiloBytes } from 'utils';
import { ActivitiesBookingsApi } from "services";
import { toastMessages, bookingStatus } from 'config/constants';
import toast from 'react-hot-toast';
import ActivitiesTable from './components/ActivitiesBookingsTable';


function ActivitiesBookings() {
    const [activitiesBookings, setActivitiesBookings] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [isRefreshingDocument, setIsRefreshingDocument] = useState(false);

    useEffect(() => {
        fetchActivitiesBookings();
    }, []);

    const onConfirmationClick = async (event, activityBooking) => {
        setIsRefreshingDocument(true);
        let formData = new FormData();
        formData.append('status', bookingStatus.paid);
        await ActivitiesBookingsApi.updateActivityBooking(activityBooking.id, formData).then((response) => {
            if (response.status === 'OK') {
                let activitiesBookingsCopy = [...activitiesBookings];
                for (var i = 0; i < activitiesBookings.length; i++) {
                    for (var j = 0; j < activitiesBookings[i].participants.length; j++) {
                        if (activitiesBookings[i].participants[j].id === activityBooking.id) {
                            activitiesBookingsCopy[i].participants[j].status = bookingStatus.paid;
                            setActivitiesBookings(activitiesBookingsCopy);
                        }
                    }
                }
            }
        }).catch((_) => {
            toast.error(toastMessages.error.ACTIVITY_BOOKING_CONFIRM_ERROR);
        }).finally(_ => {
            setIsRefreshingDocument(false);
        });
    };

    const onDocumentUpload = async (event, activityBookingId) => {
        setIsRefreshingDocument(true);
        if (event.target.files !== null) {
            let errors = 0;
            for (const file of event.target.files) {
                if (convertBytesToKiloBytes(file.size) > 9000) { // TODO : recheck value
                    toast.error(toastMessages.error.ACTIVITY_BOOKING_DOCUMENT_FILE_SIZE);
                    setIsRefreshingDocument(false);
                    return;
                }
            }
            let responseData = null;
            for (const file of event.target.files) {
                let formData = new FormData();
                formData.append('file', file);
                await ActivitiesBookingsApi.uploadDocument(activityBookingId, formData).then((response) => {
                    if (response.status === 'OK') {
                        if (response.data)
                            responseData = response.data;
                    }
                    else
                        errors += 1;
                }).catch((_) => {
                    errors += 1;
                    setIsRefreshingDocument(false);
                }).finally(_ => {
                    event.target.value = '';
                });
            }
            setIsRefreshingDocument(false);
            if (errors > 0)
                toast.error(toastMessages.error.ACTIVITY_BOOKING_DOCUMENT_UPLOAD);
            else {
                toast.success(toastMessages.success.ACTIVITY_BOOKING_DOCUMENT_FILE_UPLOADED);
                let activitiesBookingsCopy = [...activitiesBookings];
                for (var i = 0; i < activitiesBookings.length; i++) {
                    for (var j = 0; j < activitiesBookings[i].participants.length; j++) {
                        if (activitiesBookings[i].participants[j].id === activityBookingId) {
                            activitiesBookingsCopy[i].participants[j].url = responseData.documentUrl;
                            setActivitiesBookings(activitiesBookingsCopy);
                        }
                    }
                }
            }
        }
    };

    async function fetchActivitiesBookings() {
        setIsTableLoading(true);
        ActivitiesBookingsApi.getActivitiesBookings()
            .then(
                (activities_bookings) => {
                    setIsTableLoading(false);
                    var data = [];
                    if (activities_bookings && activities_bookings.data) {
                        for (var i = 0; i < activities_bookings.data.length; i++) {
                            if (activities_bookings.data[i].participants_bookings) {
                                let participantBookings = activities_bookings.data[i].participants_bookings;
                                let participants = [];
                                for (var j = 0; j < participantBookings.length; j++) {
                                    participants.push({
                                        id: participantBookings[j].id,
                                        activityName: participantBookings[j].ticket_availability.ticket.activity.name,
                                        clientName: participantBookings[j].user.firstname + " " + participantBookings[j].user.lastname + " (" + participantBookings[j].user.nickname + ")",
                                        clientEmail: participantBookings[j].user.email,
                                        invitedName: participantBookings[j].invited_members.firstname + " " + participantBookings[j].invited_members.lastname + "(" + participantBookings[j].invited_members.nickname + ")",
                                        price: participantBookings[j].ticket_availability.ticket.low_season_price + "€ • " + participantBookings[j].ticket_availability.ticket.high_season_price + "€",
                                        status: participantBookings[j].status,
                                        url: participantBookings[j].url,
                                        creationDate: participantBookings[j].creation_date,
                                    })
                                }
                                data.push({
                                    id: participants.length > 0 ? participants[0].id : "",
                                    participants: participants,
                                    bookerClientName: participants.length > 0 ? participants[0].clientName : "",
                                    bookerClientEmail: participants.length > 0 ? participants[0].clientEmail : "",
                                    activityTicketAvailabilityId: activities_bookings.data[i].activity_ticket_availability_id,
                                    userId: activities_bookings.data[i].user_id,
                                    tripId: activities_bookings.data[i].trip_id
                                });
                            }
                        }
                    }
                    setActivitiesBookings(data);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsTableLoading(false);
                }
            );
    }

    return (
        <>
            <Dialog open={isRefreshingDocument}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
            <ActivitiesTable
                data={activitiesBookings}
                loading={isTableLoading}
                onUploadDocument={onDocumentUpload}
                onConfirmationClick={onConfirmationClick}
//                onDeleteActivityDocument={onDocumentDelete}
            //                onValidateClick={onProviderValidateClick}
            //                onDeletionClick={onProviderDeleteClick}
            //                onBanClick={onProviderBanClick}
            //                openFile={openFile}
            />
        </>
    );
}

export default ActivitiesBookings;