import React, { useEffect } from "react";
import { useState, useMemo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  CardHeader,
  CardContent,
  Typography,
  Card,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import DefaultIconButton from 'components/DefaultIconButton';
import NotificationsTable from "./components/NotificationsTable";

import { getToken } from "../../firebaseConfig";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import "./Dashboard.css";

import listIcon from "../../_assets/dashboard/list-icon.svg";
import notificationIcon from '../../_assets/dashboard/notification.svg';
import notificationReadIcon from '../../_assets/dashboard/notificationRead.svg';
import env from "config/env";
import { NotificationsApi } from 'services';
import DataTable from "components/DataTable";

const GET_DASHBOARD_ROUTE = "/dashboard/admin";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  listItemText: {
    color: "#6B6B6B",
    width: "100%",
    "& span, & svg": {
      fontSize: "10px",
    },
  },
  card: {
    border: "1px solid",
    borderColor: "#6B6B6B",
    width: "90%",
  },
  taskCard: {
    background: "rgba(235, 235, 235, 0.32)",
    height: "100%",
    width: "20%",
    outline: "none",
    border: 0,
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
  },
  cardBottom: {
    margin: "1em",
    height: "40%",
    width: "20%",
    fontWeight: "500",
    color: "#4459C6",
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const [clientsCount, setClientsCount] = React.useState(0);
  const [userCount, setUserCount] = React.useState(0);
  const [serviceCount, setServiceCount] = React.useState(0);
  const [validationCount, setValidationCount] = React.useState(0);
  const [ticketCount, setTicketCount] = React.useState(0);
  const [notifications, setNotifications] = React.useState([]);
  const [areNotificationsOpened, setNotificationsOpened] = React.useState(false);
  const [isLoadingNotifications, setLoadingNotifications] = useState(false);
  const [hasUnreadNotifications, setUnreadNotifications] = useState(false);

  useEffect(() => {
    setUnreadNotifications(notifications.map((notification) => notification.isRead).includes(false));
  }, [notifications]);

  useEffect(() => {
    getDashboard();
    getNotifications();
  }, []);

  async function handleOpenNotifications() {
    setNotificationsOpened(true);
    await getNotifications().then(async _ => {
      await NotificationsApi.markNotificationAsRead();
    });
  }

  async function handleCloseNotifications() {
    setNotificationsOpened(false);
  }

  async function getNotifications() {
    setLoadingNotifications(true);
    await NotificationsApi.getNotifications().then((notifications) => {
      let data = []
      for (var i = 0; i < notifications.data.length; i++) {
        data.push({
          id: notifications.data[i].id,
          type: notifications.data[i].type,
          date: notifications.data[i].date,
          isRead: notifications.data[i].is_read,
          title: notifications.data[i].title,
          description: notifications.data[i].text,
        })
      }
      setNotifications(data);
    }).finally(_ => {
      setLoadingNotifications(false);
    });
  }

  async function getDashboard() {
    const requestOptions = {
      method: "GET",
      headers: {
        Origin: "http://127.0.0.1:3000",
        "Access-Control-Request-Method": "GET",
        "Access-Control-Request-Headers": "Authorization",
        Authorization: await getToken(),
      },
    };
    var res = await fetch(
      env.REACT_APP_SERVER_URL + GET_DASHBOARD_ROUTE,
      requestOptions
    )
      .then((response) => response.json())
      .then(
        (dashboard_data) => {
          setClientsCount(dashboard_data.clients);
          setUserCount(dashboard_data.presta);
          setServiceCount(dashboard_data.service);
          setValidationCount(dashboard_data.validation);
          setTicketCount(dashboard_data.ticket);
        },

        (error) => {
        }
      );
    return res;
  }
  const Info = ({ title, during, variable }) => {
    return (
      <Card className={classes.cardBottom}>
        <CardHeader title={title} />
        <Typography variant="div" color="textSecondary" component="div">
          {during}
        </Typography>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {variable}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  // const data = [
  //   { name: 'Aout 20', uv: 1600, pv: 2400, amt: 2400 },
  //   { name: 'Sept 20', uv: 750, pv: 2400, amt: 2400 },
  //   { name: 'Oct 20', uv: 490, pv: 2400, amt: 2400 },
  //   { name: 'Nov 20', uv: 1700, pv: 2400, amt: 2400 },
  //   { name: 'Dec 20', uv: 1510, pv: 2400, amt: 2400 }];

  return (
    <>
      <Dialog
        open={areNotificationsOpened}
        onClose={handleCloseNotifications}
      >
        <DialogContent>
          <NotificationsTable
            data={notifications}
            loading={isLoadingNotifications}
          />
        </DialogContent>
      </Dialog>
      <Grid container className="Dashboard" style={{ width: "100%", padding: "0 1em" }} justify="center">
        <Grid
          item
          xs={11}
          style={{
            marginTop: "10vh",
            marginBottom: "5vh",
          }}
        >
          <h1 style={{ fontWeight: 500 }}>Tableau de bord</h1>
        </Grid>
        <Grid item xs={1} style={{
          marginTop: "10vh"
        }}>
          <IconButton
            size="small"
            onClick={handleOpenNotifications}
          >
            <img src={hasUnreadNotifications ? notificationIcon : notificationReadIcon}></img>
          </IconButton>

          {/* <img alt="notifications" src={notificationIcon} width={30} /> */}
          {/* <Box className={classes.cardHeader}>
            <img alt="notifications" src={notificationIcon} width={30} />
            <Typography className={classes.cardTitle} variant="h4" gutterBottom>
              Notifications
            </Typography>
          </Box> */}

          {/* <NotificationsList classes={classes} /> */}
        </Grid>
        <Grid container alignItems="stretch" xs={12} justify="space-between">
          <Grid container xs={8}>
            <Card
              variant="outlined"
              className={classes.card}
              style={{ borderRadius: "10px" }}
            >
              <CardContent id="graph">
                <CardHeader title="Revenus" />
                <Grid container xs={12} style={{ marginLeft: "1em" }}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Solde du compte
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      1000
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Exécuté
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      500
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      En cours
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      20
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent style={{ width: "100%", height: "65%" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={670}
                    height={200}
                    margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                  >
                    <Line type="monotone" dataKey="earnings" stroke="#8884d8" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid container xs={4} className={classes.taskCard} direction="column">
            <CardHeader title="Liste des tâches" />
            <List id="toDoList">
              <ListItem>
                <img src={listIcon} alt="list icon" />
                <ListItemText
                  style={{ marginLeft: "1em" }}
                  primary={validationCount + " validations prestataires à faire"}
                />
              </ListItem>
              <ListItem>
                <img src={listIcon} alt="list icon" />
                <ListItemText
                  style={{ marginLeft: "1em" }}
                  primary={ticketCount + " tickets assistance"}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          wrap="wrap"
          style={{ height: "30%", width: "100%", marginTop: "3em" }}
          id="cardInfo"
        >
          <Info title="Prestataires" during="Total :" variable={clientsCount} />
          <Info title="Utilisateurs" during="Total :" variable={userCount} />
          <Info title="Services" during="Total :" variable={serviceCount} />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
