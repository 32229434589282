import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import CustomSelect from 'components/CustomSelect';
import ActivitiesBookings from './components/Activities';
import EventsBookings from './components/Events';
import TripsBookings from './components/Trips';

const pages = [{ id: 1, displayName: 'Activités' }, { id: 2, displayName: 'Evènements' }, { id: 3, displayName: 'Voyages'}];

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(1),
    }
}));

function Bookings() {

    const classes = useStyles();
    const [page, setPage] = useState(pages[0]);

    const onPageClick = (page) => {
//        if (page.id === 2) setPosts([]);
        setPage(page);
    }

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={3}>
            <Grid container item xs={12} justifyContent='center'>
                <Typography variant='h1'>Voyages</Typography>
            </Grid>

            <Grid container item xs={12} justifyContent='center'>
                <CustomSelect
                    itemList={pages}
                    justify='center'
                    labelField='displayName'
                    idField='id'
                    selectedItem={page}
                    onClick={onPageClick}
                />
            </Grid>

            <Grid className={classes.container} container item xs={12}>
                { page.id === 1 && <ActivitiesBookings />}
                { page.id === 2 && <EventsBookings />}
                { page.id === 3 && <TripsBookings />}
            </Grid>
        </Grid>
    );
};

export default Bookings;