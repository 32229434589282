import Dashboard from 'components/Dashboard/Dashboard';
import PrivateRoute from 'PrivateRoute';
import { Switch } from 'react-router';
import routes from 'config/routes';
import Analytics from 'components/Dashboard/Analytics';
import Clients from 'pages/Clients/Clients';
import Providers from 'pages/Providers/Providers';
import Contents from 'pages/Contents/Contents';
import HelpRequests from 'pages/HelpRequests/HelpRequests';
import Parameters from 'components/Parameters/Parameters';
import Bookings from 'pages/Bookings/Bookings';
import { PostProvider } from 'pages/SocialNetworks/contexts/PostContext';
import SocialNetworks from 'pages/SocialNetworks/SocialNetworks';
import AgencyPrivateRoute from 'AgencyPrivateRoute';
import SocialNetworksParameters from 'pages/Parameters/SocialNetworks'
import Payment from 'pages/Payment';
import TGX from 'pages/TGX';

function SecuredArea() {
  return (
    <Switch>
      <PrivateRoute exact path={routes.home.root} hasLayout>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={routes.analytics.root} hasLayout>
        <Analytics />
      </PrivateRoute>
      <PrivateRoute path={routes.clients.root} hasLayout>
        <Clients />
      </PrivateRoute>
      <PrivateRoute path={routes.prestataires.root} hasLayout>
        <Providers />
      </PrivateRoute>
      <PrivateRoute path={routes.contents.root} hasLayout>
        <Contents />
      </PrivateRoute>
      <PrivateRoute path={routes.helpRequests.root} hasLayout>
        <HelpRequests />
      </PrivateRoute>
      <PrivateRoute exact path={routes.parameters.root} hasLayout>
        <Parameters />
      </PrivateRoute>
      <AgencyPrivateRoute path={routes.bookings.root} hasLayout>
        <Bookings />
      </AgencyPrivateRoute>
      <PrivateRoute path={routes.tgx.root} hasLayout>
        <TGX />
      </PrivateRoute>
      <PrivateRoute path={routes.payment.root} hasLayout>
        <Payment />
      </PrivateRoute>
      <PrivateRoute path={routes.socialNetworks.root} hasLayout>
        <PostProvider>
          <SocialNetworks />
        </PostProvider>
      </PrivateRoute>
      <PrivateRoute path={routes.parameters.socialNetworks} hasLayout>
        <SocialNetworksParameters />
      </PrivateRoute>
    </Switch>
  )
}

export default SecuredArea;