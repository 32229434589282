import {
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import DataTableHead from './components/DataTableHead';
import RemoveIcon from '@material-ui/icons/Remove';
import useStyles from './styles/index.js';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const DataRow = (props) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={`${props.row.id}_collapsable`}
        selected={props.isItemSelected}
        className={props.odd ? classes.oddRow : classes.evenRow}
      >
        {props.collapsableComponent && <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setExpanded(!isExpanded)}
          >
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>}
        {props.children}
      </TableRow>
      <TableRow
        hover
        tabIndex={-1}
        key={props.row.id}
        className={props.odd ? classes.oddRow : classes.evenRow}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse
            in={isExpanded}
            timeout='auto'
            unmountOnExit
          >
            {props.collapsableComponent}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

//
function DataTable({
  data = [],
  headCells,
  loading,
  ToolbarComponent = null,
  toolbarProps = {},
  checkbox = false,
  MoreActionComponent = null,
  moreActionProps = {},
  collapsableComponent = null,
  isExpanded = false,
  isCollapsable = false,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('asc');
  const [selectedData, setSelectedData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((d) => d.id);
      setSelectedData(newSelected);
      return;
    }
    setSelectedData([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selectedData.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedData, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedData.slice(1));
    } else if (selectedIndex === selectedData.length - 1) {
      newSelected = newSelected.concat(selectedData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedData.slice(0, selectedIndex), selectedData.slice(selectedIndex + 1));
    }

    setSelectedData(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selectedData.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  let odd = true;
  return (
    <>
      {!!ToolbarComponent && (
        <ToolbarComponent classes={classes} {...toolbarProps} selectedData={/*checkbox ?*/ selectedData/* : null*/} />)}

      <TableContainer className={classes.root}>
        <Table size="small">
          <DataTableHead
            classes={classes}
            headCells={headCells}
            numSelected={selectedData.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            checkbox={checkbox}
            isCollapsable={isCollapsable}
          />

          <TableBody>
            {!loading &&
              stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <DataRow
                      odd={odd}
                      row={row}
                      isItemSelected={isItemSelected}
                      collapsableComponent={row.collapsableComponent}
                    >
                      {(odd = !odd)}
                      {checkbox && (
                        <TableCell padding="checkbox" className={classes.tableCell}>
                          <Checkbox
                            className={classes.checkbox}
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                      )}
                      {headCells.map((headCell, index) => {
                        const dataCell = row[headCell.id];
                        if (Array.isArray(dataCell)) {
                          return (
                            <TableCell key={`${row.id}-cell${index}`} align={'center'} className={classes.tableCell}>
                              <List>
                                {dataCell.map((value) => {
                                  return (
                                    <ListItem style={{ padding: 0 }}>
                                      <RemoveIcon />
                                      <ListItemText className={classes.field}>
                                        <Typography style={{ fontSize: 14 }}>{value}</Typography>
                                      </ListItemText>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </TableCell>
                          );
                        } else if (!!dataCell?.component) {
                          return (
                            <TableCell
                              key={`${row.id}-cell${index}`}
                              style={{ maxHeight: 120, maxWidth: 250 }}
                              className={classes.tableCell}
                            >
                              {dataCell.component}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={`${row.id}-cell${index}`} className={classes.tableCell}>
                              <Typography className={classes.field}>{dataCell}</Typography>
                              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                {collapsableComponent}
                              </Collapse>

                            </TableCell>
                          );
                        }
                      }
                      )}
                      {!!MoreActionComponent && (
                        <TableCell padding="checkbox" className={classes.tableCell}>
                          <MoreActionComponent props={{ classes: classes, ...moreActionProps }} />
                        </TableCell>
                      )}
                    </DataRow>
                  );
                })}

            {!loading && emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={headCells.length + 1} />
              </TableRow>
            )}

            {loading && (
              <TableRow>
                <TableCell className={classes.loadingRow} colSpan={headCells.length + 1}>
                  <CircularProgress size={33} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        labelDisplayedRows={({ from, to }) => `${from} sur ${to}`}
        labelRowsPerPage="Résultats par page :"
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default DataTable;
