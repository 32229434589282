import DataTable from 'components/DataTable';
import { Button } from '@material-ui/core';
import { useState, useMemo } from 'react';
import DataTableExportToolbar from "components/DataTableExportToolbar";
import { ClientApi } from 'services';
import { toastMessages } from 'config/constants';
import toast from 'react-hot-toast';

const headCells = [
    { id: 'id', label: 'ID Client', sortable: true },
    { id: 'nickname', label: 'Pseudo', sortable: true },
    { id: 'lastName', label: 'Nom', sortable: true },
    { id: 'firstName', label: 'Prénom', sortable: true },
    { id: 'email', label: 'Email', sortable: true },
    { id: 'contactEmail', label: 'Email de contact', sortable: true },
    { id: 'gender', label: 'Sexe', sortable: true },
    { id: 'phone', label: 'Téléphone', sortable: false },
    { id: 'age', label: 'Age', sortable: true },
    { id: 'deletion', label: 'Suppression', sortable: false },
];

function ClientsTable({ data, loading, onDeletionClick }) {

    const [searchValue, setSearchValue] = useState('');
    const [exporting, setExporting] = useState(false);

    const normalizedData = useMemo(() => Array.from(data).map((client, index) => ({
        ...client,
        id: client.id,
        lastName: client.lastName,
        firstName: client.firstName,
        nickname: client.nickname,
        contactEmail: client.contactEmail,
        email: client.email,
        phone: client.phone,
        age: client.age,
        gender: client.gender,
        deletion: {
            component: (
                <>
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ margin: "1em" }}
                        onClick={(event) => {
                            onDeletionClick(event, client);
                        }}
                    >
                        Supprimer
                    </Button>
                </>
            )
        },
    })).filter(v => Object.values(v).map(v => String(v)).find(f => searchValue ? f.toLowerCase().includes(searchValue.toLowerCase()) : true)), [data, searchValue]);

    const handleExport = (selectedData, format, useSelected) => {
        if (exporting) return;

        setExporting(true);

        let clientsIds;
        if (useSelected) {
            clientsIds = selectedData.sort((a, b) => a - b);
        } else {
            clientsIds = data.map(b => b.id);
        }

        setExporting(false);

        ClientApi.exportClients(format, { ids: clientsIds })
            .then((result) => {
                let blob;
                if (format === 'csv') blob = new Blob(['\ufeff' + result], { type: 'text/csv;' });
                else blob = new Blob([result], { type: 'application/pdf' });

                const filename = `Clients Maia Partners.${format}`;

                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, filename);
                } else {
                    let link = document.createElement('a');
                    if (link.download !== undefined) {
                        link.setAttribute('href', URL.createObjectURL(blob));
                        link.setAttribute('download', filename);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }

                setExporting(false);
            })
            .catch(() => {
                toast.error(toastMessages.error.EXPORT_CLIENTS);
                setExporting(false);
            });
    }

    return <DataTable
        headCells={headCells}
        data={normalizedData}
        loading={loading}
        ToolbarComponent={DataTableExportToolbar}
        toolbarProps={{
            onSearchChange: (event) => setSearchValue(event.target.value),
            onExport: (data, format, useSelected) => handleExport(data, format, useSelected),
            nbSelectedItems: 0,
        }}
    />;
}

export default ClientsTable;
