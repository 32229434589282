import React, { useEffect, useState, useRef } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, IconButton, TextField, Typography, Box, Chip, Button, Switch, CardMedia, lighten } from '@material-ui/core';
import { convertBytesToKiloBytes, getThumbnailFromFile, trimHashtag } from 'utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DateTime } from 'luxon'
import DefaultButton from '../DefaultButton';
import DefaultCheckbox from '../DefaultCheckbox';
import toast from 'react-hot-toast';
import uploadLogo from '_assets/parameters/upload.svg';
import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon';
import useLocationSearch from '../../hooks/useLocationSearch';
import { Autocomplete } from '@material-ui/lab';
import LocalisationAutoCompletePost from 'components/LocalisationAutoCompletePost';
import useWidth from 'hooks/useWidth';
import DefaultTextField from 'components/DefaultTextField';
import MediaIcon from 'components/icons/MediaIcon';
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import { useSnackbar } from 'contexts/SnackbarContext'
import { MAX_POST_FILE_SIZE, popupMessages } from 'config/constants';
import { mainTheme } from 'themes/main';
import LoadingButton from '@mui/lab/LoadingButton';

const validationSchema = Yup.object({
  comment: Yup.string(),
  file: Yup.mixed().required(),
  hashtag: Yup.string(),
  isDraft: Yup.boolean(),
  location: Yup.mixed(),
});

const getInitialFileData = (post = null) => {
  const fileData = post?.file;
  return {
    data: fileData?.data ?? '',
    thumbnail: fileData?.thumbnail ?? '',
    type: fileData?.type ?? '',
    url: fileData?.url ?? post?.thumbnailUrl ?? '',
  };
};

function PostForm({
  disabled,
  disableSubmit,
  onCancel = () => { },
  onFieldChange = () => { },
  onFileChange = () => { },
  onSubmit = () => { },
  post,
}) {

  const lenMaxDescription = 2000;
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' }
  const initialPostFormValues = {
    file: '',
    localisation: Object({
      id: '',
      label: '',
    }),
    hashtags: [],
    description: '',
    date: new Date().toLocaleDateString('fr-CA', dateOptions),
    time: DateTime.now().toLocaleString({
      hour: 'numeric',
      minute: 'numeric',
    }),
    // instagram: false,
    // facebook: false,
  }

  const { errors, handleChange, handleSubmit, isSubmitting, setErrors, setFieldValue, setValues, touched, values } =
    useFormik({
      initialValues: initialPostFormValues,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const result = await onSubmit(values, { ...fileData });
        if (result.success) {
          // const data = getInitialFileData();
          // setFileData(data);
          setPicture({ id: '', imageURL: '', name: '', size: 0 });
          setValues(initialPostFormValues);
          setAttempted(false);
        }
      },
    });

  const [attempted, setAttempted] = useState(false);
  const [fileData, setFileData] = useState(getInitialFileData(post));
  const [stringHashtag, setStringHashtag] = useState('')
  const [picture, setPicture] = useState({ id: '', imageURL: '', name: '', size: 0 })
  const [showAccordion, setShowAccordion] = React.useState(false)
  const tagRef = useRef({ value: '' })
  const [destinationLabel, setDestinationLabel] = useState('')
  const showSnackbar = useSnackbar()
  const [file, setFile] = useState({})

  const fileError = attempted && Boolean(errors.file);

  const [inputLocation, setInputLocation] = useState('');
  const { locations } = useLocationSearch(inputLocation);

  const screenWidth = useWidth();
  const matchesXS = screenWidth === 'xs';

  const handleOnSubmit = (e) => {
    if (tagRef.current && e.key === 'Enter') {
      const newHash = trimHashtag(tagRef.current.value)
      setFieldValue('hashtags', [...values.hashtags, newHash])
      setStringHashtag([...values.hashtags, newHash].join(','))
      tagRef.current.value = ''
    }
  }

  const handleHashtag = () => {
    if (tagRef.current.value !== '') {
      const newHash = trimHashtag(tagRef.current.value)
      setFieldValue('hashtags', [...values.hashtags, newHash])
      setStringHashtag([...values.hashtags, newHash].join(','))
      tagRef.current.value = ''
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') event.preventDefault()
  }

  const handleDelete = (value) => {
    const newtags = values.hashtags.filter((val) => val !== value)
    setStringHashtag(newtags.join(','))
    setFieldValue('hashtags', newtags)
  }

  const handleAccordionClose = () => {
    setShowAccordion(false)
    const now = new Date()
    setFieldValue('date', new Date().toLocaleDateString('fr-CA', dateOptions))
    setFieldValue('time', `${now.getHours()}:${String(now.getMinutes()).padStart(2, '0')}`)
  }

  const handleAccordionChange = () => {
    setShowAccordion((prev) => {
      return !prev
    })
  }

  const handleChangeLabelDestination = (value) => {
    setDestinationLabel(value)
  }

  const onPictureChange = async (event) => {
    if (event.target.files != null && event.target.files.length) {
      if (event.target.files[0].size > MAX_POST_FILE_SIZE) {
        // setFieldError('file', 'Veuillez sélectionner une image/vidéo moins volumineuse')
        showSnackbar('error', popupMessages.error.post.upload)
        return
      }
      setFile(event.target.files[0])
      await setFieldValue('file', event.target.files[0])
      if (event.target.files[0].type.includes('video')) {
        let thumbnails = await generateVideoThumbnails(event.target.files[0], 1, event.target.files[0].type)
        if (thumbnails && thumbnails.length > 0) {
          const byteCharacters = atob(thumbnails[0].replace('data:image/jpeg;base64,', ''));
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          setPicture({
            id: '-1',
            name: event.target.files[0].name,
            imageURL: URL.createObjectURL(new Blob([byteArray], { type: 'image/*' })),
            size: convertBytesToKiloBytes(event.target.files[0].size),
          })
        }
      } else {
        setPicture({
          id: '-1',
          name: event.target.files[0].name,
          imageURL: URL.createObjectURL(new Blob([event.target.files[0]], { type: 'image/*' })),
          size: convertBytesToKiloBytes(event.target.files[0].size),
        })
      }
    }
  }

  const onDocumentChange = async (event) => {
    if (event.target.files != null && event.target.files.length > 0) {
      const file = event.target.files[0];

      const result = await getThumbnailFromFile(file);
      if (result.error) toast.error(result.error);
      else onFileChange(result);
    }
  };

  const handleLocationChange = (event, value) => {
    setFieldValue('location', value);
    onFieldChange({ ...event, target: { ...event.target, name: 'location', value } });
  };

  const handleFieldChange = (event) => {
    const {
      target: { name },
    } = event;
    if (post[name] !== values[name]) onFieldChange(event);
  };

  const onCancelForm = () => {
    if (isSubmitting) return;

    onCancel();
    setErrors({});
    setFileData(getInitialFileData(post));
  };

  const onSubmitForm = (event) => {
    setAttempted(true);
    handleSubmit(event);
  };

  const handleSelectChange = async (e) => {
    if (e) {
      await setFieldValue('localisation', e)
      await setDestinationLabel(e.label)
    } else {
      await setFieldValue('localisation', null)
    }
  }

  return (
    <form onKeyDown={onKeyDown} style={{ width: '100%', maxWidth: '700px' }} onSubmit={handleSubmit}>
      <Typography variant={'h4'} sx={{ mb: 0.5 }}>
        Photo ou vidéo
      </Typography>
      <Box
        sx={{
          width: '100%',
          minHeight: '76px',
          bgcolor: (theme) => lighten(mainTheme.palette.primary.main, 0.95),
          mb: 5,
          border: '1px solid',
          borderRadius: '4px',
          borderColor: lighten(mainTheme.palette.primary.main, 0.7),
        }}
      >
        <Box
          sx={{
            width: '100%',
            minHeight: '76px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            let input
            input = document.getElementById('imagesInput')
            if (input) input.click()
          }}
        >
          {picture.name ? (
            <Box sx={{ m: 1, color: mainTheme.palette.primary.main }}>
              <CardMedia
                image={picture.imageURL}
                component="img"
                sx={{ borderRadius: '4px', maxHeight: '400px', display: 'flex' }}
              />
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Typography color={mainTheme.palette.primary.main} sx={{ mr: 1 }}>
                Ajouter un média
              </Typography>
              <MediaIcon />
            </Box>
          )}

          <input
            type="file"
            id="imagesInput"
            style={{ display: 'none' }}
            multiple={true}
            accept="image/*, video/*"
            onChange={onPictureChange}
          />
        </Box>
        {touched.file && Boolean(errors.file) && (
          <Typography sx={{ color: mainTheme.palette.error.main }}>{errors.file}</Typography>
        )}
      </Box>

      <Typography variant={'h4'} sx={{ mt: 4, mb: 0.5 }}>
        Localisation
      </Typography>
      <LocalisationAutoCompletePost
        setValue={handleSelectChange}
        value={values.localisation}
        setLabelValue={handleChangeLabelDestination}
      />
      <Typography variant={'h4'} sx={{ mt: 4, mb: 0.5 }}>
        Hashtags
      </Typography>
      <TextField
        inputRef={tagRef}
        fullWidth
        variant="outlined"
        size="small"
        margin="none"
        disabled={stringHashtag.length > 255}
        placeholder={values.hashtags.length < 5 ? 'Entrez vos hashtags' : ''}
        InputProps={{
          onBlur: handleHashtag,
          onKeyDown: handleOnSubmit,
          startAdornment: (
            <Box sx={{ display: 'flex' }}>
              <Chip key={0} tabIndex={-1} label={'#Maiatravel'} />
              {values.hashtags.map((data, index) => {
                return (
                  <Chip
                    sx={{ maxWidth: '300px' }}
                    key={index}
                    tabIndex={-1}
                    label={'#' + data}
                    onDelete={() => handleDelete(data)}
                  />
                )
              })}
            </Box>
          ),
        }}
      />
      {stringHashtag.length > 255 && (
        <Typography sx={{ color: mainTheme.palette.error.main }}>La liste des hashtags est trop grande</Typography>
      )}
      <Typography variant={'h4'} sx={{ mt: 4, mb: 0.5 }}>
        Description du post
      </Typography>
      <DefaultTextField
        fullWidth
        name="description"
        value={values.description}
        onChange={handleChange}
        multiline={true}
        minRows={4}
        error={
          (touched.description && Boolean(errors.description)) || values.description.length > lenMaxDescription
        }
        helperText={(touched.description && errors.description) || values.description.length > lenMaxDescription}
        sx={{ mb: 2 }}
        InputProps={{ inputProps: { maxLength: lenMaxDescription + 1 } }}
      />
      {values.description.length > lenMaxDescription && (
        <Typography color={mainTheme.palette.error.main}>
          La description ne peut pas dépasser {lenMaxDescription} caractères
        </Typography>
      )}
      {/* <Accordion
        sx={{
          width: '100%',
          borderRadius: '8px',
          m: matchesXS ? '8px 0' : '12px 0',
          '&:first-of-type': { m: matchesXS ? '0 0 8px' : '0 0 12px' },
          '&:before': { opacity: 0 },
          '&.Mui-expanded': { m: matchesXS ? '8px 0' : '12px 0' },
          '&.Mui-expanded:first-of-type': { m: matchesXS ? '0 0 8px' : '0 0 12px' },
        }}
        expanded={showAccordion}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMore color="primary" />}
          sx={{
            p: matchesXS ? '0 12px' : '0 20px',
            '& .MuiAccordionSummary-content': { m: matchesXS ? '12px 0' : '20px 0' },
            '& .Mui-expanded': { mb: '4px !important' },
            '&.Mui-expanded': { minHeight: 'unset' },
          }}
        >
          <Typography variant="paragraph_big" sx={{ mb: 0.5 }}>
            Programmation
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ p: matchesXS ? '0 12px 8px' : '0 20px 8px' }}>
          <Box sx={{ display: 'flex', flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
            <Typography sx={{ mt: 2, mb: 1, alignItem: 'center', width: '150px' }}>Programmé le</Typography>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
                <DefaultTextField
                  name="date"
                  type="date"
                  sx={{ maxWidth: '200px' }}
                  value={values.date}
                  onChange={handleChange}
                  error={touched.date && Boolean(errors.date)}
                  helperText={touched.date && errors.date}
                  inputProps={{
                    max: DateTime.now().plus({ year: 100 }).toFormat('yyyy-mm-dd'),
                  }}
                />
                <Typography sx={{ m: 1, display: 'flex', mt: 1.5 }}>à</Typography>
                <DefaultTextField
                  name="time"
                  type="time"
                  value={values.time}
                  onChange={handleChange}
                  error={touched.time && Boolean(errors.time)}
                  helperText={touched.time && errors.time}
                />
              </Box>
              <Box>
                <Button sx={{ alignItems: 'right' }} onClick={handleAccordionClose}>
                  Supprimer
                </Button>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion> */}
      {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box>
          <Typography variant="paragraph_bold" sx={{ mr: 2.5 }}>
            Partager sur{' '}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mr: 2.5 }}>
          <InstagramIcon sx={{ mr: 0.5 }} />
          <Switch id="instagram" name="instagram" checked={values.instagram} onChange={handleChange} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mr: 2.5 }}>
          <FacebookIcon sx={{ mr: 0.5 }} />
          <Switch id="facebook" name="facebook" checked={values.facebook} onChange={handleChange} />
        </Box>
      </Box> */}
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: '122px' }}> */}
      <LoadingButton
        color="primary"
        type="submit"
        disabled={isSubmitting}
        fullWidth
        isLoading={isSubmitting}
        // sx={{ height: '51px', mb: 4, mt: 4, background: '#ff0000' }}
      >
        Envoyer
      </LoadingButton>
      {/* </Box> */}
    </form>
  );
}

export default PostForm;
