import * as firebase from 'firebase/app';
import * as firebaseAuth from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import env from './config/env';

const firebaseConfig = {
  apiKey: env.firebaseAPIKey,
  authDomain: env.firebaseAuthDomain,
  projectId: env.firebaseProjectId,
  storageBucket: env.firebaseStorageBucket,
  messagingSenderId: env.firebaseMessagingSenderId,
  appId: env.firebaseAppId,
  measurementId: env.firebaseMeasurementId
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebaseAuth.getAuth();
export const firestore = getFirestore();

const provider = new firebaseAuth.GoogleAuthProvider();
export const signInWithGoogle = async (remember) => {
  if (!remember) {
    return await firebaseAuth.setPersistence(auth, firebaseAuth.browserSessionPersistence)
      .then(async () => await firebaseAuth.signInWithPopup(auth, provider))
      .catch(error => error);
  }

  return await firebaseAuth.signInWithPopup(auth, provider);
};

export const signIn = async (email, password, remember) => {
  if (!remember) {
    return await firebaseAuth.setPersistence(auth, firebaseAuth.browserSessionPersistence)
      .then(async () => await firebaseAuth.signInWithEmailAndPassword(auth, email, password))
      .catch(error => error);
  }

  return await firebaseAuth.signInWithEmailAndPassword(auth, email, password);
}

export const updatePassword = (password) => {
  const user = auth.currentUser;
  return firebaseAuth.updatePassword(user, password);
}

export const signOut = () => {
  return firebaseAuth.signOut(auth);
}

export const createUser = (email, password) => {
  return firebaseAuth.setPersistence(auth, firebaseAuth.browserSessionPersistence)
    .then(() => firebaseAuth.createUserWithEmailAndPassword(auth, email, password));
}

export const signInMethodsForEmail = (email) => {
  return firebaseAuth.fetchSignInMethodsForEmail(auth, email);
}

export const sendResetPasswordEmail = (email, redirect) => {
  const actionCodeSettings = { url: redirect, handleCodeInApp: false };
  return firebaseAuth.sendPasswordResetEmail(auth, email, actionCodeSettings);
}

export const getToken = async () => {
  return new Promise((resolve) => {
    firebaseAuth.onAuthStateChanged(auth,async (user) => {
      if (user) {
        user.getIdToken().then(idToken => {
          resolve(idToken);
        });
      } else {
        // User is signed out.
        resolve(null);
      }
    });
  });
};

/*export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email, displayName, photoURL } = user;
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};*/

/*const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data()
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};*/